import GlobeRenderer from "../../GlobeRenderer";
import { useCountryFeatures } from "../../useIRMap";
import { globeTexts } from "../../lib/globe-constants";

// selectedText=globeTexts[textIndex]

const texturesToLoad = Array.from(
  new Set(globeTexts.map((text) => text.alternateTexture).filter((d) => d))
);
const countriesToLoad = Array.from(
  new Set(globeTexts.map((text) => text.countryIRHighlight).filter((d) => d))
);
const maskTextures = countriesToLoad.map((country) => `${country}-mask.jpg`);

export function Globe({
  selectedText,
  texturesLoaded,
  countrySelected,
  loadAdditionalTextures,
  setAllTexturesInitialized,
  setTextureProgress,
  setGlobalCountrySelection,
  isMobile,
  overIntroNav,
  allowMouseClickAdvance,
  textIndex,
}) {
  const countryFeatures = useCountryFeatures();

  const metric = selectedText.metric ? selectedText.metric : "temperature F";
  const countryIRHighlight = selectedText.countryIRHighlight
    ? selectedText.countryIRHighlight
    : null;

  const cameraFocus = selectedText.cameraFocus
    ? selectedText.cameraFocus
    : null;

  const alternateTexture = selectedText.alternateTexture
    ? selectedText.alternateTexture
    : null;

  const globeScale = "globeScale" in selectedText ? selectedText.globeScale : 1;

  const countryOffset = selectedText.countryOffset
    ? selectedText.countryOffset
    : null;
  const hideLegend = !!selectedText.hideLegend;
  const exploreMode = !!selectedText.exploreMode;
  const maskTexture = selectedText.countryIRHighlight
    ? maskTextures.find((d) => d.includes(selectedText.countryIRHighlight))
    : null;

  const globeHierId = selectedText.hierId ? selectedText.hierId : null;

  const overrideCameraPosition = selectedText.overrideCameraPosition
    ? selectedText.overrideCameraPosition
    : null;

  return (
    <>
      {countryFeatures && (
        <GlobeRenderer
          countryFeatures={countryFeatures}
          metric={metric}
          countryIRHighlight={countryIRHighlight}
          cameraFocus={cameraFocus}
          alternateTexture={alternateTexture}
          texturesToLoad={texturesToLoad}
          texturesLoaded={texturesLoaded}
          maskTextures={maskTextures}
          globeScale={globeScale}
          countryOffset={countryOffset}
          hideLegend={hideLegend || countrySelected}
          exploreMode={exploreMode}
          maskTexture={maskTexture}
          globeHierId={globeHierId}
          overrideCameraPosition={overrideCameraPosition}
          loadAllTextures={loadAdditionalTextures}
          setAllTexturesInitialized={setAllTexturesInitialized}
          setTextureProgress={setTextureProgress}
          setGlobalCountrySelection={setGlobalCountrySelection}
          isMobile={isMobile}
          overIntroNav={overIntroNav}
          allowMouseClickAdvance={allowMouseClickAdvance}
          textIndex={textIndex}
        />
      )}
    </>
  );
}
