import { groupBy, keyBy } from "lodash";
import {
  METRICS_CONFIGS,
  METRICS_GROUP,
  METRICS_WITHOUT_BASELINE,
  METRIC_TYPE,
  PATHWAY,
  RISK_INDEX_METRICS,
  RISK_NATURAL_METRICS,
  SCENARIO_CONFIGS,
} from "./metric-constants";
import { years } from "../constants";
import { scaleLinear } from "d3-scale";

const GROUPS_IN_PATHWAY = {
  [METRICS_GROUP.TEMPERATURE]: PATHWAY.RCP,
  [METRICS_GROUP.SEA_LEVEL_RISING]: PATHWAY.SSP,
};
const temperatureYearBaseline = "1986-2005";
const slrYearBaseline = "1995-2014";

const METRIC_BY_KEY = keyBy(METRICS_CONFIGS, (d) => d.key);
export const getMetricConfig = (metricKey) => METRIC_BY_KEY[metricKey];

const SCENARIO_BY_VALUE = keyBy(SCENARIO_CONFIGS, (d) => d.value);
export const getScenarioConfig = (scenarioValue) =>
  SCENARIO_BY_VALUE[scenarioValue];

export function getMetricExtent(metricKey, scenario) {
  const metricConfig = getMetricConfig(metricKey);
  const extent = metricConfig?.extent;
  return typeof extent === "function" ? extent(scenario) : extent;
}
export function getMetricLabel(metric) {
  return typeof metric.boldLabel === "function"
    ? metric.boldLabel()
    : metric.boldLabel;
}
export function getMetricFormatter(metricKey) {
  const metricConfig = getMetricConfig(metricKey);
  return metricConfig.metricFormatter;
}

function getMetricPathway(metricKey) {
  const metricConfig = getMetricConfig(metricKey);
  const pathway = GROUPS_IN_PATHWAY[metricConfig.group];
  return pathway;
}

export function getPathwayFromScenario(scenario) {
  const scenarioConfig = getScenarioConfig(scenario);
  return scenarioConfig.pathway;
}

export function getScenariosOfSamePathway(scenario) {
  const pathway = getPathwayFromScenario(scenario);
  const grouppedScenario = groupBy(SCENARIO_CONFIGS, (d) => d.pathway);
  return grouppedScenario[pathway].map((el) => el.value);
}

export function isMetricInSelectedScenario(metricKey, scenario) {
  return getMetricPathway(metricKey) === getPathwayFromScenario(scenario);
}

export function getBaselineYear(metricKey) {
  const metricConfig = getMetricConfig(metricKey);
  const group = metricConfig.group;
  return group === METRICS_GROUP.SEA_LEVEL_RISING
    ? slrYearBaseline
    : temperatureYearBaseline;
}

export function getYearsWithBaseline(metricKey) {
  return [getBaselineYear(metricKey), ...years];
}

export function isWithoutBaseline(metricKey) {
  return METRICS_WITHOUT_BASELINE.includes(metricKey);
}

export function getMetricInfo(metricKey) {
  const metricConfig = getMetricConfig(metricKey);
  const type = metricConfig.type;
  const group = metricConfig.group;
  const startYears =
    type === METRIC_TYPE.HAZARD && group === METRICS_GROUP.TEMPERATURE
      ? getYearsWithBaseline(metricKey)
      : years;

  return { type, group, years: startYears };
}

const getRiskIndexYearKey = (year) =>
  `years_${year.replace("-", "_")}_percentile`;
const getRiskNaturalYearKey = (year) => `years_${year.replace("-", "_")}`;

export function getIndexYearKey(metricKey, year) {
  const metricConfig = getMetricConfig(metricKey);
  if (metricConfig.group === METRICS_GROUP.TEMPERATURE) {
    if (RISK_INDEX_METRICS.includes(metricKey)) {
      return getRiskIndexYearKey(year);
    } else if (RISK_NATURAL_METRICS.includes(metricKey)) {
      return getRiskNaturalYearKey(year);
    }
  } else {
    return year;
  }
}

export function getMetricTicks(metric, scenario) {
  const metricConfig = getMetricConfig(metric);

  const extent = getMetricExtent(metric, scenario);
  const extentFormatter = metricConfig.extentFormatter ?? ["", ""];
  const metricFormatter = metricConfig.metricFormatter;
  const ticks = scaleLinear().domain(extent).ticks(4);

  return ticks.map((value, i) => {
    const formatted = metricFormatter(value);
    return {
      value,
      label:
        i === 0
          ? extentFormatter[0] + formatted
          : i === ticks.length - 1
          ? extentFormatter[1] + formatted
          : formatted,
    };
  });
}
