import { useEffect, useState } from "react";

import { csvParse } from "d3-dsv";

import {
  getBaselineYear,
  getIndexYearKey,
  getMetricExtent,
  getMetricInfo,
  isWithoutBaseline,
} from "./metric-utils";
import { METRIC, METRICS_GROUP, METRIC_TYPE } from "./metric-constants";

function fetchHazardData(metric, years, scenario, countryFilter) {
  return years.map((year) => {
    if (metric === METRIC.TEMPERATURE) {
      return `global_tas-annual_${scenario}_${year}_absolute_degF_percentiles${countryFilter}.csv`;
    } else if (metric === METRIC.DAYS_OVER) {
      return `global_tasmax-over-95F_${scenario}_${year}_absolute_days-over-95F_percentiles${countryFilter}.csv`;
    } else if (metric === METRIC.DAYS_UNDER) {
      return `global_tasmin-under-32F_${scenario}_${year}_absolute_days-under-32F_percentiles${countryFilter}.csv`;
    } else if (metric === METRIC.SEA_LEVEL_RISING) {
      return `ir_slr_cm_${scenario}_${year}_median${countryFilter}.csv`;
    }
    return null;
  });
}

function fetchImpactData(metric, scenario, countryFilter) {
  if (metric === METRIC.MORTALITY_INDEX) {
    return [
      `unit_damages_percent_GDP_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_q50-with-percentile${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.LABOR_INDEX) {
    return [
      `labor_unit_allrisk_impacts_pct_gdp_v2_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_q50-with-percentile${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.ENERGY_INDEX) {
    return [
      `unit_total_energy_impacts_pct_gdp_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_q50-with-percentile${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.MORTALITY) {
    return [
      `unit_change_in_deathrate_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_mean${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.ELECTRICITY) {
    return [
      `unit_electricity_impacts_gj_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_mean${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.OTHER_ENERGY) {
    return [
      `unit_other_energy_impacts_gj_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_mean${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.HIGHRISK_LABOR) {
    return [
      `unit_highrisk_impacts_hours_worked_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_mean${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.LOWRISK_LABOR) {
    return [
      `unit_lowrisk_impacts_hours_worked_geography_impact_regions_years_averaged_${scenario}_SSP3_quantiles_mean${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.SHARE_OF_LAND) {
    return [
      `unit_pcnt_km_ir_years_averaged_${scenario}_Q50${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.SHARE_OF_POPULATION) {
    return [
      `unit_pcnt_pop_ir_years_averaged_${scenario}_Q50${countryFilter}.csv`,
    ];
  } else if (metric === METRIC.LAND) {
    return [`unit_km_ir_years_averaged_${scenario}_Q50${countryFilter}.csv`];
  }
}

export default function useIRData({
  scenario,
  metric,
  metricsToLoad,
  countriesToLoad,
  topCities = false,
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    metricsToLoad.forEach((metric) => {
      const { type, group, years } = getMetricInfo(metric);

      const isSLRMetric = group === METRICS_GROUP.SEA_LEVEL_RISING;
      const isHazardMetric = type === METRIC_TYPE.HAZARD;

      let countryFilter =
        countriesToLoad && countriesToLoad.length
          ? `-filtered-${countriesToLoad.join("-")}`
          : "";
      if (topCities && countriesToLoad && countriesToLoad.length) {
        countryFilter = `-${countriesToLoad.join("-")}`;
      }
      const files = isHazardMetric
        ? fetchHazardData(metric, years, scenario, countryFilter)
        : fetchImpactData(metric, scenario, countryFilter);

      Promise.all(
        files.map((file, fileIndex) => {
          return new Promise((resolve) => {
            let directory = `IR-level${
              countriesToLoad && countriesToLoad.length ? "-filtered" : ""
            }`;
            if (topCities) {
              directory = `top-cities`;
            }
            window
              .fetch(`${process.env.PUBLIC_URL}/data/${directory}/${file}`)
              .then((d) => d.text())
              .then((csv) => {
                const rows = csvParse(csv);
                const byId = {};
                rows.forEach((row) => {
                  if (isHazardMetric) {
                    row.years = years[fileIndex];
                    if (isSLRMetric) {
                      row.hierid = row.region;
                    }
                  } else {
                    if (isSLRMetric) {
                      row.hierid = row.region;
                    } else {
                      row.hierid = row.Region_ID;
                    }
                  }
                  byId[row.hierid] = row;
                });
                resolve(byId);
              });
          });
        })
      ).then((allRows) => {
        // console.log(allRows)
        let datasets = null;
        if (isHazardMetric) {
          datasets = allRows;
        } else {
          datasets = years.map((year) => {
            const yearData = {};
            Object.keys(allRows[0]).forEach((hierid) => {
              const datum = allRows[0][hierid];
              const yearKey = getIndexYearKey(metric, year);

              yearData[hierid] = { ...datum, 0.5: datum[yearKey] };
            });
            return yearData;
          });
        }

        if (isWithoutBaseline(metric)) {
          const locations = Object.keys(datasets[0]);
          const fakebaselineData = locations.reduce((data, locationID) => {
            data[locationID] = {
              0.5: 0,
              Region_ID: locationID,
              hierid: locationID,
              years: getBaselineYear(metric),
            };
            return data;
          }, {});
          datasets.unshift(fakebaselineData);
        }
        const [min, max] = getMetricExtent(metric, scenario);
        setData((d) => ({ ...d, [metric]: { min, max, datasets } }));
      });
    });
  }, [scenario, countriesToLoad, metricsToLoad, topCities]);

  // console.log("IR ", data);

  return data && data[metric] ? data[metric] : null;
}

export function useRegionNames() {
  const [regionNames, setRegionNames] = useState({});
  useEffect(() => {
    const possibleSeperators = [";", "|"];
    const controller = new AbortController();

    const signal = controller.signal;
    window
      .fetch(`${process.env.PUBLIC_URL}/data/regionNames.csv`, { signal })
      .then((d) => d.text())
      .then((text) => {
        const rows = csvParse(text);
        const byId = {};
        rows.forEach((row) => {
          let name = row.Name;
          let names = null;
          possibleSeperators.forEach((seperator) => {
            if (name.includes(seperator) && !names) {
              names = name.split(seperator).map((n) => n.trim());
            }
          });
          if (names) {
            const maxNames = 2;
            if (names.length > maxNames) {
              names = names.slice(0, maxNames);
              names.push("... ");
            }
            name = names.join("; ");
          }
          if (name.length > 100) {
            // console.log(name)
          }
          byId[row.RegionID] = name;
        });
        setRegionNames(byId);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.log(e);
        }
      });
    return () => {
      try {
        controller.abort();
      } catch (e) {}
    };
  }, []);
  return regionNames;
}
