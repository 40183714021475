import { useEffect, useMemo, useRef, useState } from "react";
import slug from "slug";
import { scaleLinear } from "d3-scale";
import { color as d3color } from "d3-color";
import { makeLayout } from "yogurt-layout";
import {
  getMetricConfig,
  getMetricTicks,
  getPathwayFromScenario,
} from "../../lib/metric-utils";
import { PATHWAY } from "../../lib/metric-constants";
import { LANDLOCK_FILL, LOLLIPOP_RADIUS } from "../../constants";
import classNames from "classnames";
import "./MapLegend.scss";
import { isNil } from "lodash";
import { hsl } from "d3";
// import { DebugLayout } from "../DebugLayout";
import { CountriesDistribution } from "../common/CountriesDistribution";

export const MapLegend = ({
  width,
  extent,
  countryValue,
  hoveredId,
  title,
  subtitle,
  metric,
  colorScale,
  scenario,
  dark = false,
  detailText,
  selectedCountry,
  yearIndex,
}) => {
  const allLegendMaxWidth = Math.min(550, width - 30);
  const showLandlock = getPathwayFromScenario(scenario) === PATHWAY.SSP;

  const layout = makeLayout({
    id: "root",
    width: allLegendMaxWidth,
    height: 66,
    direction: "row",
    children: [
      {
        id: "firstLabel",
        width: 85,
        direction: "column",
        children: [
          { id: "spacer", width: 10, height: 20 },
          {
            id: "landlock",
            direction: "row",
            height: 17,
            children: [
              {
                id: "landlockLegend",
              },
              { id: "spacer", width: 10 },
            ],
          },
          { id: "distributionLabel" },
          { id: "spacer", width: 5 },
        ],
      },
      {
        id: "chartsWrapper",
        direction: "column",
        children: [
          { id: "topLabels", height: 20 },
          {
            id: "charts",
            height: 17,
            children: [{ id: "dataLegend" }],
          },
          { id: "countriesDistribution", height: 12 },
          { id: "bottomLabel", height: 17 },
        ],
      },
    ],
  });

  const legendXScale = scaleLinear()
    .domain(extent)
    .range([layout.dataLegend.left, layout.dataLegend.right])
    .clamp(true);

  const lollipopValue =
    hoveredId && hoveredId.value !== null ? hoveredId.value : null;

  const BAR_PADDING_TOP = 5;
  const gradientId = `${slug(metric)}-detailMap-gradient`;
  const stopPoints = 256;

  const legendGradient = useMemo(
    () => (
      <linearGradient id={gradientId}>
        {[...Array(stopPoints)].map((_, i) => {
          const stop = i / (stopPoints - 1);
          const color = d3color(
            colorScale(extent[0] + (extent[1] - extent[0]) * stop)
          );
          return (
            <stop
              key={i}
              offset={`${stop * 100}%`}
              stopColor={`${color.rgb().toString()}`}
            />
          );
        })}
      </linearGradient>
    ),
    [colorScale, extent, gradientId]
  );

  const TOP_LABELS_PROPS = {
    fontSize: "12px",
    transform: `translate(0 -5)`,
    fontWeight: "500",
  };
  const lightnessValue = getMetricConfig(metric).colorLightness;
  const textColorSelectedCountry =
    hsl(colorScale(countryValue)).l < lightnessValue ? "#fff" : "#000";
  return (
    <div className={classNames("mapLegendWrapper", { dark })}>
      <div className="mapLegend">
        <div>
          <div className="mapLegend__header">
            {title && (
              <div>
                <strong>{title instanceof Function ? title() : title}</strong>
              </div>
            )}
            {detailText && <div>{detailText}</div>}
          </div>
          <div className="mapLegend__subheader">
            <div>{subtitle instanceof Function ? subtitle() : subtitle}</div>
          </div>
        </div>
        <div className="mapLegend__svg-wrapper">
          <svg width={layout.root.width} height={layout.root.height}>
            {showLandlock && (
              <g className="landlock-legend">
                <rect
                  width={layout.landlockLegend.width}
                  height={layout.landlockLegend.height - BAR_PADDING_TOP}
                  x={layout.landlockLegend.left}
                  y={layout.landlockLegend.top + BAR_PADDING_TOP}
                  fill={LANDLOCK_FILL}
                />

                {[layout.landlockLegend.left, layout.landlockLegend.right].map(
                  (x, i) => (
                    <line
                      key={i}
                      x1={x}
                      x2={x}
                      y1={layout.charts.top}
                      y2={layout.charts.bottom}
                      stroke="black"
                    />
                  )
                )}

                <text
                  x={layout.landlockLegend.left}
                  y={layout.landlockLegend.top}
                  {...TOP_LABELS_PROPS}
                >
                  Landlocked
                </text>
              </g>
            )}

            <g>
              <defs>{legendGradient}</defs>

              <rect
                width={layout.dataLegend.width}
                height={layout.dataLegend.height - BAR_PADDING_TOP}
                x={layout.dataLegend.left}
                y={layout.dataLegend.top + BAR_PADDING_TOP}
                fill={`url(#${gradientId})`}
              />
              {!isNil(countryValue) && (
                <g
                  transform={`translate(
                ${legendXScale(countryValue)},0 

              )`}
                >
                  <line
                    y1={layout.countriesDistribution.top}
                    y2={layout.topLabels.top}
                    stroke="black"
                  />
                </g>
              )}
              {getMetricTicks(metric, scenario).map(
                ({ value, label }, tickIndex, arr) => {
                  const x = legendXScale(value);
                  const textAnchor =
                    tickIndex === 0
                      ? "start"
                      : arr.length - 1 === tickIndex
                      ? "end"
                      : "middle";

                  return (
                    <g
                      key={tickIndex}
                      transform={`translate(${x}, ${layout.charts.top})`}
                    >
                      <TickLabel
                        labelProps={TOP_LABELS_PROPS}
                        layout={layout}
                        label={label}
                        textAnchor={textAnchor}
                        dark={dark}
                        value={value}
                      />
                    </g>
                  );
                }
              )}
              {lollipopValue != null && (
                <g
                  transform={`translate(
                  ${legendXScale(lollipopValue)}, 
                  ${layout.charts.top + BAR_PADDING_TOP})`}
                >
                  <circle
                    r={LOLLIPOP_RADIUS}
                    fill={"black"}
                    cx={0}
                    cy={-LOLLIPOP_RADIUS}
                    opacity={0.6}
                  />
                </g>
              )}

              <CountriesDistribution
                metric={metric}
                scenario={scenario}
                yearIndex={yearIndex}
                colorScale={colorScale}
                xScale={legendXScale}
                layout={layout}
              />
            </g>
            {/* <DebugLayout layout={layout} opacity={1} /> */}
          </svg>
          {!isNil(countryValue) && (
            <>
              <div
                className="mapLegend__country-label"
                style={{
                  top: 0,
                  left: legendXScale(countryValue),
                  transform: "translate(-50%,-100%)",
                  backgroundColor: colorScale(countryValue),
                  color: textColorSelectedCountry,
                }}
              >
                {selectedCountry}:{" "}
                {getMetricConfig(metric).metricFormatter(countryValue)}
              </div>
            </>
          )}

          <div
            className="mapLegend__distribution-label"
            style={{ top: layout.countriesDistribution.top + 2 }}
          >
            <div>Countries distribution</div>
            <svg
              width="18"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8536 4.35355C18.0488 4.15829 18.0488 3.84171 17.8536 3.64645L14.6716 0.464465C14.4763 0.269203 14.1597 0.269203 13.9645 0.464465C13.7692 0.659727 13.7692 0.97631 13.9645 1.17157L16.7929 4L13.9645 6.82843C13.7692 7.02369 13.7692 7.34027 13.9645 7.53553C14.1597 7.73079 14.4763 7.73079 14.6716 7.53553L17.8536 4.35355ZM0.563599 4.5L17.5 4.5L17.5 3.5L0.563599 3.5L0.563599 4.5Z"
                fill={dark ? "white" : "black"}
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

function TickLabel({ labelProps, layout, label, textAnchor, dark, value }) {
  const textRef = useRef(null);
  const [textDimensions, setTextDimensions] = useState(null);

  useEffect(() => {
    if (textRef.current) {
      setTextDimensions(textRef.current.getBoundingClientRect());
    }
  }, [value]);
  const xRect =
    textAnchor === "start"
      ? 0
      : textAnchor === "end"
      ? -textDimensions?.width
      : -textDimensions?.width / 2;
  return (
    <>
      {textDimensions && !dark && (
        <rect
          fill="white"
          opacity={0.7}
          width={textDimensions.width}
          height={textDimensions.height - 2}
          x={xRect}
          y={-textDimensions.height}
        />
      )}
      <line y2={layout.charts.height} stroke="black" />
      <text ref={textRef} {...labelProps} textAnchor={textAnchor}>
        {label}
      </text>
    </>
  );
}
