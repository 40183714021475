export const getDropdownCustomStyle = () => ({
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "24px",
    maxHeight: "36px",
    paddingLeft: "8px",
    border: "1px solid black",
    borderRadius: 0,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "112.5%",
    boxShadow: "unset",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
    "&:focus": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#EE402D",
    borderLeft: 0,
    "&:hover": {
      color: "#EE402D",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#006EB5" : "white",
    "&:hover": {
      backgroundColor: state.isSelected ? "#006EB5" : "#F7F7F7",
    },
    padding: "8px 16px",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "500px",
    padding: 0,
    margin: 0,
    border: "1px solid black",
    borderTop: 0,
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 4px",
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  groupHeading: (provided) => ({
    ...provided,
    backgroundColor: "#EDEFF0",
    marginBottom: 0,
    color: "#55606E",
    padding: "6px 10px",
    fontSize: 16,
    fontWeight: 700,
    textTransform: "unset",
  }),
});

export const getDropdownScenarioCustomStyle = (isLight) => ({
  control: (provided) => ({
    ...provided,
    minHeight: "24px",
    maxHeight: "36px",
    paddingLeft: "8px",
    border: isLight ? "1px solid black" : "2px solid black",
    borderRadius: 0,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "112.5%",
    boxShadow: "unset",
    cursor: "pointer",
    "&:hover": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
    "&:focus": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#EE402D",
    borderLeft: 0,
    "&:hover": {
      color: "#EE402D",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#006EB5" : "white",
    "&:hover": {
      backgroundColor: state.isSelected ? "#006EB5" : "#F7F7F7",
    },
    padding: "8px 16px",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    maxHeight: "inherit",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "fit-content",
    padding: 0,
    margin: 0,
    border: isLight ? "1px solid black" : "2px solid black",
    borderTop: 0,
  }),
  groupHeading: (provided) => ({
    ...provided,
    backgroundColor: "#EDEFF0",
    marginBottom: 0,
    color: "#55606E",
    padding: "6px 10px",
    fontSize: 16,
    fontWeight: 700,
    textTransform: "unset",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 4px",
  }),
});

export const getDropdownMetricCustomStyle = () => ({
  control: (provided) => ({
    ...provided,
    minHeight: "24px",
    maxHeight: "36px",
    paddingLeft: "8px",
    border: "1px solid black",
    borderRadius: 0,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "112.5%",
    boxShadow: "unset",
    cursor: "pointer",

    "&:hover": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
    "&:focus": {
      borderColor: "black",
      boxShadow: "unset",
      outline: "unset",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#EE402D",
    borderLeft: 0,
    "&:hover": {
      color: "#EE402D",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    width: "fit-content",
    maxHeight: "inherit",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "500px",
    padding: 0,
    margin: 0,
    border: "1px solid black",
    borderTop: 0,
  }),
  groupHeading: (provided) => ({
    ...provided,
    backgroundColor: "#232E3D",
    marginBottom: 0,
    color: "#FFF",
    padding: "6px 10px",
    fontSize: 16,
    fontWeight: 700,
    textTransform: "unset",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 4px",
  }),
  placeholder: (provider) => ({
    ...provider,
    color: "black",
    textTransform: "uppercase",
  }),
});
