import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import Select from "react-select";
import { getDropdownCustomStyle } from "../../lib/dropdowns";

export default function DetailDropdown({
  values,
  value,
  onChange,
  maxWidth,
  className,
}) {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  let style = {};
  if (maxWidth) {
    style.maxWidth = maxWidth;
  }

  return (
    <div
      className={classNames("detail-dropdown", className)}
      style={{ width: "100%" }}
    >
      <Select
        value={value}
        options={values}
        styles={getDropdownCustomStyle()}
        onChange={onChange}
      />
    </div>
  );
}
