export const globeTexts = [
  {
    text: <div className="introCopy centered">loading...</div>,
    countryRadiusMultiplier: 0,
    globeScale: 0,
    hideLegend: true,
    noNav: true,
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          <strong>The latest data release is now accessible!</strong> Featuring
          projections of localized sea level rise along coastlines relative to a
          1995-2014 average, the platform unveils impacts on people and human
          development worldwide, including at-risk populations and areas
          vulnerable to expanding floodplains. Each indicator provides
          information for over 5,000 coastal regions and covers three emissions
          scenarios over the entire 21st century.
        </p>
        <p>
          Built upon cutting-edge scientific research, this platform serves as a
          dynamic digital public resource that visualizes impacts of climate
          change on people and its implications for human development. It shows
          the effects we face today and how they are projected to evolve over
          the 21st century, providing hyperlocal coverage of the entire planet,
          bringing into focus which regions and locations are most at risk. In
          addition to the new sea level rise data, the platform also includes
          indicators from the first release including temperature projections,
          and impacts on mortality, labor disutility, and energy usage.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    hideLegend: true,
    navLabel: "Intro",
    showIntroButtons: true,
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          The ocean's surface is rising as the climate changes, with ice sheets
          and glaciers melting and seawater expanding as it warms. Global mean
          sea level has risen nearly 23 centimeters since the late nineteenth
          century.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "sea level rising cm",
    hideLegend: false,
    navLabel: "Sea Level Rise",
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          Rising water threatens to alter the landscape of coastal regions by
          submerging land and infrastructure.
        </p>
        <p>
          Without shoreline defenses, climate change is projected to cause
          permanent inundation of more than 6% of the land in Cần Thơ, Viet Nam
          by midcentury.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "share of land",
    hideLegend: false,
    countryIRHighlight: "VNM",
    hierId: "VNM.1.5",
    countryOffset: "right",
    navLabel: (
      <>
        Land Inundation Impact
        <br />
        Cần Thơ, Viet Nam
      </>
    ),
    overrideCameraPosition: {
      globeZoomedInCameraLatitudeOffset: -5,
      globeZoomedInCameraLongitudeOffset: 20,
    },
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          Higher sea levels make it possible for floods to push further inland,
          which could have damaging consequences for the 1-in-7 people worldwide
          who live in coastal communities.
        </p>
        <p>
          In Santos, Brazil, by midcentury climate change could expand the
          20-year coastal floodplain to land that's currently home to 4.88% of
          the population.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "share of population",
    countryOffset: "left",
    hideLegend: false,
    countryIRHighlight: "BRA",
    hierId: "BRA.25.Re40fc83ec83ed646",
    navLabel: (
      <>
        Flood Exposure Impact
        <br />
        Santos, Brazil
      </>
    ),
    overrideCameraPosition: {
      globeZoomedInCameraLatitudeOffset: -12,
      globeZoomedInCameraLongitudeOffset: -15,
    },
  },
  {
    text: (unit) => (
      <div className="introCopy centered">
        <p>
          Earth's average temperature rose by nearly{" "}
          {unit === "c" ? "1.2°C" : "2.16°F"} since the late nineteenth
          century--reflecting changes to the entire surface area of the planet.
        </p>
        <p>
          However, billions of humans live in regions that have already
          experienced warming greater than the global average. The Climate
          Impact Lab’s localized temperature projections show how patterns of
          future warming vary from place to place, and season to season.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "temperature F",
    hideLegend: false,
    navLabel: "Temperature",
  },

  {
    text: (unit) => (
      <div className="introCopy centered">
        <p>
          In the 1990s, Maracaibo, Venezuela, could expect an average of 62
          annual days with temps over {unit === "c" ? "35°C" : "95°F"}. By
          midcentury, that rises to 201 days over{" "}
          {unit === "c" ? "35°C" : "95°F"} each year.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "days over 95f",
    hideLegend: false,
    countryIRHighlight: "VEN",
    hierId: "VEN.24.311",
    navLabel: (
      <>
        Temperature
        <br />
        Maracaibo, Venezuela
      </>
    ),
  },
  {
    text: (unit) => (
      <div className="introCopy centered">
        <p style={{ maxWidth: "30em" }}>
          In the 1990s, Warsaw, Poland, could expect 117 days annually when
          temps dip below freezing ({unit === "c" ? "0°C" : "32°F"}). By
          midcentury, climate models show that Warsaw can expect roughly 82
          annual days below freezing.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "days under 32f",
    hideLegend: false,
    countryIRHighlight: "POL",
    countryOffset: "right",
    hierId: "POL.9.222",
    navLabel: (
      <>
        Temperature
        <br />
        Warsaw, Poland
      </>
    ),
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          Electricity and other fuels play a crucial role in our ability to cope
          with these extreme temperatures, powering air conditioners and fans to
          keep us cool, and heaters to keep us warm.
        </p>
        <p>
          Yet, the impacts of climate change on energy use will vary locally, as
          individuals, communities, and businesses adapt to local conditions
          using available resources.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "electricity",
    hideLegend: false,
    navLabel: "Energy Impact",
  },

  {
    text: (
      <div className="introCopy centered">
        <p>
          Electricity consumption in response to warmer temperatures in Jakarta
          is projected to increase by 0.4 gigajoules per capita, roughly ⅓ of
          current household electricity consumption in Indonesia, and will
          require critical infrastructure planning.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "electricity",
    hideLegend: false,
    countryIRHighlight: "IDN",
    hierId: "IDN.8.68",
    navLabel: (
      <>
        Energy Impact
        <br />
        Jakarta, Indonesia
      </>
    ),
    overrideCameraPosition: {
      globeZoomedInCameraLatitudeOffset: -10,
    },
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          More frequent and severe temperature extremes also impact our ability
          to work as we do today, affecting our ability to perform tasks, and
          influencing work intensity and duration.{" "}
        </p>
        <p>
          The impact of climate change differs across sectors of the economy,
          with workers in high-risk, weather-exposed industries like
          agriculture, construction, mining and manufacturing most affected.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "highrisk labor",
    hideLegend: false,
    navLabel: "Labor Impact",
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          In Niamey, Niger, heat leads workers in high-risk, weather-exposed
          sectors like construction, mining, and manufacturing, to spend by 36
          fewer hours working each year. Overall impacts to the workforce in
          Niger cause damages equivalent to 2.5% of the country’s future GDP.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "highrisk labor",
    hideLegend: false,
    countryIRHighlight: "NER",
    hierId: "NER.5.18.50",
    navLabel: (
      <>
        Labor Impact
        <br />
        Niamey, Niger
      </>
    ),
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          Our cardiovascular and respiratory systems get stressed when we face
          hotter and hotter temperatures. This effect is going to be important
          everywhere in the world as we face a warmer climate, but it will look
          very different in places that have the means to respond and adapt to
          that heat than in places that do not.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "mortality",
    hideLegend: false,
    navLabel: "Mortality Impact",
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          Climate change could increase mortality rates in Faisalabad, Pakistan
          by nearly 67 deaths per 100,000 population. That effect would be
          deadlier than strokes--currently Pakistan’s third leading cause of
          death.{" "}
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "mortality",
    hideLegend: false,
    countryIRHighlight: "PAK",
    hierId: "PAK.7.21.84",
    navLabel: (
      <>
        Mortality Impact
        <br />
        Faisalabad, Pakistan
      </>
    ),
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          In Riyadh, Saudi Arabia, where the future climate is similar but
          incomes are higher, climate change could increase mortality rates by
          35 deaths per 100,000 population. That effect is deadlier than
          Alzheimer’s disease, currently the sixth leading cause of death
          globally.{" "}
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "mortality",
    hideLegend: false,
    countryIRHighlight: "SAU",
    countryOffset: "right",
    hierId: "SAU.7",
    navLabel: (
      <>
        Mortality Impact
        <br />
        Riyadh, Saudi Arabia
      </>
    ),
  },
  {
    text: (
      <div className="introCopy centered">
        <p>
          As the platform shows, impacts of climate change are not evenly
          distributed across the world and will spark a significant expansion of
          inequalities over the coming years and decades. As our future is not
          predetermined, the Human Climate Horizons mission is to ensure equal
          access to data about those future impacts, inform decision-making, and
          help everyone understand the human consequences of climate change in
          different scenarios, so everyone can play a role in changing the
          conversation and choosing the path forward.
        </p>
      </div>
    ),
    countryRadiusMultiplier: "hidden",
    metric: "temperature F",
    hideLegend: false,
    navLabel: "Closing",
    overrideClickToAdvanceLabel: "Click to advance to explore mode",
  },
  {
    exploreMode: true,
    hideLegend: false,
    navLabel: "Explore",
    countryRadiusMultiplier: "minimal",
  },
];
