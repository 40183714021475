import "./FilterTop.scss";
import DetailDropdown from "../UI/DetailDropdown";
import { CountriesDropdown } from "../UI/CountriesDropdown";

export const FilterTop = ({
  countryLookup,
  countryId,
  setNavigation,
  yearIndex,
  yearLabels,
  setYearIndex,
  gotoExplore,
}) => {
  const selectedCountry = countryLookup.find(
    (country) => countryId === country.code3
  );
  const selectedCountryValue = {
    label: selectedCountry.name,
    value: countryId,
  };
  const selectedYear = {
    label: yearLabels[yearIndex],
    value: yearIndex,
  };
  return (
    <section className="filter-top">
      <CountriesDropdown
        countryValues={countryLookup.map((c) => ({
          label: c.name,
          value: c.code3,
        }))}
        value={selectedCountryValue}
        onChange={(country) => setNavigation({ country: country.value })}
        onClickGlobe={gotoExplore}
      />

      <div className="time-dropdown dropdown-wrapper">
        <div className="ui-label">TIME HORIZON</div>
        <DetailDropdown
          value={selectedYear}
          values={yearLabels.map((y, i) => ({ label: y, value: i }))}
          onChange={(v) => setYearIndex(+v.value)}
        />
      </div>
    </section>
  );
};
