const disclaimers = {
  'KO-': <><sup>1</sup> References to Kosovo shall be understood to be in the context of Security Council Resolution 1244 (1999).</>,
  'ESH': 'Territory is classified by the UN as a Non-Self-Governing Territory.',
  'SDN': 'The boundaries and names shown and the designations used on this map do not imply official endorsement or acceptance by the United Nations. Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined. Final status of the Abyei area is not yet determined.',
  'SSD': 'The boundaries and names shown and the designations used on this map do not imply official endorsement or acceptance by the United Nations. Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined. Final status of the Abyei area is not yet determined.',
  'PAK': 'The line between India and Pakistan in Jammu and Kashmir represents approximately the Line of Control agreed upon by the countries The final status of Jammu and Kashmir has not yet been agreed upon by India and Pakistan.',
  'IND': 'The line between India and Pakistan in Jammu and Kashmir represents approximately the Line of Control agreed upon by the countries The final status of Jammu and Kashmir has not yet been agreed upon by India and Pakistan.',
  'TWN': "The designation employed do not imply the expression of any opinion whatsoever on the part of the Secretariat of the United Nations concerning the legal status of any country, territory or area, or of its authorities",
  'ARG': 'A dispute exists between the Governments of Argentina and the United Kingdom of Great Britain and Northern Ireland concerning sovereignty over the Falkland Islands (Malvinas).',


}

export default disclaimers

