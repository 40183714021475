import { interpolateTurbo } from "d3-scale-chromatic";

import { interpolateRgbBasis } from "d3-interpolate";
import chroma from "chroma-js";
export default function getColorInterpolator(metric) {
  const mortality = interpolateRgbBasis([
    "#5e3c99",
    "#b2abd2",
    "#fdb863",
    "#e66101",
  ]);
  const labor = interpolateRgbBasis([
    "#c51b7d",
    "#e9a3c9",
    "#fde0ef",
    "#e6f5d0",
    "#a1d76a",
    "#4d9221",
  ]);
  const energy = interpolateRgbBasis([
    "#8c510a",
    "#d8b365",
    "#f6e8c3",
    "#c7eae5",
    "#5ab4ac",
    "#01665e",
  ]);

  const energyIndex = energy;
  const laborIndex = labor;
  const mortalityIndex = mortality;

  const metrics = {
    "temperature F": interpolateRgbBasis([
      "#C5E5EF",
      "#FBED7C",
      "#FFC642",
      "#FF9434",
      "#FF5922",
      "#D4322C",
      "#A50026",
    ]),
    "days over 95f": interpolateRgbBasis([
      "#FBED7C",
      "#FFC642",
      "#FF9434",
      "#FF5922",
      "#D4322C",
      "#A50026",
      "#662506",
    ]),
    "days under 32f": interpolateRgbBasis([
      "#D2F2FA",
      "#9FDFF5",
      "#59B8E5",
      "#288ECE",
      "#0055B0",
      "#002C8D",
      "#000670",
    ]),
    "mortality risk index": mortalityIndex,
    "labor risk index": laborIndex,
    "energy risk index": energyIndex,
    mortality: mortality,
    "highrisk labor": labor,
    "lowrisk labor": labor,
    electricity: energy,
    "other energy": energy,
    "sea level rising cm": chroma
      .scale([
        "#EBE9DB",
        "#C2E4E0",
        "#3cf2dd",
        "#00e2ee",
        "#00cff5",
        "#00a2e9",
        "#1071ce",
        "#1856ac",
        "#173d8b",
        "#081D58",
      ])
      .mode("lch"),
    "share of population": interpolateRgbBasis(['#FFF4C8', '#FFD07E', '#EA6800', '#B33E00', '#772600', '#4B1800']),
    "share of land": interpolateRgbBasis([
      "#FFD1B9",
      "#FF9086",
      "#FF618E",
      "#e23e99",
      "#99017b",
      "#49006a",
    ]),
    land: interpolateRgbBasis([
      "#FFD1B9",
      "#FF9086",
      "#FF618E",
      "#e23e99",
      "#99017b",
      "#49006a",
    ]),
  };
  return metrics[metric] || interpolateTurbo;
}
