import "./App.scss";
import { useEffect, useState, useCallback, useRef } from "react";
import countryLookup from "./data/countryLookup";
import { usePreviousDistinct, useWindowSize } from "react-use";
import React from "react";
import classNames from "classnames";
import { Leva, useControls } from "leva";
import { useLocation, useMatch, useParams } from "react-router-dom";
import CountryDetail from "./CountryDetail";
import RiskComparison from "./RiskComparison";
import Header from "./Header";
import Footer from "./Footer";
import pymjs from "pym.js";
import ReactTooltip from "react-tooltip";
import News from "./News";
import WhatIsHCH from "./WhatIsHCH";
import { globeTexts } from "./lib/globe-constants";

import {
  METRICS_CONFIGS,
  METRICS_GROUP,
  SCENARIO,
} from "./lib/metric-constants";

import { GlobePage } from "./components/globePage/GlobePage";
import { split } from "lodash";

globeTexts.forEach((text) => {
  if (text.metric && !text.alternateTexture) {
    const group = METRICS_CONFIGS.find((metric) => metric.key === text.metric);
    const scenario =
      group === METRICS_GROUP.TEMPERATURE ? SCENARIO.RCP45 : SCENARIO.SSP245;
    text.alternateTexture = `${text.metric}-2040-2059-${scenario}.png`;
  }
});

const countriesToLoad = Array.from(
  new Set(globeTexts.map((text) => text.countryIRHighlight).filter((d) => d))
);

countriesToLoad.sort();

const allCountriesCode = new Set(countryLookup.map((c) => c.code3));

function App() {
  const [texturesInitialized, setTexturesInitialized] = useState(false);
  const [allTexturesInitalized, setAllTexturesInitialized] = useState(false);

  const [globalCountrySelection, setGlobalCountrySelection] = useState(
    countriesToLoad[Math.floor(countriesToLoad.length * Math.random())]
  );
  const [riskComparisonTooltipOpen, setRiskComparisonTooltipOpen] =
    useState(false);
  const [
    riskComparisonLockedTooltipIndex,
    setRiskComparisonLockedTooltipIndex,
  ] = useState(0);

  const [{ textIndex }, set] = useControls(() => ({
    textIndex: { value: 0, min: 0, max: globeTexts.length - 1, step: 1 },
  }));
  const setTextIndex = useCallback(
    (index) => {
      set({ textIndex: index });
    },
    [set]
  );

  const [textOpacity, setTextOpacity] = useState(0);

  const params = useParams();

  const countryParam = allCountriesCode.has(params.countryId);
  const countrySelected = params && countryParam;

  const globePage = useMatch("/");
  const news1 = useMatch("/news");
  const news2 = useMatch("/news/:slug");
  const news = news1 || news2;
  const whatIsHCH = useMatch("/what-is-hch");
  const riskComparison = useMatch("/risk");
  const riskComparison2 = useMatch("/risk/:rcp");
  const riskComparison3 = useMatch("/risk/:rcp/:countryId");
  const riskPage = riskComparison || riskComparison2 || riskComparison3;
  const globeControlPage = !countrySelected && !(news || whatIsHCH || riskPage);

  const [loadAdditionalTextures, setLoadAdditionalTextures] = useState(false);

  const windowSize = useWindowSize();

  const location = useLocation();
  const actualPageParam = split(location.pathname, "/", 2);
  const prevPageParam = usePreviousDistinct(actualPageParam[1]);

  const showIntroButtons = globeTexts[textIndex].showIntroButtons
    ? globeTexts[textIndex].showIntroButtons
    : false;
  const [introButtonsVisible, setIntroButtonsVisible] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (showIntroButtons) {
      timeout = setTimeout(() => {
        setIntroButtonsVisible(true);
        setLoadAdditionalTextures(true);
      }, 3000);
    } else {
      timeout = setTimeout(() => {
        setIntroButtonsVisible(false);
      }, 0);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showIntroButtons]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [prevPageParam]);

  const width = windowSize.width;
  const headerHeight = 117;
  const hasTouch = "ontouchstart" in window;
  const isMobile = hasTouch && width < 800;

  const height = windowSize.height - headerHeight;

  useEffect(() => {
    var pymChild = new pymjs.Child();
    if (!countrySelected) {
      document.getElementById("root").style.height = `${height}px`;
    } else {
      document.getElementById("root").style.height = ``;
    }
    pymChild.sendHeight();
    requestAnimationFrame(() => {
      pymChild.sendHeight();
    });
  }, [countrySelected, height]);

  const advance = useCallback(
    (event) => {
      if (!texturesInitialized) {
        return;
      }
      let direction = event.shiftKey ? -1 : 1;
      const newTextIndex = textIndex + direction;
      if (newTextIndex < 0 || newTextIndex >= globeTexts.length) {
        return;
      }
      setTextOpacity(0);
      setTimeout(() => {
        setTextIndex(newTextIndex);
        setTextOpacity(1);
      }, 400);
    },
    [textIndex, texturesInitialized, setTextIndex]
  );

  const advancedFromTextures = useRef(false);
  useEffect(() => {
    if (texturesInitialized && !advancedFromTextures.current) {
      advance({});
      advancedFromTextures.current = true;
    }
  }, [texturesInitialized, advance]);

  const skipIntro = useCallback(() => {
    setTextIndex(globeTexts.length - 1);
  }, [setTextIndex]);

  const forceExplore = useRef(false);

  const gotoExplore = () => {
    if (!allTexturesInitalized) {
      advancedFromTextures.current = true;
      setLoadAdditionalTextures(true);
      forceExplore.current = true;
    } else {
      skipIntro();
    }
  };

  useEffect(() => {
    if (allTexturesInitalized && forceExplore.current) {
      skipIntro();
      forceExplore.current = false;
    }
  }, [allTexturesInitalized, skipIntro]);

  const resetIntro = () => {
    if (advancedFromTextures.current) {
      setTextIndex(1);
    } else {
      setTexturesInitialized(false);
      setAllTexturesInitialized(false);
      advancedFromTextures.current = false;
      setTextIndex(0);
    }
  };

  return (
    <div className={classNames("App", { isMobile })}>
      <Leva collapsed theme={{ sizes: { rootWidth: "440px" } }} hidden={true} />

      <Header country={globalCountrySelection} resetIntro={resetIntro} />

      {globeControlPage && (
        <GlobePage
          setGlobalCountrySelection={setGlobalCountrySelection}
          isMobile={isMobile}
          countrySelected={countrySelected}
          textIndex={textIndex}
          setTextIndex={setTextIndex}
          loadAdditionalTextures={loadAdditionalTextures}
          skipIntro={skipIntro}
          advance={advance}
          setTexturesInitialized={setTexturesInitialized}
          textOpacity={textOpacity}
          setTextOpacity={setTextOpacity}
          allTexturesInitalized={allTexturesInitalized}
          setAllTexturesInitialized={setAllTexturesInitialized}
          showIntroButtons={showIntroButtons}
          introButtonsVisible={introButtonsVisible}
        />
      )}
      {countrySelected && !riskPage && (
        <CountryDetail
          setGlobalCountrySelection={setGlobalCountrySelection}
          countryId={params.countryId}
          gotoExplore={gotoExplore}
        />
      )}
      {riskPage && (
        <RiskComparison
          riskComparisonTooltipOpen={riskComparisonTooltipOpen}
          setRiskComparisonTooltipOpen={setRiskComparisonTooltipOpen}
          lockedTooltipYearIndex={riskComparisonLockedTooltipIndex}
          setLockedTooltipYearIndex={setRiskComparisonLockedTooltipIndex}
          setGlobalCountrySelection={setGlobalCountrySelection}
          countryId={params.countryId}
          globalCountrySelection={globalCountrySelection}
          gotoExplore={gotoExplore}
        />
      )}
      {news ? <News /> : null}
      {whatIsHCH ? <WhatIsHCH /> : null}
      <Footer margin={!globePage} />
      <ReactTooltip
        arrowColor="transparent"
        className="reactTooltip"
        place="bottom"
      />
    </div>
  );
}

export default App;
