import COUNTRY_LOOKUP from "./data/countryLookup";
import { useCountryData } from "./lib/useCountryData";
import { useRef, useEffect, useMemo } from "react";
import { scaleSequential, scaleLinear, scaleBand } from "d3-scale";
import getColorInterpolator from "./getColorInterpolator";
import { years, yearLabels } from "./constants";
import { Delaunay } from "d3-delaunay";
import { color as d3color, hsl } from "d3-color";
import slug from "slug";
import WorldSummary from "./data/WorldSummary.csv.js";
import ReactTooltip from "react-tooltip";
import _, { compact, isNil, partition } from "lodash";
import {
  getBaselineYear,
  getMetricExtent,
  getMetricTicks,
  isMetricInSelectedScenario,
} from "./lib/metric-utils";

import "./Barcode.scss";
import { MetricHeader } from "./components/comparePage/MetricHeader";
import { makeLayout } from "yogurt-layout";
import { useElementDimension } from "./lib/hook/useElementDimension.js";
import { METRIC } from "./lib/metric-constants";

const COUNTRY_BY_ISO = _.keyBy(COUNTRY_LOOKUP, "code3");
const getCountruNameFromISO = (ISO) => COUNTRY_BY_ISO[ISO]?.name;

export const COLUMN_PADDING = 5;
export const defaultRiskComparisonCountryValue = "globalAverage";

function formatYear(year, collapse) {
  if (collapse) return year;
  const index = years.indexOf(year);

  if (index === -1) return year + " (Baseline)";
  return yearLabels[index];
}

export function drawPoint(
  row,
  xScale,
  yScale,
  colorScale,
  context,
  delaunayPoints,
  selected = false
) {
  const value = +row["0.5"];
  const year = row.year;
  const x = xScale(year);
  const y = yScale(value);

  const width = xScale.bandwidth();
  const color = typeof colorScale === "string" ? colorScale : colorScale(value);
  context.strokeStyle = color;
  context.lineWidth = selected ? 2 : 1;
  context.beginPath();
  context.moveTo(x, y);
  context.lineTo(x + width, y);
  context.stroke();
  const midPoint = x + width / 2;
  if (delaunayPoints) {
    delaunayPoints.current.push([midPoint, y]);
  }
}

function drawLine(from, to, xScale, yScale, colorScale, context) {
  const x1 = xScale(from.year) + xScale.bandwidth();
  const x2 = xScale(to.year);
  const y1 = yScale(+from["0.5"]);
  const y2 = yScale(+to["0.5"]);

  const color = colorScale(+to["0.5"]);
  context.strokeStyle = color;
  context.lineWidth = 2;
  context.beginPath();
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

export function Barcode({
  group,
  metric,
  scenario,
  country,
  contentWidth,
  collapse,
  hoveredPoint,
  setHoveredPoint,
  selectCountry,
  setLockedTooltipYearIndex,
  lockedTooltipYearIndex,
  setRiskComparisonTooltipOpen,
  countryGroup,
}) {
  const canvasContainerRef = useRef(null);
  const { width } = useElementDimension(canvasContainerRef);
  // const width = 1000;
  const delaunay = useRef();
  const backgroundCanvasRef = useRef();
  const canvasRef = useRef();
  const delaunayPoints = useRef([]);
  const averageCanvasRef = useRef();

  const allData = useCountryData({ metric: metric.key, scenario });

  // const allData = countryGroup
  //   ? allData1?.filter((d) => countryGroup?.group.includes(d.ISO))
  //   : allData1;

  const metricKey = metric.key;
  let label = metric.riskHeader;
  if (label instanceof Function) {
    label = label();
  }
  let riskGraphLabel = metric.riskGraphLabel;
  if (riskGraphLabel instanceof Function) {
    riskGraphLabel = riskGraphLabel();
  }

  const baseline = getBaselineYear(metricKey);

  const extent = useMemo(
    () => getMetricExtent(metricKey, scenario),
    [metricKey, scenario]
  );

  const colorScale = useMemo(() => {
    return scaleSequential()
      .domain(extent)
      .interpolator(getColorInterpolator(metricKey))
      .clamp(true);
  }, [metricKey, extent]);

  const legendWidth = 50;

  let columnWidth = (contentWidth - legendWidth) / 4;
  let canvasWidth = width;
  let canvasHeight = 300;

  if (collapse) {
    columnWidth = (contentWidth - legendWidth) / 3;
    canvasWidth = columnWidth * 3;
    canvasHeight = columnWidth * 2;
  }

  const layout = makeLayout({
    id: "root",
    width: canvasWidth,
    height: canvasHeight,
    direction: "column",
    children: [
      { id: "years", height: collapse ? 20 : 40 },
      { id: "_space", height: 28 },
      {
        id: "chart",
        children: [
          { id: "barcode" },
          { id: "_space", width: COLUMN_PADDING },
          { id: "legend", width: 60 },
        ],
      },
      { id: "_space", height: collapse ? 1 : 10 },
    ],
  });

  const yearsData = [baseline, ...years];

  const xScale = useMemo(() => {
    return scaleBand()
      .domain(yearsData)
      .range([layout.barcode.left, layout.barcode.right])
      .paddingInner(COLUMN_PADDING / (layout.barcode.width / yearsData.length));
  }, [
    layout.barcode.left,
    layout.barcode.right,
    layout.barcode.width,
    baseline,
    years,
  ]);

  const yScale = useMemo(() => {
    return scaleLinear()
      .domain(extent)
      .range([layout.barcode.bottom, layout.barcode.top])
      .clamp(true);
  }, [layout.barcode.bottom, layout.barcode.top, extent]);

  //
  // Draw lines for all countries
  //
  const worldData = WorldSummary.filter(
    (d) => d.metric === metricKey && d.scenario === scenario
  );

  useEffect(() => {
    const averageCanvas = averageCanvasRef.current;
    if (!averageCanvas || !worldData) {
      return;
    }
    const context = averageCanvas.getContext("2d");
    context.clearRect(0, 0, canvasWidth, canvasHeight);
    worldData.forEach((row) => {
      drawPoint(row, xScale, yScale, "black", context, null, false);
    });
  }, [worldData, canvasWidth, canvasHeight]);

  const [allDataFilteredByGroup, allDataNotInGroup] = useMemo(() => {
    const groupCountrySet = new Set(countryGroup?.group);
    return partition(allData, (d) => groupCountrySet.has(d.ISO));
  }, [countryGroup, allData]);

  useEffect(() => {
    const backgroundCanvas = backgroundCanvasRef.current;
    if (!backgroundCanvas || !allData) {
      return;
    }

    const context = backgroundCanvas.getContext("2d");
    context.clearRect(0, 0, canvasWidth, canvasHeight);

    delaunayPoints.current = [];

    const countryOpacity = hoveredPoint || country ? 0.2 : 1;
    context.save();
    if (countryGroup?.group.length > 0) {
      allDataNotInGroup.forEach((row) => {
        drawPoint(row, xScale, yScale, "#f2f2f2", context, null, false);
      });
      context.globalAlpha = countryOpacity;
      allDataFilteredByGroup.forEach((row) => {
        drawPoint(
          row,
          xScale,
          yScale,
          colorScale,
          context,
          delaunayPoints,
          false
        );
      });
    } else {
      context.globalAlpha = countryOpacity;
      allData.forEach((row) => {
        drawPoint(
          row,
          xScale,
          yScale,
          colorScale,
          context,
          delaunayPoints,
          false
        );
      });
    }
    context.restore();
    delaunay.current = Delaunay.from(delaunayPoints.current);
  }, [
    allData,
    hoveredPoint,
    country,
    countryGroup,
    canvasWidth,
    canvasHeight,
    selectCountry,
  ]);

  // const backgroundCanvasOpacity = hoveredPoint || country ? 0.2 : 1;

  const hoverCanvas = (event) => {
    const xy = [event.clientX, event.clientY];
    const canvasPos = backgroundCanvasRef.current.getBoundingClientRect();
    const x = xy[0] - canvasPos.left;
    const y = xy[1] - canvasPos.top;
    if (delaunay.current) {
      const point = delaunay.current.find(x, y);
      const data =
        countryGroup?.group.length > 0
          ? allDataFilteredByGroup[point]
          : allData[point];
      if (!data) {
        return;
      }
      const ISO = data.ISO;
      const year = data.year;

      if (
        hoveredPoint &&
        hoveredPoint.ISO === ISO &&
        hoveredPoint.year === year
      ) {
        return;
      }
      setHoveredPoint({ ISO: data.ISO, year: data.year, key: metricKey });
    }
  };
  const hoverOffCanvas = () => {
    setHoveredPoint(null);
  };

  const clickCanvas = () => {
    if (hoveredPoint) {
      selectCountry({ country: hoveredPoint.ISO });
      setLockedTooltipYearIndex(years.indexOf(hoveredPoint.year));
      setRiskComparisonTooltipOpen(true);
    }
  };

  const hoveredPointData = useMemo(() => {
    if (hoveredPoint == null || allData == null) {
      return null;
    }
    const matches = allData.filter((d) => d.ISO === hoveredPoint.ISO);
    return matches;
  }, [hoveredPoint, allData]);

  const selectedCountryData = useMemo(() => {
    if (!country || !allData) {
      return null;
    } else if (
      allData
        .filter((d) => d.ISO === country.code3)
        .every((r) => r["0.5"] === "" || r["0.5"] === 0)
    ) {
      return null;
    }

    return allData.filter((d) => d.ISO === country.code3);
  }, [country, allData]);

  useEffect(() => {
    if (!canvasRef.current || !allData) {
      return;
    }

    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasWidth, canvasHeight);

    const pointsGroups = compact([selectedCountryData, hoveredPointData]);

    pointsGroups.forEach((points) => {
      const links = points.slice(1).map((to, i) => [points[i], to]);

      links.forEach(([from, to]) => {
        drawLine(from, to, xScale, yScale, colorScale, context);
      });

      points?.forEach((row) => {
        drawPoint(row, xScale, yScale, colorScale, context, null, true);
      });
    });
  }, [
    allData,
    hoveredPoint,
    hoveredPointData,
    selectedCountryData,
    canvasWidth,
    canvasHeight,
    colorScale,
    yScale,
    xScale,
  ]);

  const averageTooltips = useMemo(() => {
    const tooltipData = [];

    worldData?.forEach((row, i) => {
      tooltipData.push({
        point: row,
        type: "average",
        opacity: 1,
        showLabel: i === 0,
      });
    });
    return tooltipData
      .filter((d) => !isNil(d.point))
      .map(({ point, type, showLabel, opacity }, tooltipIndex) => {
        return (
          <BarcodeTooltip
            key={tooltipIndex}
            tooltipIndex={tooltipIndex}
            colorScale={colorScale}
            type={type}
            xScale={xScale}
            yScale={yScale}
            point={point}
            metric={metric}
            opacity={opacity}
            showLabel={showLabel}
          />
        );
      });
  }, [worldData, metric, colorScale, xScale, yScale]);

  const tooltips = useMemo(() => {
    const tooltipData = [];

    const hasHoveredPointData =
      country?.code3 !== hoveredPoint?.ISO && hoveredPointData?.length > 0;
    if (selectedCountryData) {
      selectedCountryData.forEach((row) => {
        tooltipData.push({
          point: row,
          type: "dropdown",
          opacity: hasHoveredPointData ? 0.6 : 1,
          showLabel: (years[lockedTooltipYearIndex] ?? baseline) === row.year,
        });
      });
    }

    if (hasHoveredPointData) {
      hoveredPointData?.forEach((row) => {
        tooltipData.push({
          point: row,
          type: "hover",
          opacity: 1,
          showLabel: hoveredPoint.year === row.year,
        });
      });
    }

    return tooltipData
      .filter((d) => !isNil(d.point))
      .map(({ point, type, showLabel, opacity }, tooltipIndex) => {
        return (
          <BarcodeTooltip
            key={tooltipIndex}
            colorScale={colorScale}
            type={type}
            xScale={xScale}
            yScale={yScale}
            point={point}
            metric={metric}
            showLabel={showLabel}
            opacity={opacity}
            onClose={() => {
              selectCountry({ country: defaultRiskComparisonCountryValue });
              setRiskComparisonTooltipOpen(false);
            }}
          />
        );
      });
  }, [
    baseline,
    hoveredPoint,
    hoveredPointData,
    metric,
    colorScale,
    country,
    xScale,
    yScale,
    selectedCountryData,
    selectCountry,
    lockedTooltipYearIndex,
    setRiskComparisonTooltipOpen,
  ]);

  const legendBarWidth = 10;
  const gradientId = `${slug(metricKey)}-riskcomparison-gradient`;
  const stopPoints = 256;

  const legendGradient = useMemo(
    () => (
      <linearGradient id={gradientId} x1={0} x2={0} y1={1} y2={0}>
        {[...Array(stopPoints)].map((_, i) => {
          const stop = i / (stopPoints - 1);
          const color = d3color(
            colorScale(extent[0] + (extent[1] - extent[0]) * stop)
          );
          return (
            <stop
              key={i}
              offset={`${stop * 100}%`}
              stopColor={`${color.rgb().toString()}`}
            />
          );
        })}
      </linearGradient>
    ),
    [colorScale, extent, gradientId]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const cursor = hoveredPoint ? "pointer" : "default";
  const countryCheckLandlock = country ? selectedCountryData : true;
  return (
    <div key={metricKey} className="metric-compare">
      <MetricHeader metric={metric} scenario={scenario} country={country} />
      <div className="columns">
        <div
          className="canvasContainer"
          ref={canvasContainerRef}
          style={{
            overflow: "visible",
            width: "100%",
            height: canvasHeight,
            cursor,
          }}
        >
          {worldData.length > 0 && countryCheckLandlock ? (
            <>
              <canvas
                className="backgroundCanvas"
                ref={backgroundCanvasRef}
                width={canvasWidth}
                height={canvasHeight}
                onMouseMove={hoverCanvas}
                onMouseOut={hoverOffCanvas}
                onClick={clickCanvas}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              />
              <canvas
                ref={averageCanvasRef}
                width={canvasWidth}
                height={canvasHeight}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  pointerEvents: "none",
                  opacity: 1,
                }}
              />

              <canvas
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  pointerEvents: "none",
                }}
              />

              {averageTooltips}
              {tooltips}
            </>
          ) : (
            <div>
              {yearsData.map((year) => {
                return (
                  <div
                    key={`empty-block-${metric.key}-${year}`}
                    className="empty-block"
                    style={{
                      position: "absolute",
                      left: xScale(year),
                      width: xScale.bandwidth(),
                      height: layout.barcode.height,
                      top: layout.barcode.top,
                    }}
                  >
                    {isMetricInSelectedScenario(metricKey, scenario)
                      ? "Landlocked"
                      : `Select an available scenario to show ${group} data`}
                  </div>
                );
              })}
            </div>
          )}

          <svg
            {...layout.root}
            style={{
              pointerEvents: "none",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <defs>{legendGradient}</defs>
            <rect
              x={layout.legend.left}
              y={layout.legend.top}
              width={legendBarWidth}
              height={layout.legend.height}
              fill={`url(#${gradientId})`}
            />
            {getMetricTicks(metric.key, scenario).map(({ value, label }, i) => {
              return (
                <g
                  key={i}
                  transform={`translate(
                    ${layout.legend.left}, 
                    ${yScale(value)}
                    )`}
                >
                  <line stroke="#000" x2={legendBarWidth + 10} />
                  <text y={"0.25em"} x={legendBarWidth + 15}>
                    {label}
                  </text>
                </g>
              );
            })}

            {/* <DebugLayout layout={layout} /> */}
          </svg>

          <div className="yearLabels">
            {yearsData.map((year) => {
              return (
                <div
                  key={`yearLabels-${year}`}
                  style={{
                    position: "absolute",
                    left: xScale(year),
                    width: xScale.bandwidth(),
                    top: layout.years.top,
                    height: layout.years.height,
                  }}
                >
                  <div>{formatYear(year, collapse)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function BarcodeTooltip({
  xScale,
  yScale,
  colorScale,
  point,
  metric,
  onClose,
  showLabel,
  type,
  opacity = 0.6,
}) {
  const metricKey = metric.key;
  const isWorldAverage = type === "average";
  const countryNameLabel = getCountruNameFromISO(point.ISO) ?? point.ISO;
  const nameLabel = isWorldAverage
    ? metricKey === METRIC.LAND
      ? "GLOBAL TOTAL"
      : "GLOBAL AVG."
    : countryNameLabel;
  const tooltipWidth = xScale.bandwidth();
  const left = isWorldAverage
    ? xScale(point.year) + xScale.bandwidth()
    : xScale(point.year);

  const color = isWorldAverage ? "black" : colorScale(+point["0.5"]);
  const textColor = hsl(color).l < 0.5 ? "#fff" : "#000";
  const top = yScale(+point["0.5"]);

  const isFromDropdown = type === "dropdown";
  const translate = isWorldAverage
    ? "translate(-100%,-50%)"
    : "translateY(-100%)";

  return (
    <div
      className="barcode-tooltip"
      style={{
        left,
        top,
        borderColor: color,
        maxWidth: tooltipWidth,
        transform: translate,
        opacity,
        flexDirection: isWorldAverage ? "row-reverse" : "row",
      }}
    >
      <div
        className="barcode-tooltip__value"
        style={{ backgroundColor: color, color: textColor }}
      >
        {metric.metricFormatter(+point["0.5"])}
      </div>

      {showLabel && (
        <>
          <div className="barcode-tooltip__country">{nameLabel}</div>
          {isFromDropdown && (
            <div className="barcode-tooltip__closeButton">
              <button onClick={onClose}>&times;</button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
