import { useEffect, useState } from "react";

export function useElementDimension(ref) {
  const [dimension, setDimension] = useState({ width: 1, height: 1 });

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    const handleResize = () => {
      setDimension({
        width: el.offsetWidth,
        height: el.offsetHeight,
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return dimension;
}
