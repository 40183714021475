import "./CountryDetailMap.scss";
import useIRMap, { useCountryOutlines } from "../../useIRMap";
import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { geoMercator, geoPath, geoCentroid } from "d3-geo";
import useIRData, { useRegionNames } from "../../lib/useIRData";
import {
  years,
  COUNTRIES_TO_SHOW_ICONS_FOR,
  LANDLOCK_FILL,
  MARKER_SIZE,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import cityMarker from "../../images/cityMarker.svg";
import infoIcon from "../../images/info_icon.svg";
import centroid from "@turf/centroid";
import { useCountryData } from "../../lib/useCountryData";
import {
  getPathwayFromScenario,
  getScenarioConfig,
} from "../../lib/metric-utils";
import { PATHWAY } from "../../lib/metric-constants";
import { MapLegend } from "./MapLegend";
import { CountryTooltipWithMetric } from "./CountryTooltipWithMetric";
const missingIds = {};

const countriesToShowIconsFor = COUNTRIES_TO_SHOW_ICONS_FOR;

const PADDING_HORIZ = 10;
const PADDING_TOP = 90;
const PADDING_BOTTOM = 130;

export default function CountryDetailMap({
  metric,
  scenario,
  country,
  width,
  height,
  colorScale,
  yearIndex,
  title,
  extent,
  metricFormatter,
  displayBorders,
  countryValue,
  disclaimer,
  children,
}) {
  const mapFeatures = useIRMap(country.code3);

  const countryFeatures = useCountryOutlines(country.code3);

  const navigation = useNavigate();
  const regionNames = useRegionNames();

  const metricArray = useMemo(() => [metric], [metric]);
  const countryArray = useMemo(() => [country.code3], [country.code3]);
  const data = useIRData({
    scenario,
    metric,
    metricsToLoad: metricArray,
    countriesToLoad: countryArray,
  });
  const countryData = useCountryData({
    metric,
    scenario,
    year: years[yearIndex],
  });

  if (data && data.datasets.length === 4) {
    yearIndex++;
  }
  const topCityFeatures = useMemo(() => {
    const showIconsForAllFeatures = countriesToShowIconsFor.includes(
      country.code3
    );
    if (!mapFeatures) return null;
    if (showIconsForAllFeatures) {
      return mapFeatures;
    }
    return mapFeatures.filter((f) => f.properties.topCity);
  }, [mapFeatures, country.code3]);

  const [hoveredId, setHoveredId] = useState(null);

  const projection = useMemo(() => {
    const projection = geoMercator();
    if (!mapFeatures || mapFeatures.length === 0) {
      return null;
    }

    let filteredFeatures = mapFeatures;
    if (country.code3 === "USA") {
      filteredFeatures = filteredFeatures.filter((feature) => {
        const id = feature.properties.hierid;
        const parts = id.split(".");
        return parts[1] !== "2" && parts[1] !== "12";
      });
    } else if (country.code3 === "RUS") {
      const idsToRemove = [
        "RUS.14.401.401",
        "RUS.14.399.399",
        "RUS.14.Rdd2e9902f80893b8",
      ];
      filteredFeatures = filteredFeatures.filter((feature) => {
        const id = feature.properties.hierid;
        return !idsToRemove.includes(id);
      });
    }

    const collection = {
      type: "FeatureCollection",
      features: filteredFeatures,
    };
    let center = geoCentroid(collection);

    projection.center(center);
    const countriesToRotate = ["NZL", "FJI", "KIR", "UMI"];
    if (countriesToRotate.includes(country.code3)) {
      projection.rotate([center[0], projection.rotate()[1]]);
    }

    projection.fitExtent(
      [
        [PADDING_HORIZ, PADDING_TOP],
        [width - PADDING_HORIZ, height - PADDING_BOTTOM],
      ],
      collection
    );
    const maxScale = 5000;
    projection.scale(Math.min(maxScale, projection.scale()));
    return projection;
  }, [mapFeatures, country.code3, width, height]);

  const [featurePaths, countryFeaturePaths] = useMemo(() => {
    if (!mapFeatures || !countryFeatures || !projection) {
      return [null, null];
    }
    const featuresToPlot = [mapFeatures, countryFeatures];

    const pathGen = geoPath().projection(projection);
    return featuresToPlot.map((features) =>
      features
        ? features.map((feature) => {
            const path = pathGen(feature);
            return path;
          })
        : null
    );
  }, [mapFeatures, countryFeatures, projection]);

  const percentile = "0.5";
  const svgRef = useRef();
  const hover = useCallback(
    (id, type, value, feature, featureYearData, featureIndex) => (event) => {
      if (!id) {
        setHoveredId(null);
        return;
      }
      if (value == null) {
        return;
      }
      if (type === "regions" && !feature.properties.topCity) {
        if (metric === "highrisk labor" || metric === "lowrisk labor") {
          return;
        }
      }
      const svgPosition = svgRef.current.getBoundingClientRect();
      const mousePosition = {
        x: event.clientX - svgPosition.left,
        y: event.clientY - svgPosition.top,
      };
      const eventPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      setHoveredId({
        id,
        mousePosition,
        eventPosition,
        type,
        value,
        feature,
        featureYearData,
        featureIndex,
      });
    },
    [svgRef, setHoveredId, metric]
  );

  const [paths, countryPaths, countryOutlines] = useMemo(() => {
    const clickCountry = (id) => (event) => {
      setHoveredId(null);
      navigation(`/country/${id}/${metric}/${scenario}`);
    };
    if (
      !mapFeatures ||
      !countryFeatures ||
      !data ||
      !data.datasets ||
      !countryData
    )
      return [null, null];
    let { datasets } = data;
    const selectedCountryFeatureIndex = countryFeatures.findIndex(
      (f) => f.properties.code3 === country.code3
    );
    const featuresToPlot = [
      { features: mapFeatures, paths: featurePaths, type: "regions" },
      {
        features: countryFeatures,
        paths: countryFeaturePaths,
        type: "countries",
      },
    ];
    if (
      selectedCountryFeatureIndex !== -1 &&
      countryFeatures &&
      countryFeaturePaths
    ) {
      featuresToPlot.push({
        features: [countryFeatures[selectedCountryFeatureIndex]],
        paths: [countryFeaturePaths[selectedCountryFeatureIndex]],
        type: "countryOutlines",
      });
    }

    return featuresToPlot.map(({ features, paths, type }) => {
      if (!paths) {
        return null;
      }
      return features.map((feature, featureIndex) => {
        const path = paths[featureIndex];
        let fill = "transparent";
        let stroke = null;
        let id = null;
        let value = null;
        let cursor = null;
        let onClick = null;
        let featureYearData = null;
        fill = "transparent";
        id = feature.properties.code3;

        if (type === "regions") {
          id = feature.properties.hierid;
          if (datasets && datasets[yearIndex] && datasets[yearIndex][id]) {
            value = +datasets[yearIndex][id][percentile];
            fill = colorScale(value);
            featureYearData = datasets.map((d) => d[id]);
          }
        } else if (type === "countryOutlines") {
          fill = "none";
          if (displayBorders) {
            stroke = "#fff";
          }
          if (id === country.code3) {
            value = null;
            onClick = null;
            cursor = null;
          }
        } else if (type === "countries") {
          if (countryData) {
            const datum = countryData.find((d) => d.ISO === id);

            if (datum) {
              value = +datum[percentile];
              fill = colorScale(value);

              cursor = "pointer";
              onClick = clickCountry(id);
              if (id === country.code3 || datum["0.5"] === "") {
                fill = LANDLOCK_FILL;
              }
            } else {
              fill = LANDLOCK_FILL;
            }
          }
          // TODO: this is temp, remove whan all regions data will be shown
          if (
            getPathwayFromScenario(scenario) === PATHWAY.SSP &&
            id !== country.code3
          ) {
            fill = "#e3e6e7";
          }
        }
        if (!id) {
          if (!missingIds[feature.properties.name]) {
            console.log("id is null", feature.properties.name);
            missingIds[feature.properties.name] = true;
          }
          return null;
        }
        return (
          <path
            stroke={stroke}
            style={{ cursor }}
            onMouseOver={hover(
              id,
              type,
              value,
              feature,
              featureYearData,
              featureIndex
            )}
            onMouseMove={hover(
              id,
              type,
              value,
              feature,
              featureYearData,
              featureIndex
            )}
            onMouseOut={hover(null)}
            onClick={onClick}
            data-id={id}
            d={path}
            key={id}
            fill={fill}
          />
        );
      });
    });
  }, [
    colorScale,
    mapFeatures,
    featurePaths,
    data,
    yearIndex,
    countryData,
    countryFeatures,
    countryFeaturePaths,
    country.code3,
    navigation,
    hover,
    displayBorders,
    metric,
    scenario,
  ]);

  const topCityIcons = topCityFeatures.map((feature, featureIndex) => {
    if (!projection) {
      return null;
    }
    const topCity = feature.properties.topCity;
    let x,
      y = null;
    if (topCity) {
      const xy = projection([+topCity.lng, +topCity.lat]);
      x = xy[0];
      y = xy[1];
    } else {
      if (!feature.geometry) {
        return null;
      }
      const latLong = centroid(feature);
      const xy = projection(latLong.geometry.coordinates);
      x = xy[0];
      y = xy[1];
    }
    const id = feature.properties.hierid;
    let featureYearData = null;
    let value = null;
    if (
      data &&
      data.datasets &&
      data.datasets[yearIndex] &&
      data.datasets[yearIndex][id]
    ) {
      value = +data.datasets[yearIndex][id][percentile];
      featureYearData = data.datasets.map((d) => d[id]);
    }
    return (
      <g
        transform={`translate(${x}, ${y})`}
        key={id}
        onMouseOver={hover(
          id,
          "regions",
          value,
          feature,
          featureYearData,
          featureIndex
        )}
        onMouseMove={hover(
          id,
          "regions",
          value,
          feature,
          featureYearData,
          featureIndex
        )}
        onMouseOut={hover(null)}
      >
        <image
          x={-MARKER_SIZE[0] / 2}
          y={-MARKER_SIZE[1]}
          xlinkHref={cityMarker}
          width={MARKER_SIZE[0]}
          height={MARKER_SIZE[1]}
        />
      </g>
    );
  });

  const hasTouch = "ontouchstart" in window;
  const isMobile = hasTouch && window.innerWidth < 800;

  let disclaimerJSX = null;
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  useEffect(() => {
    if (disclaimer) {
      setDisclaimerOpen(true);
    } else {
      setDisclaimerOpen(false);
    }
  }, [disclaimer]);
  if (disclaimer) {
    disclaimerJSX = (
      <div className="disclaimer">
        <img
          alt="click to toggle country disclaimer"
          src={infoIcon}
          onClick={() => setDisclaimerOpen(!disclaimerOpen)}
        />
        {disclaimerOpen ? (
          <p>
            {disclaimer}
            <div className="close" onClick={() => setDisclaimerOpen(false)}>
              &times;
            </div>
          </p>
        ) : null}
      </div>
    );
  }

  return (
    <div className="CountryDetailMap">
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        ref={svgRef}
        className="map"
        style={{ overflow: "hidden" }}
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
          <g>{countryPaths}</g>
          <g>{paths}</g>
          <g>{countryOutlines}</g>
        </g>
        <g>{topCityIcons}</g>
      </svg>
      {disclaimerJSX}
      {hoveredId && (
        <CountryTooltipWithMetric
          hoveredId={hoveredId}
          height={height}
          metricFormatter={metricFormatter}
          topCityFeatures={topCityFeatures}
          metric={metric}
          colorScale={colorScale}
          extent={extent}
          yearIndex={yearIndex}
          scenario={scenario}
          isMobile={isMobile}
          country={country}
          regionNames={regionNames}
        />
      )}

      <div className="CountryDetailMap__legend">
        <MapLegend
          width={width}
          extent={extent}
          scenario={scenario}
          countryValue={countryValue}
          hoveredId={hoveredId}
          title={title}
          metric={metric}
          colorScale={colorScale}
          detailText={`Emission scenario: ${getScenarioConfig(scenario).label}`}
          selectedCountry={country.name}
          yearIndex={yearIndex}
        />
      </div>

      {children}
    </div>
  );
}
