import { Vector3 } from "three";
import { polar2Cartesian } from "../../GlobeRenderer";
import { CountryTooltip } from "./CountryTooltip";
import { useNavigate } from "react-router-dom";

export function CountryTooltipControl({
  isMobile,
  tooltipViaSelect,
  country,
  scenario,
  cameraRef,
  width,
  height,
  setExploreModeCountry,
  metric,
  tooltipValue,
}) {
  const navigate = useNavigate();

  const lat = +country.lat;
  const lng = +country.lng;
  const labelWorldPosition = polar2Cartesian({ lat, lng, alt: 0 });
  const vec = new Vector3(
    labelWorldPosition.x,
    labelWorldPosition.y,
    labelWorldPosition.z
  );
  cameraRef.current.updateMatrixWorld();
  const xy = vec.project(cameraRef.current);

  const widthHalf = width / 2;
  const heightHalf = height / 2;
  xy.x = xy.x * widthHalf + widthHalf;
  xy.y = -(xy.y * heightHalf) + heightHalf;
  const navigateToCountry = () => {
    navigate(`/country/${country.code3}`);
  };
  const navigateToRisk = () => {
    navigate(`/risk/${scenario}/${country.code3}`);
  };
  const value = metric.metricFormatter(tooltipValue);
  return (
    <CountryTooltip
      isMobile={isMobile}
      tooltipViaSelect={tooltipViaSelect}
      xy={xy}
      country={country}
      setExploreModeCountry={setExploreModeCountry}
      navigateToCountry={navigateToCountry}
      navigateToRisk={navigateToRisk}
      value={value}
    />
  );
}
