import { DoubleSide } from "three";

import { useSpring, animated } from "@react-spring/three";

import { ConicPolygonBufferGeometry } from "three-conic-polygon-geometry";
import { useMemo } from "react";
import { extend } from "@react-three/fiber";
extend({ ConicPolygonBufferGeometry });

function Polygon({ polygon, GLOBE_RADIUS }) {
  const {
    sideColor,
    capColor,
    altitude,
    onPointerEnter,
    onClick,
    onPointerLeave,
  } = polygon;

  const capCurvatureResolution = 1;
  const alt = altitude || 0;
  const scale = 1 + alt + 1e-4;
  const spring = useSpring({ scale, capColor });

  return (
    <animated.mesh
      onClick={onClick}
      key={polygon.id}
      scale={spring.scale}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      <meshBasicMaterial
        attach="material"
        color={sideColor}
        opacity={0}
        transparent
        side={DoubleSide}
      />
      <conicPolygonBufferGeometry
        attach="geometry"
        args={[
          polygon.coords,
          0,
          GLOBE_RADIUS,
          false,
          true,
          false,
          capCurvatureResolution,
        ]}
      />
    </animated.mesh>
  );
}
export default function PolygonMesh({
  features,
  GLOBE_RADIUS,
  exploreMode,
  exploreOnClick,
  exploreOnHover,
}) {
  const singlePolygons = useMemo(() => {
    const singlePolygons = [];
    features.forEach((polygon) => {
      const objAttrs = {
        data: polygon,
        capColor: "#ffffff",
        sideColor: "#ffffff",
        altitude: 0.001,
        onPointerEnter: exploreMode
          ? (e) => {
              e.stopPropagation();
              exploreOnHover(polygon);
            }
          : null,
        onPointerLeave: exploreMode
          ? (e) => {
              e.stopPropagation();
              exploreOnHover(null);
            }
          : null,
        onClick: exploreMode
          ? (e) => {
              e.stopPropagation();
              exploreOnClick(polygon);
            }
          : null,
      };
      const geoJson = polygon.geometry;
      const geoId = polygon.__id || `${Math.round(Math.random() * 1e9)}`; // generate and stamp polygon ids to keep track in digest
      polygon.__id = geoId;

      if (geoJson.type === "Polygon") {
        singlePolygons.push({
          id: `${geoId}_0`,
          coords: geoJson.coordinates,
          ...objAttrs,
        });
      } else if (geoJson.type === "MultiPolygon") {
        singlePolygons.push(
          ...geoJson.coordinates.map((coords, idx) => ({
            id: `${geoId}_${idx}`,
            coords,
            ...objAttrs,
          }))
        );
      } else {
        console.warn(
          `Unsupported GeoJson geometry type: ${geoJson.type}. Skipping geometry...`
        );
      }
    });
    return singlePolygons;
  }, [features, exploreMode, exploreOnClick, exploreOnHover]);

  return singlePolygons.map((polygon) => {
    return (
      <Polygon key={polygon.id} polygon={polygon} GLOBE_RADIUS={GLOBE_RADIUS} />
    );
  });
}
