import { SCENARIO_CONFIGS } from "../../lib/metric-constants";
import "./MetricCardLegend.scss";

export function MetricCardLegend({ color, scenario, otherScenarios }) {
  const { pathway: selectedPathway, legendLabel: selectedLabel } =
    SCENARIO_CONFIGS.find((d) => d.value === scenario);

  return (
    <div className="sparkline-legend">
      <div className="sparkline-legend__label">
        {selectedPathway.toUpperCase()} EMISSION SCENARIO
      </div>
      <div className="sparkline-legend__items">
        <div className="sparkline-legend__single-item">
          <svg width="20" height="10">
            <line x1="0" y1="5" x2="20" y2="5" stroke={color} strokeWidth="5" />
          </svg>
          {selectedLabel}
        </div>
        {otherScenarios.map((scenario, i) => (
          <div className="sparkline-legend__single-item" key={scenario}>
            <svg width="20" height="10">
              <line
                x1="0"
                y1="5"
                x2="20"
                y2="5"
                stroke={color}
                strokeWidth="2"
                strokeDasharray={i === 0 ? "4 4" : "1 1"}
              />
            </svg>

            {SCENARIO_CONFIGS.find((d) => d.value === scenario).legendLabel}
          </div>
        ))}
      </div>
    </div>
  );
}
