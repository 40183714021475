import { NavLink, useMatch } from "react-router-dom";
import "./Header.scss";
import logo from "./images/logo.svg";
import hamburgerIcon from "./images/hamburger.svg";
import { Fragment, useState } from "react";
import classNames from "classnames";
export default function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { country } = props;
  const [showChildMenu, setShowChildMenu] = useState(true);
  const links = [
    {
      label: "What is HCH",
      href: "/what-is-hch",
    },
    {
      label: "Explore Climate Horizons",
      href: "/",
      onClick: props.resetIntro,
      children: [
        {
          label: "Global Overview",
          href: "/",
          onClick: props.resetIntro,
          match: useMatch("/"),
        },
        {
          label: "Country insights",
          href: `/country/${country}`,
          match: [
            useMatch("/country/:countryCode"),
            useMatch("/country/:countryCode/:metricId/:rcp"),
          ].some((d) => !!d),
        },
        {
          label: "Compare Impacts",
          href: "/risk",
        },
      ],
    },
    {
      label: "News and Stories",
      href: "/news",
    },
    // {
    //   label: 'Methodology',
    //   href: '/methodology',
    // },
    // {
    //   label: 'About',
    //   href: '/about',
    // },
  ];

  return (
    <>
      <div className="b--header-a">
        <div className="b--brand-a">
          <a
            aria-label="UNDP homepage"
            target="_blank"
            rel="noopener noreferrer"
            className="b--brand-a__item-left"
            href="https://www.undp.org/content/undp/en/home.html"
          >
            <img
              alt="header logo"
              className="b--brand-a__item-left__media"
              src={logo}
            />
          </a>
          <div className="b--brand-a__item-right">
            <p className="b--brand-a__item-right__subtitle">Global</p>
            <p className="b--brand-a__item-right__title">
              HUMAN <span style={{ color: "#006EB5" }}>CLIMATE</span> HORIZONS
            </p>
          </div>
        </div>

        <nav>
          {links.map(({ label, href, match, onClick, children }) => {
            // console.log(match)
            const className = classNames(
              !!match ? "active" : null,
              "animatedLink animatedOnHover"
            );
            // don't allow resetting intro when already in intro
            const isIntroAndInIntro = href === "/" && !!match;
            const click = isIntroAndInIntro ? null : onClick;
            const childrenLinks =
              children && showChildMenu ? (
                <div className="childrenLinksContainer">
                  <div className="childrenLinks">
                    {children.map(({ label, href, match, onClick }) => {
                      let className = !!match ? "active" : null;
                      const childOnClick = () => {
                        setShowChildMenu(false);
                        if (onClick) {
                          onClick();
                        }
                      };
                      return (
                        <NavLink
                          key={`child-${href}`}
                          to={href}
                          className={className}
                          onClick={childOnClick}
                        >
                          {label}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              ) : null;
            return (
              <span key={label}>
                <span style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <NavLink
                    key={label}
                    to={href}
                    className={className}
                    onClick={click}
                    onMouseOver={(e) => setShowChildMenu(true)}
                  >
                    {label}
                  </NavLink>
                  {children && (
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4438 1.1665L7.44385 7.83317L1.44385 1.1665"
                        stroke="black"
                        strokeWidth="2"
                      />
                    </svg>
                  )}
                </span>
                {childrenLinks}
              </span>
            );
          })}
        </nav>
        <img
          className="hamburger"
          src={hamburgerIcon}
          alt="hamburger"
          onClick={toggle}
        />
      </div>
      <div className={classNames("mobileMenu", { open: isOpen })}>
        <div className="mobileMenu__close" onClick={toggle}>
          &times;
        </div>
        {links.map(({ label, href, match, onClick, children }) => {
          // console.log(match)
          let className = !!match ? "active" : null;
          let link = null;
          if (href && !children) {
            link = (
              <NavLink
                key={label}
                to={href}
                className={className}
                onClick={onClick}
              >
                {label}
              </NavLink>
            );
          } else {
            link = label;
          }
          const childrenLinks = children
            ? children.map(({ label, href, match, onClick }) => {
                let className = !!match ? "active" : null;

                return (
                  <NavLink
                    key={`child-${href}`}
                    to={href}
                    className={className}
                    onClick={onClick}
                  >
                    {label}
                  </NavLink>
                );
              })
            : null;
          return (
            <Fragment key={label}>
              <span key={label} onClick={toggle}>
                {link}
              </span>
              {childrenLinks}
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
