import {
  METRICS_CONFIGS,
  METRICS_GROUP,
  METRIC_TYPE,
} from "../../lib/metric-constants";
import { ScenarioDropdown } from "../UI/ScenarioDropdown";
import { MetricCard } from "./MetricCard";
import "./LeftPanelMetrics.scss";
import { groupBy, values } from "lodash";
import TemperatureUnitToggle from "../comparePage/TemperatureUnitToggle";

export const LeftPanelMetrics = ({
  scenario,
  metricId,
  country,
  yearIndex,
  showOverlay,
  setShowOverlay,
  setNavigation,
  onTemperatureChange,
}) => {
  const groupedMetricsByGroup = groupBy(
    METRICS_CONFIGS,
    (metric) => metric.group
  );

  const allGroups = values(METRICS_GROUP);
  const allTypes = values(METRIC_TYPE);

  return (
    <aside className="left-panel-metrics">
      <div className="sticky-dropdown">
        <div className="left-panel-metrics__spacer" />
        <div
          className="scenario-dropdown-wrapper "
          style={{ backgroundColor: showOverlay ? "#4F95DD" : "#232e3d" }}
        >
          <ScenarioDropdown
            scenario={scenario}
            setShowOverlay={setShowOverlay}
            metricId={metricId}
            setNavigation={setNavigation}
            isDark
          />
        </div>
      </div>
      <div className="metrics-wrapper">
        {allGroups.map((group) => {
          const metricsByGroups = groupedMetricsByGroup[group];
          const groupByTypes = groupBy(
            metricsByGroups,
            (metric) => metric.type
          );
          return (
            <div key={group} className="metrics-group">
              <div className="group-label">
                <span>{group}</span>
                {group === METRICS_GROUP.TEMPERATURE && (
                  <TemperatureUnitToggle onChange={onTemperatureChange} />
                )}
              </div>
              <div className="group-wrapper">
                {allTypes.map((type) => {
                  const metricsByType = groupByTypes[type];
                  return (
                    <div key={type} className="metrics-type">
                      <div className="type-label">{type} Data</div>
                      <div className="type-wrapper">
                        {metricsByType.map((metric) => {
                          const selected = metric.key === metricId;
                          return (
                            <MetricCard
                              key={metric.key}
                              metric={metric}
                              scenario={scenario}
                              country={country}
                              yearIndex={yearIndex}
                              selected={selected}
                              setNavigation={setNavigation}
                              setShowOverlay={setShowOverlay}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};
