import earthNightFile from "./images/map.jpg";
// import earthTopologyFile from './images/earth-topology.png'
import glsl from "babel-plugin-glsl/macro";
import { useLoader, extend, useThree } from "@react-three/fiber";
import { TextureLoader, Color, BackSide } from "three";
import { useRef, useEffect, useState } from "react";
import { Tween, Easing } from "@tweenjs/tween.js";
import { shaderMaterial } from "@react-three/drei";
import { GLOBE_RADIUS } from "./GlobeRenderer";
import { useControls } from "leva";
import { METRICS_CONFIGS, METRICS_GROUP } from "./lib/metric-constants";
import { partition } from "lodash";
// import GlobeMaterial from './GlobeMaterial';
const textureFolder =
  process.env.NODE_ENV === "production" && window.innerWidth > 700
    ? "maptextures"
    : "maptextures_dev";

const maskFolder =
  process.env.NODE_ENV === "production" && window.innerWidth > 700
    ? "masks"
    : "masks_dev";

const TextureTransitionMaterial = shaderMaterial(
  {
    t1: { value: null },
    t2: { value: null },
    transition: { value: 0 },
    maskTransition: { value: 0 },
    maskTexture: { value: null },
    baseTexture: { value: null },
  },

  // vertex shader
  glsl`
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // fragment shader
  glsl`
    uniform sampler2D t1;
    uniform sampler2D t2;
    uniform sampler2D baseTexture;
    uniform sampler2D maskTexture;
    uniform float transition;
    uniform float maskTransition;
    varying vec2 vUv;
    void main(){
      vec4 tex1 = texture2D(t1, vUv);
      vec4 tex2 = texture2D(t2, vUv);

      vec4 tweenedColor = mix(tex1, tex2, transition);
      vec4 baseColor = texture2D(baseTexture, vUv);

      // if (tex2.a > 1.5 || tex2.a <= 1.5) {
      //   gl_FragColor = texture2D(baseTexture, vUv);
      // } else {
      //   gl_FragColor = tweenedColor;
      // }
      // gl_FragColor = tweenedColor U;
      gl_FragColor = baseColor * (1.0 - tweenedColor.a) + tweenedColor * tweenedColor.a;

      float maxOpacityChange = 0.4;
      vec4 maskColor = texture2D(maskTexture, vUv);

      if (maskColor.r < 0.1 && maskTransition > 0.0) {
        float tweenedOpacity = mix(1.0, maxOpacityChange, maskTransition);
        gl_FragColor.r = gl_FragColor.r * tweenedOpacity;
        gl_FragColor.g = gl_FragColor.g * tweenedOpacity;
        gl_FragColor.b = gl_FragColor.b * tweenedOpacity;
      }

      // gl_FragColor.a = 0.2;


      gl_FragColor = linearToOutputTexel(gl_FragColor);

    }


  `
);

const GlowMaterial = shaderMaterial(
  {
    color: { value: new Color(0xffffff) },
    coefficient: { value: 0.5 },
    power: { value: 1.0 },
  },
  glsl`
  varying vec3 vVertexWorldPosition;
  varying vec3 vVertexNormal;
  void main() {
    vVertexNormal	= normalize(normalMatrix * normal);
    vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
    gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  glsl`
  uniform vec3 color;
  uniform float coefficient;
  uniform float power;
  varying vec3 vVertexNormal;
  varying vec3 vVertexWorldPosition;
  void main() {
    vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;
    vec3 viewCameraToVertex	= (viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz;
    viewCameraToVertex = normalize(viewCameraToVertex);
    float intensity	= pow(
      coefficient + dot(vVertexNormal, viewCameraToVertex),
      power
    );
    gl_FragColor = vec4(color, intensity);

  }`
);
extend({ TextureTransitionMaterial });
extend({ GlowMaterial });
// extend({ GlobeMaterial })

function getTexturePath(texture) {
  return `${process.env.PUBLIC_URL}/data/${textureFolder}/${texture}`;
}

function getMaskPath(mask) {
  return `${process.env.PUBLIC_URL}/data/${maskFolder}/${mask}`;
}

const metrics = METRICS_CONFIGS;
const [slrMetrics, temperatureMetrics] = partition(
  metrics,
  (metric) => metric.group === METRICS_GROUP.SEA_LEVEL_RISING
);

export default function Globe({
  alternateTexture,
  texturesLoaded,
  maskTextures,
  maskTexture,
  exploreMode,
  exploreModeMetric,
  onHover,
  loadAllTextures,
  setAllTexturesInitialized,
  setTextureProgress,
}) {
  const selectedTextureIndex = metrics.findIndex((d) =>
    alternateTexture ? alternateTexture.includes(d.key) : false
  );

  const earthNight = useLoader(TextureLoader, earthNightFile);

  const [, setExploreModeTextureRerender] = useState(0);
  useEffect(() => {
    setExploreModeTextureRerender((d) => d + 1);
  }, [exploreModeMetric]);
  useEffect(() => {
    setExploreModeTextureRerender((d) => d + 1);
  }, [maskTexture]);
  const glRenderer = useThree(({ gl }) => gl);

  const earthNightInitalized = useRef(false);

  useEffect(() => {
    if (glRenderer && earthNight) {
      earthNightInitalized.current = true;
      console.time("textures");

      glRenderer.initTexture(earthNight);

      console.timeEnd("textures");
      console.log("textures initialized");
      texturesLoaded();
    }
  }, [glRenderer, texturesLoaded, earthNight]);

  const [textures, setTextures] = useState([]);
  const [maskTexturesArray, setMaskTextures] = useState([]);
  const [texturesRequested, setTexturesRequested] = useState(false);

  useEffect(() => {
    if (loadAllTextures && !texturesRequested) {
      const textures = [
        ...slrMetrics.map((d) =>
          getTexturePath(`${d.key}-2040-2059-SSP2-45.png`)
        ),
        ...temperatureMetrics.map((d) =>
          getTexturePath(`${d.key}-2040-2059-rcp45.png`)
        ),
        ...maskTextures.map(getMaskPath),
      ];
      const loader = new TextureLoader();
      const loadedTextures = textures.map((d) => null);
      setTexturesRequested(true);
      setTextureProgress({ loaded: 0, total: textures.length });
      let loaded = 0;
      textures.forEach((textureFile, textureIndex) => {
        loader.load(textureFile, (texture) => {
          loadedTextures[textureIndex] = texture;
          glRenderer.initTexture(texture);
          loaded++;
          setTextureProgress({ loaded, total: textures.length });
          if (loadedTextures.filter((d) => d).length === textures.length) {
            const alternateTextures = loadedTextures.slice(
              0,
              loadedTextures.length - maskTextures.length
            );
            const maskTexturesArray = loadedTextures.slice(
              loadedTextures.length - maskTextures.length,
              loadedTextures.length
            );
            setTextures(alternateTextures);
            setMaskTextures(maskTexturesArray);
            setAllTexturesInitialized(true);
          }
        });
      });
    }
  }, [
    loadAllTextures,
    texturesRequested,
    glRenderer,
    maskTextures,
    setAllTexturesInitialized,
    setTextureProgress,
  ]);

  let t1 = useRef(earthNight);
  let t2 = useRef(earthNight);
  let nextT1 = useRef(null);
  let transition = useRef(0);
  const material = useRef();
  let tweenRef = useRef();
  useEffect(() => {
    if (nextT1.current) {
      t1.current = nextT1.current;
    }
    if (exploreMode) {
      t2.current = textures.find((d) =>
        d.image.src.includes(exploreModeMetric.key.replace(/ /g, "%20"))
      );
      if (!t2.current) {
        // debugger
        if (textures.length) {
          t2.current = textures[0];
        }
      }
    } else {
      if (selectedTextureIndex === -1) {
        t2.current = earthNight;
      } else {
        t2.current = textures[selectedTextureIndex];
      }
    }
    // console.log('tween start')
    if (tweenRef.current && tweenRef.current._isPlaying) {
      tweenRef.current.stop();
      t1.current = t2.current;
    }
    tweenRef.current = new Tween({ value: 0 })
      .to({ value: 1 }, 1000)
      .easing(Easing.Quadratic.InOut)
      .onUpdate((t) => {
        if (!material.current) {
          return;
        }
        material.current.uniforms.transition.value = t.value;
        // transition.current = t.value
        // console.log(t.value)
      })
      .onComplete(() => {
        // console.log('oncomplete')
        nextT1.current = t2.current;
      })
      .start();
  }, [
    selectedTextureIndex,
    earthNight,
    textures,
    maskTexturesArray,
    exploreMode,
    exploreModeMetric,
  ]);

  const maskTransition = useRef(0);
  const maskTweenRef = useRef();
  const maskTextureRef = useRef(maskTexturesArray[0]);
  // console.log(maskTexture, maskTexturesArray)
  useEffect(() => {
    let maskTransitionValue = null;
    if (maskTexture) {
      const maskTextureIndex = maskTextures.indexOf(maskTexture);
      // const texture = maskTexturesArray.find(texture => texture.image.src.includes(maskTexture))
      const texture = maskTexturesArray[maskTextureIndex];
      console.log(texture);
      maskTextureRef.current = texture;
      // material.current.uniforms.maskTexture = texture

      maskTransitionValue = 1;
    } else {
      maskTransitionValue = 0;
    }
    if (
      maskTransitionValue !== null &&
      maskTransitionValue !== maskTransition.current
    ) {
      if (maskTweenRef.current) {
        maskTweenRef.current.stop();
      }
      maskTweenRef.current = new Tween({ value: maskTransition.current })
        .to({ value: maskTransitionValue }, 1000)
        .easing(Easing.Quadratic.InOut)
        .onUpdate((t) => {
          if (material.current) {
            material.current.uniforms.maskTransition.value = t.value;
          }
          // console.log(t.value)
          maskTransition.current = t.value;
        })
        .start();
    }
  }, [maskTexture, maskTexturesArray, maskTextures]);

  const { glowSize, glowPower, glowCoeifficient, glowColor } = useControls(
    "glow",
    {
      glowSize: 1.15,
      glowPower: 3.5,
      glowCoeifficient: 0.15,
      glowColor: "#87cefa",
    },
    { collapsed: true }
  );
  // useFrame(() => {
  //   console.log(transition.current, t1.current.image.src, t2.current.image.src, earthNight.image.src)

  // })
  // console.log(GlobeMaterial)
  return (
    <group
      onPointerEnter={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <mesh
        rotation={[0, -Math.PI / 2, 0]}
        onPointerOver={() => onHover(true)}
        onPointerOut={() => onHover(false)}
      >
        <sphereBufferGeometry attach="geometry" args={[GLOBE_RADIUS, 64, 64]} />
        <textureTransitionMaterial
          transparent
          ref={material}
          transition={transition.current}
          maskTransition={maskTransition.current}
          attach="material"
          baseTexture={earthNight}
          t1={t1.current}
          t2={t2.current}
          maskTexture={maskTextureRef.current}
        />
      </mesh>
      <mesh>
        <sphereBufferGeometry
          attach="geometry"
          args={[GLOBE_RADIUS * glowSize, 32, 32]}
        />
        <glowMaterial
          side={BackSide}
          color={new Color(glowColor)}
          power={glowPower}
          coefficient={glowCoeifficient}
          transparent={true}
          depthWrite={false}
        />
      </mesh>
    </group>
  );
}
