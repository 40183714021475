import App from "./App";
// adding this dummy component fixes live realoading in dev
// i dunno!
import { HashRouter as Router, Route, Routes } from "react-router-dom";

export default function App2() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="country/:countryId/:metricId/:rcp" element={null} />
          <Route path="country/:countryId/:metricId" element={null} />
          <Route path="country/:countryId" element={null} />
          <Route path="country" element={null} />
          <Route path="risk/:rcp/:countryId/" element={null} />
          <Route path="risk/:rcp" element={null} />
          <Route path="risk" element={null} />

          <Route path="about" element={null} />
          <Route path="methodology" element={null} />

          <Route path="news" element={null} />
          <Route path="news/:slug" element={null} />
          <Route path="what-is-hch" element={null} />
        </Route>
      </Routes>
    </Router>
  );
}
