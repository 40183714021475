import DetailDropdown from "./DetailDropdown";
import globeIcon from "../../images/globeIcon.svg";
import { Link } from "react-router-dom";
import "./CountriesDropdown.scss";

export function CountriesDropdown({
  countryValues,
  value,
  onChange,
  onClickGlobe,
}) {
  return (
    <div className="countries-dropdown dropdown-wrapper">
      <div className="ui-label">COUNTRIES & TERRITORIES</div>
      <div className="countries-dropdown__content">
        <div className="back-button">
          <Link to="/" onClick={onClickGlobe}>
            <img src={globeIcon} alt="Global View" />
          </Link>
        </div>
        <DetailDropdown
          values={countryValues}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
