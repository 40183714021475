import { round } from "lodash";

const farenheight2Celcius = (d) => {
  return round(((d - 32) * 5) / 9, 1);
};

export const temperatureFormatterShort = (d) => {
  const unit = window.localStorage.getItem("temperatureUnit") || "f";
  if (unit === "f") {
    return `${round(d, 1)}°F`;
  } else {
    return `${farenheight2Celcius(d)}°C`;
  }
};
export const temperatureGraphLabel = () => {
  const unit = window.localStorage.getItem("temperatureUnit") || "f";
  if (unit === "f") {
    return "°F Change";
  } else {
    return "°C Change";
  }
};
export const daysAbove95Label = () => {
  const unit = window.localStorage.getItem("temperatureUnit") || "f";
  if (unit === "f") {
    return "Days Above 95°F";
  } else {
    return "Days Above 35°C";
  }
};

export const daysBelow32Label = () => {
  const unit = window.localStorage.getItem("temperatureUnit") || "f";
  if (unit === "f") {
    return "Days Below 32°F";
  } else {
    return "Days Below 0°C";
  }
};
