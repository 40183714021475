import countryLookup from "./data/countryLookup";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { years, yearLabels } from "./constants";
import countryDisclaimers from "./data/countryDisclaimers";
import { IntroParagraph } from "./components/common/IntroParagraph";
import { FilterTop } from "./components/countryPage/FilterTop";
import { MapWrapper } from "./components/countryPage/MapWrapper";
import { METRICS_CONFIGS, SCENARIO } from "./lib/metric-constants";
import { LeftPanelMetrics } from "./components/countryPage/LeftPanelMetrics";
import "./CountryDetail.scss";
import { useForceRender } from "./lib/hook/useForceRender";

const titlePage = "Country Insights";
const descriptionPage = (
  <p>
    Human activity is pushing the climate away from its current state,
    influencing human development and all spheres of our lives, including our
    health, infrastructure, and livelihoods. Explore
    <strong> Temperature</strong> and
    <strong> NEW Sea Level Rise</strong> projections and the associated impacts
    on human development under different Emission Scenario and Time Horizon
    options to see how our decisions can shape the future.
    <br />
    <br />
  </p>
);

export default function CountryDetail({
  countryId,
  setGlobalCountrySelection,
  gotoExplore,
}) {
  const forceRender = useForceRender();
  useEffect(() => {
    setGlobalCountrySelection(countryId);
  }, [countryId, setGlobalCountrySelection]);

  const detailParams = useParams();
  const scenarioParam = detailParams.rcp || SCENARIO.SSP245;

  const metricId = detailParams.metricId || METRICS_CONFIGS[0].key;

  const navigation = useNavigate();
  const [yearIndex, setYearIndex] = useState(1);
  const [displayBorders, setDisplayBorders] = useState(
    !!countryDisclaimers[countryId]
  );
  const [showOverlay, setShowOverlay] = useState(false);

  const year = years[yearIndex];
  const country = countryLookup.find((c) => c.code3 === countryId);
  const updateNavigation = (country, metric, scenario) => {
    navigation(`/country/${country}/${metric}/${scenario}`, { replace: true });
  };

  const setNavigation = ({
    country = countryId,
    scenario = scenarioParam,
    metric = metricId,
  }) => {
    updateNavigation(country, metric, scenario);
  };

  const selectedMetricIndex = METRICS_CONFIGS.findIndex((metric) => {
    return metric.key
      ? metric.key === metricId
      : metric.keys.includes(metricId);
  });
  const selectedMetric = METRICS_CONFIGS[selectedMetricIndex];

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  useEffect(() => {
    const hasDisclaimer = !!countryDisclaimers[countryId];
    setDisplayBorders((borders) => {
      if (hasDisclaimer && borders) {
        return false;
      } else if (!hasDisclaimer && !borders) {
        return true;
      }
      return borders;
    });
  }, [countryId]);

  const mapRef = useRef();
  const [mapDimension, setMapDimension] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (!mapRef.current) return;

    const handleResize = () => {
      const width = mapRef.current.offsetWidth;
      const height = Math.max(window.innerHeight - 140 - 19, 370);

      setMapDimension({
        width: Math.min(width, height),
        height: Math.min(width, height),
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classNames("country-detail layout-page")}>
      <IntroParagraph
        title={titlePage}
        description={descriptionPage}
        contentStyle={{ maxWidth: mapDimension.width }}
      />
      {country && (
        <>
          <div className="metrics-and-map">
            <LeftPanelMetrics
              scenario={scenarioParam}
              metricId={metricId}
              country={country}
              yearIndex={yearIndex}
              showOverlay={showOverlay}
              setShowOverlay={setShowOverlay}
              setNavigation={setNavigation}
              onTemperatureChange={forceRender}
            />

            <MapWrapper
              country={country}
              metricIndex={selectedMetricIndex}
              metric={selectedMetric}
              scenario={scenarioParam}
              year={year}
              yearIndex={yearIndex}
              displayBorders={displayBorders}
              setDisplayBorders={setDisplayBorders}
              disclaimer={countryDisclaimers[countryId]}
              showOverlay={showOverlay}
              mapRef={mapRef}
              width={mapDimension.width}
              height={mapDimension.height}
            >
              <FilterTop
                countryLookup={countryLookup}
                countryId={countryId}
                setNavigation={setNavigation}
                yearIndex={yearIndex}
                yearLabels={yearLabels}
                setYearIndex={setYearIndex}
                gotoExplore={gotoExplore}
              />
            </MapWrapper>
          </div>

          {/* <AllCountries
          contentWidth={contentWidth}
          country={country}
          rcp={rcp}
          year={year}
          yearIndex={yearIndex}
        /> */}
        </>
      )}
    </div>
  );
}
