import { scaleSequential } from "d3-scale";
import getColorInterpolator from "../../getColorInterpolator";

import CountryDetailMap from "./CountryDetailMap";
import innerText from "react-innertext";
import "./MapWrapper.scss";
import { useCountryData } from "../../lib/useCountryData";
import {
  getMetricExtent,
  getPathwayFromScenario,
} from "../../lib/metric-utils";
import { MapOverlay } from "./MapOverlay";
import { ShowBordersButton } from "./ShowBordersButton";
import { CTA } from "../common/CTA";

export function MapWrapper(props) {
  let {
    metric,
    scenario,
    country,
    yearIndex,
    children,
    showOverlay,
    displayBorders,
    setDisplayBorders,
    mapRef,
    width,
    height,
  } = props;
  const key = metric.key;
  let label = metric.label;

  if (label instanceof Function) {
    label = label();
  }
  const allYearData = useCountryData({
    metric: key,
    scenario,
    country: country.code3,
  });
  if (allYearData && allYearData.length === 4) {
    yearIndex++;
  }
  const data = allYearData ? allYearData[yearIndex] : null;

  // console.log(allYearData)
  const extent = getMetricExtent(key, scenario);

  const colorScale = scaleSequential()
    .domain(extent)
    .interpolator(getColorInterpolator(key))
    .clamp(true);
  // console.log(data)
  const value = data ? +data["0.5"] : null;
  // console.log(color)

  const title = metric.boldLabel || innerText(label);
  return (
    <section className="map-wrapper">
      <div className="map-wrapper__header" style={{ maxWidth: width }}>
        <ShowBordersButton
          setDisplayBorders={setDisplayBorders}
          displayBorders={displayBorders}
        />
        <CTA
          label="COMPARE COUNTRIES"
          href={`/risk/${scenario}/${country.code3}`}
        />
      </div>
      <div className="map-wrapper__map" ref={mapRef}>
        <CountryDetailMap
          {...props}
          metric={key}
          colorScale={colorScale}
          width={width}
          height={height}
          title={title}
          extent={extent}
          metricFormatter={metric.metricFormatter}
          countryValue={value}
        >
          {children}
        </CountryDetailMap>
        {showOverlay && (
          <MapOverlay
            currentPathway={getPathwayFromScenario(scenario)}
            style={{ maxWidth: width }}
          />
        )}
      </div>
    </section>
  );
}
