import './Footer.scss';
// import facebook from './images/facebook.svg'
// import linkedin from './images/linkedin.svg'
// import twitter from './images/twitter.svg'
// import mail from './images/mail.svg'
// import download from './images/download.svg'
import logo from './images/UNDP_White_Footer_EN.svg';
import cilLogo from './images/CIL-Logo.png'
// const links = [
//   {
//     image: facebook,
//   },
//   {
//     image: linkedin,
//   },
//   {
//     image: twitter,
//   },
//   {
//     image: mail,
//   },
//   {
//     image: download,
//   },
// ]
export default function Footer(props) {
  return (
    <footer className="b--footer-a">
      <div className="f--container">
        <div className="b--footer-a__hd">
          <div className="b--footer-a__hd__item-left">
            <div className="b--brand-b">
              <a href="https://www.undp.org/" className="b--brand-b__link" aria-label="UNDP homepage" target="_blank" rel="noopener noreferrer">
                <img className="b--brand-b__link__media" alt="Footer Logo" src={logo} />
                <span className='fullName'>United Nations<br />Development Programme</span>
              </a>
            </div>
          </div>
          <div className='cilLogo'>
            <a style={{ marginRight: '0.5rem' }} href="https://impactlab.org/" target="_blank" rel="noopener noreferrer">
              <img src={cilLogo} alt="Climate Impact Lab" width='200' />
            </a>
            <ul className="b--footer-a__hd__item-right b--social-a">
              <li className="b--social-a__list-item">
                <a target="_blank" rel="noopener noreferrer" className="b--social-a__list-item__link" href="https://www.facebook.com/UNDP">
                  <div className="b--icon-a">
                    <svg className="b--icon-a__media" viewBox="0 0 20 20">
                      <path fill="none" d="M11.5,20v-9.1h3.1l0.5-3.6h-3.5V5.1c0-1,0.3-1.7,1.8-1.7l1.9,0V0.1c-0.3,0-1.4-0.1-2.7-0.1C9.7,0,7.9,1.7,7.9,4.7v2.6H4.8v3.6h3.1V20H11.5z"></path>
                    </svg>
                  </div>
                </a>
              </li>

              <li className="b--social-a__list-item">
                <a target="_blank" rel="noopener noreferrer" className="b--social-a__list-item__link" href="https://twitter.com/UNDP/">
                  <div className="b--icon-a">
                    <svg className="b--icon-a__media" viewBox="0 0 20 20">
                      <path fill="none" d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"></path>
                    </svg>
                  </div>
                </a>
              </li>

              <li className="b--social-a__list-item">
                <a target="_blank" rel="noopener noreferrer" className="b--social-a__list-item__link" href="https://www.instagram.com/UNDP/">
                  <div className="b--icon-a">
                    <svg className="b--icon-a__media" viewBox="0 0 20 20">
                      <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M14.8,5.2c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C13.8,5.7,14.3,5.2,14.8,5.2z M10.6,7.9c-1.5,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C13.2,9.1,12,7.9,10.6,7.9z
                      M10.6,6.5c2.2,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1S8.3,6.5,10.6,6.5z M11.4,2.5H9.3c-0.7,0-1.1,0-1.5,0l-0.2,0c-0.1,0-0.2,0-0.4,0c-0.9,0-1.4,0.2-1.9,0.4c-0.5,0.2-1,0.5-1.4,0.9C3.4,4.3,3.1,4.7,2.9,5.3C2.7,5.8,2.6,6.4,2.6,7.2c0,0.7-0.1,1-0.1,2.3v1.9c0,0.9,0,1.3,0,1.7l0,0.2c0,0.1,0,0.3,0,0.5c0,0.9,0.2,1.4,0.4,1.9c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.4,0.9c0.5,0.2,1.1,0.3,1.9,0.4c0.2,0,0.3,0,0.5,0l0.2,0c0.4,0,0.8,0,1.7,0h0.7h0.5h0.7c0.9,0,1.3,0,1.7,0l0.2,0c0.1,0,0.3,0,0.5,0c0.9,0,1.4-0.2,1.9-0.4c1.1-0.4,1.9-1.3,2.3-2.3c0.2-0.5,0.3-1.1,0.4-1.9c0-0.2,0-0.3,0-0.5l0-0.2
                      c0-0.4,0-0.8,0-1.7V9.4c0-1.2,0-1.5,0-2.2c0-0.9-0.2-1.4-0.4-1.9c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.5-0.2-1.1-0.3-1.9-0.4C13.1,2.5,12.8,2.5,11.4,2.5z"></path>
                    </svg>
                  </div>
                </a>
              </li>

              <li className="b--social-a__list-item">
                <a target="_blank" rel="noopener noreferrer" className="b--social-a__list-item__link" href="https://www.linkedin.com/company/undp/">
                  <div className="b--icon-a">
                    <svg className="b--icon-a__media" viewBox="0 0 20 20">
                      <path d="M3.1,1.3c-1.3,0-2.2,0.9-2.2,2c0,1.1,0.8,2,2.2,2h0c1.4,0,2.2-0.9,2.2-2C5.3,2.2,4.4,1.3,3.1,1.3z M1.1,7H5v11.7H1.1V7z
                M14.6,6.7c-2.1,0-3.5,2-3.5,2V7H7.2v11.7h3.9v-6.6c0-0.4,0-0.7,0.1-1c0.3-0.7,0.9-1.4,2-1.4c1.4,0,2,1.1,2,2.7v6.3h3.9V12
              C19.1,8.4,17.2,6.7,14.6,6.7z"></path>
                    </svg>
                  </div>
                </a>
              </li>

              <li className="b--social-a__list-item">
                <a target="_blank" rel="noopener noreferrer" className="b--social-a__list-item__link" href="https://www.youtube.com/UNDP">
                  <div className="b--icon-a">
                    <svg className="b--icon-a__media" viewBox="0 0 20 20">
                      <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M8.9,8.1l4.1,2.4l-4.1,2.4C8.9,12.9,8.9,8.1,8.9,8.1z M10.8,4.9h-0.6c-1.1,0-4.9,0.1-6,0.3C3.6,5.4,3,6,2.8,6.6c-0.3,1.1-0.3,3.3-0.3,3.8v0.2c0,0.5,0,2.7,0.3,3.8C3,15,3.6,15.6,4.2,15.8C4.8,15.9,5.8,16,7,16l0.5,0c1.2,0,2.4,0,2.8,0h0.4c0.9,0,5,0,6.1-0.3c0.7-0.2,1.2-0.7,1.4-1.4c0.3-1.1,0.3-3.3,0.3-3.8v-0.1v0v-0.1v0v-0.1c0-0.7,0-2.6-0.3-3.6C18,6,17.5,5.4,16.8,5.2C15.7,4.9,11.9,4.9,10.8,4.9z"></path>
                    </svg>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="b--footer-a__ft">
          <div className="b--footer-a__ft__item-left">
            <p className="b--footer-a__ft__item-left__title">© 2022 United Nations Development Programme</p>
          </div>
          <div className="b--footer-a__ft__item-right">
            <div className="b--footer-a__ft__item-right__link">
              <a className="b--footer-a__ft__item-right__link__item" target="_blank" rel="noopener noreferrer" href="https://www.undp.org/copyright-terms-use">Terms Of Use</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
