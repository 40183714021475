import { scaleLinear } from "d3-scale";
import { extent as d3Extent } from "d3-array";
import slug from "slug";
import { color as d3color } from "d3-color";
import "./MetricDetailSparkline.scss";
import { LineAndPointsFromData } from "./LineAndPointsFromData";
import { MetricCardLegend } from "../countryPage/MetricCardLegend";
import { makeLayout } from "yogurt-layout";
import { useRef } from "react";
import { useElementDimension } from "../../lib/hook/useElementDimension";
import { linechartYearLabels } from "../../constants";
import classNames from "classnames";
import { getMetricConfig } from "../../lib/metric-utils";

export default function MetricDetailSparkline(props) {
  const {
    data,
    colorScale,
    yearIndex,
    metric,
    metricFormatter,
    alternateData = [],
    scenario,
    otherScenarios,
  } = props;

  const ref = useRef(null);
  const { width } = useElementDimension(ref);

  const layout = makeLayout({
    id: "root",
    width: width,
    padding: [0, 8],
    height: 140,
    direction: "column",
    children: [
      { id: "topLabels", height: 24 },
      {
        id: "charts",
        padding: [0, 15],
        children: [{ id: "chart" }],
      },
      { id: "bottomLabels", height: 17 },
    ],
  });

  const years = data.map((el) => el.year);

  const xScale = scaleLinear()
    .domain([0, data.length - 1])
    .range([layout.chart.left, layout.chart.right]);

  const allGroupedData = [data, ...alternateData];

  const allExtentsByData = d3Extent(
    allGroupedData.flatMap((dataset) => dataset.map((d) => +d["0.5"]))
  );

  const yScale = scaleLinear()
    .domain(allExtentsByData)
    .range([layout.chart.bottom, layout.chart.top]);

  const id = `${slug(metric)}-gradient-${Math.round(Math.random() * 100000)}`;
  const stopPoints = 256;

  const singlePointGraph = allExtentsByData[0] === allExtentsByData[1];

  const lineStroke = singlePointGraph
    ? colorScale(allExtentsByData[0])
    : `url(#${id})`;

  const selectedValue = yearIndex
    ? +data.find((datum) => datum.year === years[yearIndex])["0.5"]
    : null;
  const lightnessValue = getMetricConfig(metric).colorLightness;

  return (
    <div className="detail-sparkline">
      <div className="sparkline-wrapper" ref={ref}>
        <div className="axis-name">
          {years.map((year, i) => {
            return (
              <div
                key={`${year}-${Math.random()}`}
                className={classNames("year-label", {
                  "selected-year": i === yearIndex,
                })}
              >
                {linechartYearLabels[i]}
              </div>
            );
          })}
        </div>
        <svg width={layout.root.width} height={layout.root.height}>
          <defs>
            <linearGradient id={id} y1={"0%"} y2={"100%"} x1="0%" x2="0%">
              {[...Array(stopPoints)].map((_, i) => {
                const stop = i / (stopPoints - 1);
                const color = d3color(
                  colorScale(
                    allExtentsByData[1] -
                      (allExtentsByData[1] - allExtentsByData[0]) * stop
                  )
                );
                if (!color) {
                  debugger;
                }

                return (
                  <stop
                    key={`${i}-stop`}
                    offset={`${stop * 100}%`}
                    stopColor={`${color.rgb().toString()}`}
                  />
                );
              })}
            </linearGradient>
          </defs>
          {alternateData.map((alternate, i) => {
            return (
              <LineAndPointsFromData
                key={alternate[0].scenario}
                dataset={alternate}
                xScale={xScale}
                yScale={yScale}
                colorScale={colorScale}
                selectedYearIndex={yearIndex}
                metricFormatter={metricFormatter}
                strokeDasharray={i === 0 ? "4 4" : "1 1"}
                lineStroke={lineStroke}
                lightnessValue={lightnessValue}
              />
            );
          })}
          <LineAndPointsFromData
            dataset={data}
            xScale={xScale}
            yScale={yScale}
            colorScale={colorScale}
            selectedYearIndex={yearIndex}
            metricFormatter={metricFormatter}
            lineStroke={lineStroke}
            layout={layout}
            lightnessValue={lightnessValue}
            selectedScenario
          />
          {/* <DebugLayout layout={layout} opacity={1} /> */}
        </svg>
      </div>
      {scenario && alternateData.length > 0 && (
        <MetricCardLegend
          colorScale={colorScale}
          color={colorScale(selectedValue)}
          scenario={scenario}
          otherScenarios={otherScenarios}
        />
      )}
    </div>
  );
}
