import { scaleSequential } from "d3-scale";
import { useCountryData } from "../../lib/useCountryData";
import getColorInterpolator from "../../getColorInterpolator";
import {
  getMetricExtent,
  getScenariosOfSamePathway,
  isMetricInSelectedScenario,
} from "../../lib/metric-utils";
import classNames from "classnames";

import { METRIC_INFO_ICON_TEXT } from "../../lib/metric-constants";
import infoIcon from "../../images/info_icon.svg";
import { groupBy } from "lodash";
import "./MetricCard.scss";
import MetricDetailSparkline from "../common/MetricDetailSparkline";

export function MetricCard({
  metric,
  scenario,
  country,
  yearIndex,
  selected,
  setNavigation,
  setShowOverlay,
}) {
  const key = metric.key;
  const allScenarios = getScenariosOfSamePathway(scenario);

  const allScenarioData = useCountryData({
    metric: key,
    scenario: allScenarios,
    country: country.code3,
  });

  const groupedDataByScenario = groupBy(allScenarioData, (el) => el.scenario);
  const selectedScenarioData = groupedDataByScenario[scenario];
  const otherScenarios = allScenarios.filter((el) => el !== scenario);
  const alternateData =
    otherScenarios.map((s) => {
      return groupedDataByScenario[s];
    }) || [];

  if (selectedScenarioData && selectedScenarioData.length === 4) {
    yearIndex++;
  }
  const data = selectedScenarioData ? selectedScenarioData[yearIndex] : null;

  const extent = getMetricExtent(metric.key, scenario);

  const colorScale = scaleSequential()
    .domain(extent)
    .interpolator(getColorInterpolator(key))
    .clamp(true);

  const value = data ? +data["0.5"] : null;
  const isMetricWithoutData = value === null;

  const labelWithoutData = isMetricInSelectedScenario(key, scenario)
    ? "Landlocked"
    : "No data";

  const metricDescription = METRIC_INFO_ICON_TEXT[key] || "lorem ipsum...";

  const handleCardClick = () => {
    setNavigation({ metric: key });
    setShowOverlay(false);
  };
  return (
    <div
      onClick={() =>
        isMetricInSelectedScenario(key, scenario)
          ? handleCardClick()
          : setShowOverlay(true)
      }
      key={key}
      className={classNames("metric-card", { selected })}
    >
      <div className="stats" style={{ width: "100%" }}>
        <div className="metric-name-wrapper">
          <div
            className="metric-name"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", gap: "8px" }}>
              <img
                style={{ width: "16px" }}
                src={infoIcon}
                alt={metricDescription}
                data-tip={metricDescription}
              />
              <span>
                {metric.boldLabel instanceof Function
                  ? metric.boldLabel()
                  : metric.boldLabel}
              </span>
            </div>
            <strong style={{ whiteSpace: "nowrap" }}>
              {isMetricWithoutData
                ? labelWithoutData
                : metric.metricFormatter
                ? metric.metricFormatter(value)
                : data}
            </strong>
          </div>
          <div className="sub-label">{metric.label}</div>
        </div>
        {selectedScenarioData && selected && !isMetricWithoutData && (
          <MetricDetailSparkline
            metricFormatter={metric.metricFormatter}
            metric={key}
            colorScale={colorScale}
            data={selectedScenarioData}
            extent={extent}
            yearIndex={yearIndex}
            alternateData={alternateData}
            scenario={scenario}
            otherScenarios={otherScenarios}
          />
        )}
      </div>
    </div>
  );
}
