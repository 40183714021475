import { useState, useEffect, useMemo } from "react";
import { feature } from "topojson-client";
import irPointsCsv from "./data/points.csv";
import { csvParse } from "d3-dsv";
import countryListAllIsoData from "./data/countryLookup";
import cities500k from "./data/Cities500k.csv";
// console.log(cities500k)
const irCache = {};
export default function useIRMap(countryCode) {
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    if (!countryCode) {
      setFeatures([]);
      return;
    }
    setFeatures([]);
    if (!irCache[countryCode]) {
      irCache[countryCode] = window
        .fetch(
          `${process.env.PUBLIC_URL}/data/country-maps/${countryCode}.topo.json`
        )
        .then((r) => r.json())
        .then((topo) => {
          const geojson = feature(topo, topo.objects[countryCode]);
          geojson.features.forEach((feature) => {
            feature.properties.type = "ir";
            if (cities500k[feature.properties.hierid]) {
              feature.properties.topCity =
                cities500k[feature.properties.hierid];
            }
          });

          return Promise.resolve(geojson);
        });
    }
    irCache[countryCode].then((geojson) => {
      setFeatures(geojson.features);
    });
  }, [countryCode]);
  return features;
}

// export function useIRMaps(countryCodes) {
//   const [features, setFeatures] = useState([]);

//   useEffect(() => {
//     if (!countryCodes || countryCodes.length === 0) {
//       setFeatures([]);
//       return
//     }
//     const load = async () => {
//       const features = []
//       await Promise.all(countryCodes.map(async countryCode => {
//         return window.fetch(`${process.env.PUBLIC_URL}/data/country-maps/${countryCode}.topo.json`)
//           .then(r => r.json())
//           .then(topo => {
//             const geojson = feature(topo, topo.objects[countryCode]);
//             geojson.features.forEach(feature => feature.properties.type = 'ir')
//             const nonNull = geojson.features.filter(d => d.geometry)
//             features.push(...nonNull);
//             console.log(geojson)
//           })
//       }))
//       setFeatures(features)
//     }
//     load()

//   }, [countryCodes])
//   return features
// }

export function useCountryFeatures() {
  const [features, setFeatures] = useState(null);
  useEffect(() => {
    window
      .fetch(`${process.env.PUBLIC_URL}/countries-110m.json`)
      .then((d) => d.json())
      .then((topojson) => {
        const countries = feature(topojson, topojson.objects.countries);
        countries.features.forEach((feature) => {
          feature.properties.type = "country";
          const number = feature.id;
          const country = countryListAllIsoData.find(
            (d) => d.number === number
          );
          if (country) {
            feature.properties = { ...feature.properties, ...country };
          }
        });
        setFeatures(countries.features);
        // console.log(countries)
      });
  }, []);
  return features;
}
const outlineCache = {};
export function useCountryOutlines(countryCode) {
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    if (!countryCode) {
      setFeatures([]);
      return;
    }
    setFeatures([]);
    if (!outlineCache[countryCode]) {
      outlineCache[countryCode] = window
        .fetch(
          `${process.env.PUBLIC_URL}/data/country-outlines/${countryCode}.topo.json`
        )
        .then((r) => r.json())
        .then((topo) => {
          const geojson = feature(topo, topo.objects[countryCode]);
          geojson.features.forEach((feature) => {
            const iso = feature.properties.ISO;
            const country = countryListAllIsoData.find((d) => d.code3 === iso);
            if (country) {
              feature.properties = { ...feature.properties, ...country };
            }
          });

          return Promise.resolve(geojson);
        });
    }
    outlineCache[countryCode].then((geojson) => {
      setFeatures(geojson.features);
    });
  }, [countryCode]);
  return features;
}
