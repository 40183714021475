import { INFO_ICON_DISCLAIMERS } from "../../lib/metric-constants";
import infoIcon from "../../images/info_icon.svg";
import {
  getMetricLabel,
  isMetricInSelectedScenario,
} from "../../lib/metric-utils";
import "./MetricHeader.scss";
import { CTA } from "../common/CTA";

export function MetricHeader({ metric, scenario, country }) {
  const infoIconDisclaimer = INFO_ICON_DISCLAIMERS[metric.key];

  const metricIsInSelectedScenario = isMetricInSelectedScenario(
    metric.key,
    scenario
  );

  return (
    <div className="metric-header">
      <div>
        <div className="metric-header__type">{metric.type} Data</div>
        <div className="metric-header__name">
          <img
            src={infoIcon}
            data-tip={infoIconDisclaimer}
            alt={infoIconDisclaimer}
            style={{ width: "16px" }}
          />
          {getMetricLabel(metric)}
        </div>
      </div>
      {country && metricIsInSelectedScenario && (
        <div className="metric-header__CTA">
          <CTA
            label="COUNTRY INSIGHTS"
            href={`/country/${country.code3}/${metric.key}/${scenario}`}
          />
        </div>
      )}
    </div>
  );
}
