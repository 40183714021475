import {
  defaultTemperatureUnit,
  temperatureUnits,
  temperatureUnitStorageKey,
} from "../../constants";
import { useLocalStorage } from "react-use";
import "./TemperatureUnitToggle.scss";
import classNames from "classnames";

export default function TemperatureUnitToggle({ inverted, label, onChange }) {
  const [temperatureUnit, setTemperatureUnit] = useLocalStorage(
    temperatureUnitStorageKey,
    defaultTemperatureUnit,
    { raw: true }
  );
  const onChangeHandle = (value) => {
    setTemperatureUnit(value);
    onChange?.();
  };

  return (
    <div
      className={classNames("temperatureUnitToggle ", {
        inverted,
      })}
    >
      {label && <div className="ui-label">{label}</div>}
      <div className="toggle">
        {temperatureUnits.map((unit) => {
          const className = unit.value === temperatureUnit ? "active" : null;
          return (
            <div
              key={unit.value}
              className={className}
              onClick={() => onChangeHandle(unit.value)}
            >
              {unit.label}
            </div>
          );
        })}
      </div>
    </div>
  );
}
