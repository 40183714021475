import classNames from "classnames";
import { Globe } from "./Globe";
import { IntroNav } from "./IntroNav";
import TemperatureUnitToggle from "../comparePage/TemperatureUnitToggle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIdle, useMouse, useWindowSize } from "react-use";
import { globeTexts } from "../../lib/globe-constants";
import {
  defaultTemperatureUnit,
  temperatureUnitStorageKey,
} from "../../constants";

export function GlobePage({
  setGlobalCountrySelection,
  isMobile,
  countrySelected,
  textIndex,
  setTextIndex,
  loadAdditionalTextures,
  skipIntro,
  advance,
  setTexturesInitialized,
  textOpacity,
  setTextOpacity,
  allTexturesInitalized,
  setAllTexturesInitialized,
  introButtonsVisible,
  showIntroButtons,
}) {
  const [allowMouseClickAdvance, setAllowMouseClickAdvance] = useState(false);

  const [textureProgress, setTextureProgress] = useState(null);
  useEffect(() => {
    setAllowMouseClickAdvance(false);

    if (textIndex <= 1 || textIndex === globeTexts.length - 1) {
      return;
    }
    const showAfterDuration = 1000;
    const timeout = setTimeout(() => {
      setAllowMouseClickAdvance(true);
    }, showAfterDuration);
    return () => clearTimeout(timeout);
  }, [textIndex]);

  const temperatureUnit =
    window.localStorage.getItem(temperatureUnitStorageKey) ||
    defaultTemperatureUnit;

  const texturesLoaded = useCallback(() => {
    setTexturesInitialized(true);
  }, []);

  const gotoNavItem = (newTextIndex) => {
    setTextOpacity(0);
    setTimeout(() => {
      setTextIndex(newTextIndex);
      setTextOpacity(1);
    }, 400);
  };

  const [overIntroNav, setOverIntroNav] = useState(false);

  return (
    <div className="intro">
      <div
        className={classNames("webglcontainer", { countrySelected })}
        onClick={showIntroButtons || !allowMouseClickAdvance ? null : advance}
      >
        <Globe
          selectedText={globeTexts[textIndex]}
          texturesLoaded={texturesLoaded}
          countrySelected={countrySelected}
          loadAdditionalTextures={loadAdditionalTextures}
          setAllTexturesInitialized={setAllTexturesInitialized}
          setTextureProgress={setTextureProgress}
          setGlobalCountrySelection={setGlobalCountrySelection}
          isMobile={isMobile}
          overIntroNav={overIntroNav}
          allowMouseClickAdvance={allowMouseClickAdvance}
          textIndex={textIndex}
        />
      </div>

      {globeTexts[textIndex].text
        ? React.cloneElement(
            globeTexts[textIndex].text instanceof Function
              ? globeTexts[textIndex].text(temperatureUnit)
              : globeTexts[textIndex].text,
            {
              style: { opacity: textOpacity },
              key: textIndex,
              onClick:
                showIntroButtons || !allowMouseClickAdvance ? null : advance,
            }
          )
        : null}
      {showIntroButtons && allTexturesInitalized ? (
        <p className={classNames("introButtons", { introButtonsVisible })}>
          <button onClick={skipIntro}>Skip Intro</button>
          <button onClick={advance}>Continue</button>
        </p>
      ) : null}
      {!allTexturesInitalized && textureProgress ? (
        <div className="loadingStatus">
          <div className="bar">
            <div
              className="fill"
              style={{
                width: `${
                  (textureProgress.loaded / textureProgress.total) * 100
                }%`,
              }}
            ></div>
          </div>
        </div>
      ) : null}
      <IntroNav
        isMobile={isMobile}
        visible={allTexturesInitalized}
        hover={setOverIntroNav}
        navigate={gotoNavItem}
        texts={globeTexts}
        textIndex={textIndex}
      />
      <div
        className={classNames("introTemperature", {
          hidden: textIndex < 2,
        })}
      >
        <TemperatureUnitToggle label="TEMPERATURE" inverted />
      </div>
    </div>
  );
}
