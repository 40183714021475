import TemperatureUnitToggle from "./TemperatureUnitToggle";
import { ScenarioDropdown } from "../UI/ScenarioDropdown";
import "./CompareControls.scss";
import { CountriesDropdown } from "../UI/CountriesDropdown";

export function CompareControls({
  defaultRiskComparisonCountryValue,
  countryLookup,
  countryId,
  scenario,
  gotoExplore,
  setNavigation,
  groupsData,
  onTemperatureChange,
}) {
  const groupsValues = groupsData?.map((group) => ({
    label: group.name,
    value: group.code,
  }));
  const countryValues = [
    {
      label: "All Countries & Territories",
      value: defaultRiskComparisonCountryValue,
    },
    ...groupsValues,
    ...countryLookup.map((c) => ({
      label: c.name,
      value: c.code3,
    })),
  ];
  const allValues = [
    {
      label: "All Countries & Territories",
      value: defaultRiskComparisonCountryValue,
    },
    {
      label: "Developing Regions and Economic Groups",
      options: groupsValues,
    },
    {
      label: "Countries & Territories",
      options: [
        ...countryLookup.map((c) => ({
          label: c.name,
          value: c.code3,
        })),
      ],
    },
  ];
  const selectedCountry =
    countryId === "globalAverage"
      ? countryValues[0]
      : countryValues.find((country) => countryId === country.value);

  return (
    <div className="compare-controls">
      <div className="spacer" />
      <div className="compare-controls__selectors-wrapper">
        <div className="compare-controls__selectors">
          <CountriesDropdown
            countryValues={allValues}
            value={selectedCountry}
            onChange={(country) => setNavigation({ country: country.value })}
            onClickGlobe={gotoExplore}
          />

          <ScenarioDropdown scenario={scenario} setNavigation={setNavigation} />

          <TemperatureUnitToggle
            label="TEMPERATURE"
            onChange={onTemperatureChange}
          />
        </div>
      </div>
    </div>
  );
}
