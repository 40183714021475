import { Billboard, Line, Text } from "@react-three/drei";
import { polar2Cartesian } from "../../GlobeRenderer";

export function CityLabelLine({ city, countryOffset }) {
  const globeCityLabelAlt = 0.2;
  const lineAlt = 0;
  const labelLonOffset = (countryOffset === "right" ? -1 : 1) * 5;

  const cityLabelCoords = polar2Cartesian({
    lat: city.lat,
    lng: city.lng + labelLonOffset,
    alt: globeCityLabelAlt,
  });
  const lineEndCoords = polar2Cartesian({
    lat: city.lat,
    lng: city.lng + labelLonOffset,
    alt: globeCityLabelAlt - 0.01,
  });
  const lineCoords = polar2Cartesian({
    lat: city.lat,
    lng: city.lng,
    alt: lineAlt,
  });
  const globeCityLabel = `${city.city}, ${city.country}`;
  return (
    <group>
      <Line
        points={[
          [lineEndCoords.x, lineEndCoords.y, lineEndCoords.z],
          [lineCoords.x, lineCoords.y, lineCoords.z],
        ]}
        color={"#ffffff"}
        lineWidth={0.5}
      />

      <Billboard
        position={[cityLabelCoords.x, cityLabelCoords.y, cityLabelCoords.z]}
      >
        <Text
          fontSize="2.4"
          scale={[0.8, 0.8, 0.8]}
          color="#ffffff"
          anchorX="left"
          anchorY="middle"
          sdfGlyphSize="128"
        >
          {globeCityLabel}
        </Text>
      </Billboard>
    </group>
  );
}
