import { useEffect, useRef, useState } from "react";
import { hsl, line } from "d3";

export function LineAndPointsFromData({
  dataset,
  xScale,
  yScale,
  colorScale,
  selectedYearIndex,
  metricFormatter,
  lineStroke,
  strokeDasharray = null,
  selectedScenario = false,
  layout,
  lightnessValue,
}) {
  const linePath = line()
    .x((_, i) => xScale(i))
    .y((d) => yScale(+d["0.5"]))(dataset);

  return (
    <>
      <path
        strokeWidth={2}
        d={linePath}
        stroke={lineStroke}
        strokeDasharray={strokeDasharray}
        fill="none"
      />
      {selectedScenario && (
        <>
          {dataset.map((d, i) => {
            const value = +d["0.5"];
            const isSelectedYear = i === selectedYearIndex;
            const r = isSelectedYear ? 5 : 3;
            const fontWeight = isSelectedYear ? "bold" : "normal";
            const strokeWidth = isSelectedYear ? 2 : 1;
            const lastValue = i === dataset.length - 1;
            const translateX = lastValue ? layout.charts.right : xScale(i);
            return (
              <g key={i}>
                <line
                  x1={xScale(i)}
                  x2={xScale(i)}
                  y1={yScale?.range()[1]}
                  y2={yScale(value)}
                  stroke="#D4D6D8"
                  strokeWidth={1}
                />
                <circle
                  r={r}
                  fill={colorScale(value)}
                  stroke={"#000"}
                  strokeWidth={strokeWidth}
                  cx={xScale(i)}
                  cy={yScale(value)}
                />
                <g
                  key={i}
                  transform={`translate(${translateX}, ${yScale(value)})`}
                >
                  <TextWithBackground
                    style={{ fontWeight }}
                    value={metricFormatter(value)}
                    isSelectedYear={isSelectedYear}
                    rectColor={colorScale(value)}
                    lastValue={lastValue}
                    lightnessValue={lightnessValue}
                  />
                </g>
              </g>
            );
          })}
        </>
      )}
    </>
  );
}

function TextWithBackground({
  style,
  value,
  isSelectedYear,
  rectColor,
  lastValue,
  lightnessValue,
}) {
  const textRef = useRef();
  const [textDimensions, setTextDimensions] = useState(null);
  const dy = isSelectedYear ? "10px" : "6px";
  useEffect(() => {
    if (textRef.current) {
      setTextDimensions(textRef.current.getBoundingClientRect());
    }
  }, [value, style]);
  const rectX = lastValue
    ? -textDimensions?.width - 2
    : -textDimensions?.width / 2 - 2;

  const light = hsl(rectColor).l;
  const fillText = isSelectedYear && light < lightnessValue ? "white" : "black";
  return (
    <>
      {textDimensions && (
        <rect
          x={rectX}
          y={isSelectedYear ? 8 : 6}
          width={textDimensions.width + 4}
          height={textDimensions.height}
          fill={isSelectedYear ? rectColor : "rgba(255,255,255,0.7)"}
          fillOpacity={0.8}
        />
      )}
      <text
        fill={fillText}
        ref={textRef}
        style={style}
        dy={dy}
        dominantBaseline={"hanging"}
        textAnchor={lastValue ? "end" : "middle"}
      >
        {value}
      </text>
    </>
  );
}
