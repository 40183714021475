import { years } from "../../constants";
import { useCountryData } from "../../lib/useCountryData";
import WorldSummary from "../../data/WorldSummary.csv.js";
import { getMetricFormatter } from "../../lib/metric-utils";
import { METRIC } from "../../lib/metric-constants";

export function CountriesDistribution({
  metric,
  scenario,
  xScale,
  colorScale,
  yearIndex = 2,
  layout,
}) {
  const dataset = useCountryData({
    metric,
    scenario,
    year: years[yearIndex],
  });
  const globalAverage = WorldSummary.filter(
    (d) =>
      d.metric === metric &&
      d.scenario === scenario &&
      d.year === years[yearIndex - 1]
  );
  const globalAverageValue = +globalAverage[0]?.["0.5"];
  const formatter = getMetricFormatter(metric);

  const textAnchor =
    xScale(globalAverageValue) < layout.countriesDistribution.width * 0.3
      ? "start"
      : xScale(globalAverageValue) > layout.countriesDistribution.width * 0.8
      ? "end"
      : "middle";
  const globalLabel =
    metric === METRIC.LAND ? `Global Total: ` : `Global Avg: `;
  return (
    <>
      {dataset &&
        dataset.map((d) => {
          const value = +d["0.5"];
          return (
            <line
              class="distribution"
              key={`${d.ISO}-${d.year}`}
              x1={xScale(value)}
              x2={xScale(value)}
              y1={layout.countriesDistribution.bottom}
              y2={layout.countriesDistribution.top}
              stroke={colorScale(value)}
              strokeWidth={1}
              opacity={0.7}
            />
          );
        })}
      <line
        x1={xScale(globalAverageValue)}
        x2={xScale(globalAverageValue)}
        y1={layout.charts.top + 5}
        y2={layout.countriesDistribution.bottom}
        stroke={"black"}
        strokeWidth={1}
      />
      <text
        fontSize={12}
        x={xScale(globalAverageValue)}
        y={layout.bottomLabel.bottom - 5}
        textAnchor={textAnchor}
        fontWeight={600}
      >
        {globalLabel} {formatter(globalAverageValue)}
      </text>
    </>
  );
}
