import { csvParse } from "d3-dsv";

export const cities500kArray = csvParse(
  `city,country,Region_ID,hierid,lat,lng,area
Tokyo,Japan,JPN.41.R5148cbf71a2651b4,JPN.41.R5148cbf71a2651b4,35.63077476999084,139.77169311144294,72400672.38025807
New York,United States,USA.33.1854,USA.33.1854,40.62556480889132,-73.96940770432003,185194457.59520277
Mexico City,Mexico,MEX.9.250,MEX.9.250,19.438956618909703,-99.16639000889262,47489802.612863
Mumbai,India,IND.21.317.1249,IND.21.317.1249,18.97295412915696,72.82357781550738,73259609.98837128
São Paulo,Brazil,BRA.25.5212.R3fd4ed07b36dfd9c,BRA.25.5212.R3fd4ed07b36dfd9c,-23.606917221339444,-46.604670070803984,74540931.95533991
Delhi,India,IND.10.121.371,IND.10.121.371,28.63841210888213,77.09947878269855,1499262300.9633396
Shanghai,China,CHN.25.262.1764,CHN.25.262.1764,31.24573327938261,121.44734225268878,198705968.37497374
Kolkata,India,IND.35.592.2293,IND.35.592.2293,22.181393477683034,88.54756709705465,2673670699.276123
Los Angeles,United States,USA.5.202,USA.5.202,33.65947197077385,-118.38453448494816,10698563671.516855
Dhaka,Bangladesh,BGD.3.9.18.132,BGD.3.9.18.132,23.78394277805562,90.40314178010627,297749935.06341356
Buenos Aires,Argentina,ARG.5.184,ARG.5.184,-34.62467870245888,-58.44656064408881,223132761.7940548
Karachi,Pakistan,PAK.8.28.126,PAK.8.28.126,24.95536699367009,66.9257522991273,1007666911.8234531
Cairo,Egypt,EGY.11,EGY.11,30.06296424320165,31.533147022486506,3200784591.0944085
Rio de Janeiro,Brazil,BRA.19.3634.Rf31287f7cff5d3a1,BRA.19.3634.Rf31287f7cff5d3a1,-23.015165090369315,-43.478694690180646,388668579.85401815
Osaka,Japan,JPN.33.R7b7f7c3d0067ede8,JPN.33.R7b7f7c3d0067ede8,34.77474362181508,135.4787038782553,79546280.42331523
Beijing,China,CHN.2.18.78,CHN.2.18.78,39.92407973296672,116.26927257791638,1339541184.7490664
Manila,Philippines,PHL.47.955.Rf7fb64512597c906,PHL.47.955.Rf7fb64512597c906,14.588857470456214,120.96039353717963,7497892.631885071
Moscow,Russia,RUS.48.1150.Re704f9e837a08221,RUS.48.1150.Re704f9e837a08221,55.72585906842303,37.6106641226786,34902250.40682404
Istanbul,Türkiye,TUR.40.440,TUR.40.440,41.064911645869614,29.000893689833084,23367096.77038089
Paris,France,FRA.11.75,FRA.11.75,48.84564496396513,2.3252249924142063,107055463.4656155
Seoul,"Korea, South",KOR.15.R4924b5775a890171,KOR.15.R4924b5775a890171,37.59192161377763,126.97765203978821,64456171.821823195
Lagos,Nigeria,NGA.25.510,NGA.25.510,6.433630237031874,3.3438268957741926,38492788.70740116
Jakarta,Indonesia,IDN.8.68,IDN.8.68,-6.1884161003262,106.8126781660442,61617224.48325716
Guangzhou,China,CHN.6.46.280,CHN.6.46.280,23.218786129396854,113.36003317903085,1366968312.622734
Chicago,United States,USA.14.608,USA.14.608,41.856476901691344,-87.79650528580268,2470516669.9369793
London,United Kingdom,GBR.1.R7074136591e79d11,GBR.1.R7074136591e79d11,51.504712252675,-0.19639091240923728,77165076.47039847
Lima,Peru,PER.15.135.1340,PER.15.135.1340,-12.064748495570997,-77.06091698627044,18940555.589916334
Tehran,Iran,IRN.27.239,IRN.27.239,35.73929742348349,51.419852953814264,1458971502.8055284
Kinshasa,Congo (Kinshasa),COD.7.29.103,COD.7.29.103,-4.395675582541494,15.390473247432313,614100568.8638619
Bogotá,Colombia,COL.14.568,COL.14.568,4.244465651399118,-74.2442107064709,1691905354.7996447
Shenzhen,China,CHN.6.57.345,CHN.6.57.345,22.50771074302673,113.93024022390145,268329244.61083633
Wuhan,China,CHN.13.143.974,CHN.13.143.974,30.59936611067614,114.19261647264607,1380305906.1353996
Hong Kong,Hong Kong,HKG.4,HKG.4,22.316918537709476,114.16741395132587,14334365.530657476
Tianjin,China,CHN.28.296.2048,CHN.28.296.2048,39.12312886001276,117.17011434862391,180314526.0240377
Chennai,India,IND.31.460.1844,IND.31.460.1844,13.092907664942512,80.24602037433587,49048723.0785331
Taipei,Taiwan,TWN.3.3,TWN.3.3,25.024975714992802,121.48583841162848,291351125.2041704
Bengaluru,India,IND.17.218.765,IND.17.218.765,13.054242759928648,77.53902956052877,796172867.9692942
Bangkok,Thailand,THA.3.R94493c79c32e39a5,THA.3.R94493c79c32e39a5,13.718028883729426,100.50604723566798,52685489.4509659
Lahore,Pakistan,PAK.7.23.96,PAK.7.23.96,31.45272676557495,74.33912174182298,1840487318.3724012
Chongqing,China,CHN.3.19.93,CHN.3.19.93,29.693976551140892,106.46372759354738,949487521.5593619
Miami,United States,USA.10.360,USA.10.360,25.497855338013604,-80.38707629184029,5166575750.363807
Hyderabad,India,IND.2.20.170,IND.2.20.170,17.350291866717853,78.45624132057739,1371720702.235695
Dallas,United States,USA.44.2584,USA.44.2584,32.80159053503005,-96.76568829851522,2280307287.3724046
Santiago,Chile,CHL.11.42.248,CHL.11.42.248,-33.32417066980716,-70.68929007985784,1531164817.9318752
Philadelphia,United States,USA.39.2299,USA.39.2299,39.992735501970294,-75.15085923287772,362032859.60200304
Belo Horizonte,Brazil,BRA.13.1610.Rbdae5fa597fccbba,BRA.13.1610.Rbdae5fa597fccbba,-19.969657792679364,-43.98958086484564,145624873.0343644
Madrid,Spain,ESP.8.33.235.5912,ESP.8.33.235.5912,40.46850383227104,-3.6986062174330607,610067410.8996149
Houston,United States,USA.44.2628,USA.44.2628,29.787627245078443,-95.19444636196751,4683542964.135248
Ahmadabad,India,IND.12.124.384,IND.12.124.384,23.005208912689124,72.56427890165354,246016421.58860874
Ho Chi Minh City,Vietnam,VNM.4.41.R04f39eb861a36ae8,VNM.4.41.R04f39eb861a36ae8,10.759121781471356,106.65654826072243,57085559.69568137
Washington,United States,USA.9.317,USA.9.317,38.88523550730774,-77.04591548548458,168819206.51125428
Atlanta,United States,USA.11.444,USA.11.444,33.83477699385842,-84.46947454945352,1381193075.221269
Toronto,Canada,CAN.9.169.2940,CAN.9.169.2940,43.697572188074396,-79.40310919949378,660747890.5168816
Singapore,Singapore,SGP,SGP,1.3160794059784682,103.79224555999126,669954229.6306281
Luanda,Angola,AGO.11.96,AGO.11.96,-8.873157384097793,13.198585516566347,26791110.189427964
Baghdad,Iraq,IRQ.10.55,IRQ.10.55,33.33384954377979,44.35721258123845,835154012.294757
Barcelona,Spain,ESP.6.27.201.5116,ESP.6.27.201.5116,41.387833606701285,2.115034153101364,90098017.74782015
Haora,India,IND.35.582.2264,IND.35.582.2264,22.60045098149259,88.1711151064773,475660484.26679146
Shenyang,China,CHN.18.207.1380,CHN.18.207.1380,41.806455055549236,123.44086398234342,3462708892.2984285
Khartoum,Sudan,SDN.4.11.50.164,SDN.4.11.50.164,15.509283348326317,32.50224397325313,335934547.34624004
Pune,India,IND.21.329.1353,IND.21.329.1353,18.43233418302036,73.77688763775905,1998962697.8489466
Boston,United States,USA.22.1228,USA.22.1228,42.34630996470028,-71.03400634747067,151790677.09623802
Sydney,Australia,AUS.4.140,AUS.4.140,-33.96048740749777,151.17611639004613,12853536.929102158
Saint Petersburg,Russia,RUS.16.430.430,RUS.16.430.430,59.94651121114027,29.983941345017165,2012999229.327873
Chittagong,Bangladesh,BGD.2.4.8.55,BGD.2.4.8.55,22.318032485373973,91.9542105574869,326122135.485122
Dongguan,China,CHN.6.44.273,CHN.6.44.273,22.875540235897056,113.87391792261758,2422410786.102518
Riyadh,Saudi Arabia,SAU.7,SAU.7,24.1900831404477,44.37199901938089,379627074394.0736
Hanoi,Vietnam,VNM.2.18.Ra5f28dabe4b12dfc,VNM.2.18.Ra5f28dabe4b12dfc,21.013781227776747,105.79796126673385,36162184.30510381
Guadalajara,Mexico,MEX.14.550,MEX.14.550,20.61831329493675,-103.3930409698305,206648961.27465555
Melbourne,Australia,AUS.10.R56598671195eb31a,AUS.10.R56598671195eb31a,-37.827267316855185,144.9276912827191,30596037.70401832
Alexandria,Egypt,EGY.6,EGY.6,31.142384694744088,29.879878177258476,2401783368.13066
Chengdu,China,CHN.27.275.1878,CHN.27.275.1878,30.675467404641488,104.20844912883284,1409434666.753946
Yangon,Myanmar,MMR.14.62.285,MMR.14.62.285,16.802828453659874,96.15481192772629,96408686.48909596
Phoenix,United States,USA.3.101,USA.3.101,33.5380602446084,-112.15885538625007,24261433460.189583
Xi’an,China,CHN.23.241.1600,CHN.23.241.1600,34.27946549722277,109.00286091350529,825696385.3309203
Porto Alegre,Brazil,BRA.21.4131.7953,BRA.21.4131.7953,-30.10174282535351,-51.2084247590329,489251875.81800276
Surat,India,IND.12.144.554,IND.12.144.554,21.14389695657401,72.80838217765243,538342572.7994894
Hechi,China,CHN.7.68.394,CHN.7.68.394,23.06586803618546,109.56455611947426,3538490701.549518
Abidjan,Côte D’Ivoire,CIV.9.20,CIV.9.20,5.480613544898573,-4.053919685916474,8660593583.75956
Brasília,Brazil,BRA.7.804.1862,BRA.7.804.1862,-15.757208336664833,-47.92880254016937,469806612.3874883
Ankara,Türkiye,TUR.7.70,TUR.7.70,39.86443130120077,32.80394082239103,184332832.57138857
Monterrey,Mexico,MEX.19.958,MEX.19.958,25.67378227299886,-100.32790181369504,439887706.8020886
Yokohama,Japan,JPN.19.R13854ccb66d0489f,JPN.19.R13854ccb66d0489f,35.2453793424765,139.60265734882472,208793306.07607433
Nanjing,China,CHN.15.166.1134,CHN.15.166.1134,32.115863882177734,118.84258754917846,1030259291.1329975
Montréal,Canada,CAN.11.195.Rd6851092cdcff9c8,CAN.11.195.Rd6851092cdcff9c8,45.54711760696332,-73.60762957569072,227890381.21673393
Guiyang,China,CHN.8.80.473,CHN.8.80.473,26.530060726293282,106.72434279815123,2366493978.76772
Recife,Brazil,BRA.17.3294.Rddfb19cfe46ce1cf,BRA.17.3294.Rddfb19cfe46ce1cf,-8.080903061676873,-34.96850596123744,80539757.15548897
Seattle,United States,USA.48.2971,USA.48.2971,47.414227296060545,-121.96527658990908,5709672812.756512
Harbin,China,CHN.11.103.724,CHN.11.103.724,45.7082213778544,126.50664839772308,1682567714.4679866
San Francisco,United States,USA.5.221,USA.5.221,37.75195523428075,-122.50534906975795,140902539.58140385
Fortaleza,Brazil,BRA.6.678.R8ec4611fc33882a1,BRA.6.678.R8ec4611fc33882a1,-3.7513598056341446,-38.57636690023711,96633728.33657101
Zhangzhou,China,CHN.4.28.187,CHN.4.28.187,24.591809898947705,117.59000635562016,267713966.9800221
Detroit,United States,USA.23.1315,USA.23.1315,42.194290961620034,-83.16613264463193,1636525630.820096
Salvador,Brazil,BRA.5.539.R1c0b45048a7c4c03,BRA.5.539.R1c0b45048a7c4c03,-12.99846130014003,-38.51614658993958,26420331.311305463
Busan,"Korea, South",KOR.1.R9bba2a8ea1df493d,KOR.1.R9bba2a8ea1df493d,35.065576107139016,128.95855566419573,44363778.83034968
Johannesburg,South Africa,ZAF.2.87,ZAF.2.87,-26.25582704195308,27.963075578323572,496739489.25291026
Berlin,Germany,DEU.3.12.141,DEU.3.12.141,52.48943603115806,13.427372041278737,905689520.0816733
Algiers,Algeria,DZA.4.97,DZA.4.97,36.769373826497,3.0220447039174676,43437656.6898717
Rome,Italy,ITA.8.39,ITA.8.39,41.8638466943653,12.56384741942401,5404710262.804543
Pyongyang,"Korea, North",PRK.11.170,PRK.11.170,39.07075917204999,125.78880385557312,1172909735.5502064
Medellín,Colombia,COL.2.76,COL.2.76,6.253322340269368,-75.6490175969585,361955050.2344667
Kabul,Afghanistan,AFG.13.140,AFG.13.140,34.48966588973043,69.0986194475163,213911465.31414714
Athens,Greece,GRC.2.6,GRC.2.6,37.5188647118489,23.46854440891858,3834003130.821414
Nagoya,Japan,JPN.1.39,JPN.1.39,35.11062654183616,136.89229499747677,310457898.3308186
Cape Town,South Africa,ZAF.10.323,ZAF.10.323,-33.94188260931959,18.402976306084202,80333618.59049289
San Diego,United States,USA.5.220,USA.5.220,32.910738684341986,-117.05407093780333,11122953182.821987
Changchun,China,CHN.17.189.1292,CHN.17.189.1292,43.77716861179532,125.29780018894246,1576770978.22329
Casablanca,Morocco,MAR.5.12.R4f398f03ea45306d,MAR.5.12.R4f398f03ea45306d,33.592463592131686,-7.627861647165275,12908318.257890465
Dalian,China,CHN.18.199.1345,CHN.18.199.1345,38.93601260272265,121.46220825604809,596624508.5911009
Cawnpore,India,IND.33.533.2130,IND.33.533.2130,26.39487846845514,80.28782230911004,1055052910.0652804
Kano,Nigeria,NGA.20.400,NGA.20.400,11.9960514523488,8.490841815403277,7578271.946574568
Tel Aviv-Yafo,Israel,ISR.7,ISR.7,32.07177172649533,34.787943228284846,160844524.30422077
Addis Ababa,Ethiopia,ETH.1.6.26,ETH.1.6.26,9.043511325467259,38.67866187680805,3825550.6843360374
Curitiba,Brazil,BRA.16.2860.R193cbbf8202aaaef,BRA.16.2860.R193cbbf8202aaaef,-25.425474227262807,-49.321341853118255,202919552.24189493
Zibo,China,CHN.24.261.1753,CHN.24.261.1753,36.806204798890434,118.09085909369963,1333665535.6713483
Jeddah,Saudi Arabia,SAU.11,SAU.11,20.852067352743347,40.35516215356128,138720287058.4568
Nairobi,Kenya,KEN.4.22.R2947e0197ea9b378,KEN.4.22.R2947e0197ea9b378,-1.3109270846060497,36.82567649973845,30981122.952107523
Hangzhou,China,CHN.32.335.2338,CHN.32.335.2338,30.231059120300806,120.11014406941862,465198245.5682818
Benoni,South Africa,ZAF.2.80,ZAF.2.80,-26.166730730677543,28.3478283556219,243364870.11865702
Caracas,Venezuela,VEN.10.84,VEN.10.84,10.974441312386844,-66.66648355401065,319967165.7316
Milan,Italy,ITA.10.52,ITA.10.52,45.461960302425396,9.120740448400847,1565069739.137212
Stuttgart,Germany,DEU.1.3.35,DEU.1.3.35,48.762510759422874,9.143590044719783,217023597.59524527
Kunming,China,CHN.31.325.2264,CHN.31.325.2264,25.094157270446534,102.83434913005104,1171809516.6827025
Dar es Salaam,Tanzania,TZA.2.7,TZA.2.7,-6.922348063400685,39.14770190413757,357613642.49104565
Minneapolis,United States,USA.24.1343,USA.24.1343,45.01862130774698,-93.45084054422003,1528509660.3783157
Jaipur,India,IND.29.439.1743,IND.29.439.1743,26.912190931367856,75.70214761266635,825586308.5864472
Taiyuan,China,CHN.26.270.1842,CHN.26.270.1842,37.8342022235603,112.48724586330937,1468590368.7470787
Frankfurt,Germany,DEU.7.16.Re828af1bccfae314,DEU.7.16.Re828af1bccfae314,50.1059133205572,8.66207323151618,293181511.01662964
Qingdao,China,CHN.24.254.1712,CHN.24.254.1712,36.17161175665896,120.4584154111158,1106342472.8819842
Surabaya,Indonesia,IDN.12.178,IDN.12.178,-7.262670928466414,112.68755161665972,338132712.5492645
Lisbon,Portugal,PRT.12,PRT.12,38.88858014261662,-9.266741513700675,2763871053.4549394
Tampa,United States,USA.10.345,USA.10.345,27.84859383495236,-82.48232715366372,2806457971.6179376
Jinan,China,CHN.24.249.1673,CHN.24.249.1673,36.63003653218004,117.10695074803361,2126647314.772378
Fukuoka,Japan,JPN.7.238,JPN.7.238,33.59963899638772,130.31413674594066,316364583.02335554
Campinas,Brazil,BRA.25.4756.R4680b3af2c5befe7,BRA.25.4756.R4680b3af2c5befe7,-22.908268289752506,-47.096094234047484,713687913.3274108
Denver,United States,USA.6.258,USA.6.258,39.73472025612454,-104.87924999854846,399076347.0221197
Kaohsiung,Taiwan,TWN.1.1,TWN.1.1,22.68046756974641,120.25038614271286,75067493.98866336
Quezon City,Philippines,PHL.47.963.Rbba9b4e449ab7180,PHL.47.963.Rbba9b4e449ab7180,14.687132148832962,121.04410191156478,104920371.43426287
Katowice,Poland,POL.13.R4d9a7ebd2cc96afc,POL.13.R4d9a7ebd2cc96afc,50.33413217820958,19.14747935937109,571198754.7895039
Aleppo,Syria,SYR.2.10,SYR.2.10,35.99410775201257,37.09570351388392,3009438466.815169
Durban,South Africa,ZAF.3.110,ZAF.3.110,-29.946125076267577,30.942168980717017,228288718.51943183
Kyiv,Ukraine,UKR.11.R51883c8036e5a7d5,UKR.11.R51883c8036e5a7d5,50.352426896418116,30.588909701593554,261975503.74421576
Lucknow,India,IND.33.538.2141,IND.33.538.2141,26.827171099543982,80.81930849667559,971487305.305864
Giza,Egypt,EGY.8,EGY.8,29.252239336320745,30.56355748512689,38230786237.581
Zhengzhou,China,CHN.12.129.903,CHN.12.129.903,34.77081623625371,113.62492724723184,1062731660.4976562
Taichung,Taiwan,TWN.4.15,TWN.4.15,24.148870998321136,120.66606409176109,152000675.90639424
Brooklyn,United States,USA.33.1854,USA.33.1854,40.62556480889132,-73.96940770432003,185194457.59520277
Ibadan,Nigeria,NGA.31.637,NGA.31.637,7.364076865920736,3.9093834754011803,23049803.286547136
Faisalabad,Pakistan,PAK.7.21.84,PAK.7.21.84,31.206521578030816,73.11628582211752,5670835246.6735115
Fuzhou,China,CHN.4.20.123,CHN.4.20.123,26.153332872194184,119.36578016210647,1050220440.3007437
Changsha,China,CHN.14.150.1017,CHN.14.150.1017,28.1549229285023,112.97306369645156,355169198.688666
Dakar,Senegal,SEN.1.1.1,SEN.1.1.1,14.695841950646743,-17.48215658520586,82424563.40132466
Izmir,Türkiye,TUR.41.485,TUR.41.485,38.401634786662875,27.119705491286048,66810549.254457116
Xiangtan,China,CHN.14.156.1070,CHN.14.156.1070,27.888333662299544,112.96037011886347,277343371.6725435
Lanzhou,China,CHN.5.35.219,CHN.5.35.219,36.1505755427431,103.42738297131244,1683331928.1628602
Incheon,"Korea, South",KOR.11.R5ca567607be6efbd,KOR.11.R5ca567607be6efbd,37.43964268886299,126.62826056409385,43062290.23288238
Sapporo,Japan,JPN.12.576,JPN.12.576,42.98554324189405,141.24754479884834,1085253153.327157
Xiamen,China,CHN.4.27.179,CHN.4.27.179,24.522147916025542,118.02795401510078,581315509.9174179
Guayaquil,Ecuador,ECU.10.82.406,ECU.10.82.406,-2.3487204458404536,-79.9483323807945,3158198132.610336
George Town,Malaysia,MYS.9.65,MYS.9.65,5.395100861803608,100.27233154650163,123406926.16151962
Mashhad,Iran,IRN.23.204,IRN.23.204,36.431861556844176,59.90982373063523,13073074115.917936
Damascus,Syria,SYR.13.52,SYR.13.52,33.38340035392017,36.27734578135868,986566773.8627262
Daegu,"Korea, South",KOR.4.44,KOR.4.44,35.85687049185111,128.56345416546998,106737848.7133127
Nagpur,India,IND.21.323.1304,IND.21.323.1304,20.97229505337761,79.02599243748291,669073232.6499349
Lianshan,China,CHN.18.203.1363,CHN.18.203.1363,40.91357015112408,120.69241455401095,2306324230.769234
Shijiazhuang,China,CHN.10.97.658,CHN.10.97.658,38.05955456416002,114.45544276641444,250098548.5108478
Tunis,Tunisia,TUN.23,TUN.23,36.83086310924299,10.180380703910656,269836389.77975404
Vienna,Austria,AUT.9.99,AUT.9.99,48.207909332500385,16.373021671793662,431112768.1151971
Jilin,China,CHN.17.190.1300,CHN.17.190.1300,43.87055950827798,126.5313447459555,1150748429.0706031
Omdurman,Sudan,SDN.4.11.54.171,SDN.4.11.54.171,15.424910169561276,32.41345130643951,1019613732.4603267
Bandung,Indonesia,IDN.10.94,IDN.10.94,-6.923073851417391,107.62403206309286,165349035.59539014
Bekasi,Indonesia,IDN.10.95,IDN.10.95,-6.3061702516323646,106.95175590950005,234869057.75051826
Mannheim,Germany,DEU.1.2.R8e3580ead9e07039,DEU.1.2.R8e3580ead9e07039,49.43928139615767,8.588593530201466,236706630.76765776
Nanchang,China,CHN.16.181.1248,CHN.16.181.1248,28.742961375385715,115.80821224703611,529868852.2626946
Wenzhou,China,CHN.32.344.2406,CHN.32.344.2406,27.986203646562082,120.67056454199681,191568531.59344324
Queens,United States,USA.33.1872,USA.33.1872,40.65007162830978,-73.86085138954226,308401562.569007
Vancouver,Canada,CAN.2.33.913,CAN.2.33.913,49.25138320747769,-123.14448945876097,116342491.80882563
Birmingham,United Kingdom,GBR.1.106,GBR.1.106,52.47670208040498,-1.908198529355903,882477759.6793426
Cali,Colombia,COL.30.1043,COL.30.1043,3.40183467830142,-76.61874592115583,502693123.77748054
Naples,Italy,ITA.5.21,ITA.5.21,40.76294073725739,14.273082622205083,1178177699.2396188
Sendai,Japan,JPN.24.R92edee8d7344600c,JPN.24.R92edee8d7344600c,38.29862119242584,140.82808114795878,918076908.3068498
Manchester,United Kingdom,GBR.1.58,GBR.1.58,53.5082615971224,-2.3095900368694964,1366057273.0405006
Puebla,Mexico,MEX.21.1116,MEX.21.1116,19.031559243418233,-98.2298347752153,43942925.49290005
Tripoli,Libya,LBY.29,LBY.29,32.83125485212526,13.097802706741339,730286735.0751613
Tashkent,Uzbekistan,UZB.13.145,UZB.13.145,41.27031091464542,69.1902476618401,337736327.7608671
Havana,Cuba,CUB.4.R1bf4d0b1e9df8510,CUB.4.R1bf4d0b1e9df8510,23.11799980326604,-82.38464958515695,24940333.881011516
Gaoping,China,CHN.27.286.1984,CHN.27.286.1984,30.76138326960563,106.05156092617271,2556187995.4039693
Baltimore,United States,USA.21.1195.R90cc82a36b95f891,USA.21.1195.R90cc82a36b95f891,39.252172658932096,-76.60967184263167,215829712.64344805
Nanning,China,CHN.7.74.442,CHN.7.74.442,22.857440018220565,108.12068102176451,1738232079.671749
Belém,Brazil,BRA.14.2418.R74e10dd66f97d9ec,BRA.14.2418.R74e10dd66f97d9ec,-1.4448816314889612,-48.48914431238029,38724071.84196269
Patna,India,IND.5.88.287,IND.5.88.287,25.489947620655496,85.18575593655483,573348299.1067755
Santo Domingo,Dominican Republic,DOM.5.32,DOM.5.32,18.47010777839665,-69.96100669433791,73478274.323594
Ürümqi,China,CHN.29.311.2137,CHN.29.311.2137,43.54081839508361,87.82541693308863,10285636124.308636
Zaozhuang,China,CHN.24.260.1748,CHN.24.260.1748,34.7460905401764,117.5404180613558,2345617106.877491
Baku,Azerbaijan,AZE.19,AZE.19,40.23935356459668,49.80214450781944,2141556711.3173158
Accra,Ghana,GHA.5.70,GHA.5.70,5.581530480471132,-0.2314593558047601,273721531.41611814
Yantai,China,CHN.24.259.1745,CHN.24.259.1745,37.560655139358055,121.41469071028867,122903255.95944048
Medan,Indonesia,IDN.32.421,IDN.32.421,3.681128900162146,98.66018257097139,289935331.1645273
Santa Cruz,Bolivia,BOL.8.78.260,BOL.8.78.260,-17.729292976018257,-63.37495650584601,940759885.9469033
Tongshan,China,CHN.15.172.1168,CHN.15.172.1168,34.33201723213545,117.28288692098327,3024009317.1900196
Riverside,United States,USA.5.216,USA.5.216,33.74923895726403,-115.71334718103915,18902029452.356884
Linyi,China,CHN.24.253.1701,CHN.24.253.1701,35.092123081645774,118.36870698595827,1736775408.3809037
St. Louis,United States,USA.26.1581.Rd3313b8146f2e1b9,USA.26.1581.Rd3313b8146f2e1b9,38.64907860243269,-90.25803725760858,157364243.07563728
Las Vegas,United States,USA.29.1752,USA.29.1752,35.90811379083325,-114.74461195285072,20894722110.497116
Maracaibo,Venezuela,VEN.24.311,VEN.24.311,10.696609659592662,-71.64812284587629,312132914.37186587
Kuwait City,Kuwait,KWT.4,KWT.4,29.344016473602416,47.9239632693633,50653347.32783043
Ad Dammam,Saudi Arabia,SAU.8,SAU.8,26.257208705146528,49.52147615824994,550015185992.0981
Portland,United States,USA.38.2238,USA.38.2238,45.842654218023824,-122.77934959066637,1392408047.1642294
Haikou,China,CHN.9.87.541,CHN.9.87.541,19.997115628869075,110.22596700800901,218405586.02367923
Hiroshima,Japan,JPN.11.440,JPN.11.440,34.41255782837588,132.44817710897328,932549122.8681694
Baotou,China,CHN.19.210.1394,CHN.19.210.1394,40.622664500882614,109.89659644466998,2284241377.7957892
Hefei,China,CHN.1.8.43,CHN.1.8.43,31.818493917450624,117.2529226329619,523313539.8177455
Indore,India,IND.20.278.1053,IND.20.278.1053,22.677047293717692,75.97686600523946,1004298770.7848275
Goiânia,Brazil,BRA.9.975.R6e53f867232dd0ad,BRA.9.975.R6e53f867232dd0ad,-16.655131022071842,-49.279080482332965,616102594.0334668
Sanaa,Yemen,YEM.9.R392a529bfb0144c1,YEM.9.R392a529bfb0144c1,15.36393336255927,44.1995570574516,29881166.567607503
San Antonio,United States,USA.44.2542,USA.44.2542,29.623891006196544,-98.42730907646919,3201702971.941997
Port-au-Prince,Haiti,HTI.8.33.105,HTI.8.33.105,18.53124521240995,-72.35599629480072,36731317.91821772
Haiphong,Vietnam,VNM.2.16.Rf6e96f12fac0ff30,VNM.2.16.Rf6e96f12fac0ff30,20.83994562633753,106.66837801562782,21720456.62285945
Suzhou,China,CHN.1.14.66,CHN.1.14.66,33.66512134776873,117.10803246212836,2951454690.207206
Nanyang,China,CHN.12.121.835,CHN.12.121.835,32.968352101403134,112.52049243261874,2011786427.4956431
Bucharest,Romania,ROU.10,ROU.10,44.52579279880441,26.097279261669396,2090354818.6930156
Ningbo,China,CHN.32.340.2375,CHN.32.340.2375,29.938381002067747,121.46414393356892,201388242.04868463
Douala,Cameroon,CMR.5.29,CMR.5.29,3.9662367431278134,9.565611259606024,1263611085.583343
Tangshan,China,CHN.10.98.675,CHN.10.98.675,39.70410024479455,118.28499006254965,771910873.3273312
Tainan,Taiwan,TWN.4.17,TWN.4.17,23.000658025665473,120.13801126409871,178257502.30459046
Xiping,China,CHN.26.264.1779,CHN.26.264.1779,40.130515527459245,113.15485393681452,2069664912.4336047
Asunción,Paraguay,PRY.4.25,PRY.4.25,-25.299953855554012,-57.62521259212085,143598814.2719194
Saidu Sharif,Pakistan,PAK.5.15.64,PAK.5.15.64,35.30638815642206,72.43347408271858,5216632584.169371
Brisbane,Australia,AUS.6.R3ba02071c0bb1f99,AUS.6.R3ba02071c0bb1f99,-27.46332834164322,152.99978159247632,58438182.55876768
Rawalpindi,Pakistan,PAK.7.25.110,PAK.7.25.110,33.54501260843961,73.08650608992133,5238128942.077437
Sacramento,United States,USA.5.217,USA.5.217,38.298250476786464,-121.53591427006603,2615214921.8164563
Beirut,Lebanon,LBN.2.5,LBN.2.5,33.87818818960753,35.48154202932524,25727978.839024495
San Jose,United States,USA.5.226,USA.5.226,37.20509819983007,-121.70709778222684,3396136410.7928467
Minsk,Belarus,BLR.5.86,BLR.5.86,53.93597164489698,27.49868205531074,2264757807.47303
Kyoto,Japan,JPN.22.962,JPN.22.962,35.10359538496212,135.71556968013715,843040083.7742172
Barranquilla,Colombia,COL.4.141,COL.4.141,10.895308904135648,-74.69770913675828,1179238018.6964345
Orlando,United States,USA.10.365,USA.10.365,28.55098792147006,-81.22681460365209,2570090059.4004536
Shuyangzha,China,CHN.15.168.1142,CHN.15.168.1142,34.141649239670976,118.81063709111523,2327533441.71284
Valencia,Venezuela,VEN.7.286,VEN.7.286,10.034907273187843,-67.98368887875507,694242563.6512467
Hamburg,Germany,DEU.6.15.161,DEU.6.15.161,53.553996561943336,10.013712613626259,766453262.3959435
Vadodara,India,IND.12.147.592,IND.12.147.592,22.2595905934101,73.17313887338449,663160554.9175906
Manaus,Brazil,BRA.4.179.223,BRA.4.179.223,-2.7050052840952103,-60.14082425990249,11542679181.953995
Shangqiu,China,CHN.12.125.866,CHN.12.125.866,34.45013069360181,115.62330960259371,102219703.10638909
Wuxi,China,CHN.15.171.1161,CHN.15.171.1161,31.48946741115205,120.1829612155444,445956996.2966086
Palembang,Indonesia,IDN.31.409,IDN.31.409,-3.0063588192757416,104.70901943515324,259176457.88277653
Brussels,Belgium,BEL.2.2.4.R47143224496e2c2a,BEL.2.2.4.R47143224496e2c2a,50.82634253391382,4.370917453237413,149234975.96296725
Essen,Germany,DEU.10.26.273,DEU.10.26.273,51.41453030010719,6.991614906096253,210160899.37537527
Cleveland,United States,USA.36.2064,USA.36.2064,41.443312568815884,-81.66132007869354,1167746140.7383876
Bhopal,India,IND.20.264.1013,IND.20.264.1013,23.28402858700818,77.37599640819867,1305988024.1590939
Hohhot,China,CHN.19.213.1414,CHN.19.213.1414,40.831011405633085,111.79221765334624,2060431424.9031835
Pittsburgh,United States,USA.39.2250,USA.39.2250,40.459116254587876,-79.94166783404755,1906849586.5302098
Luoyang,China,CHN.12.120.824,CHN.12.120.824,34.65194934263932,112.40683781909718,478015536.3655701
Santos,Brazil,BRA.25.Re40fc83ec83ed646,BRA.25.Re40fc83ec83ed646,-23.9411530711279,-46.40827280037571,708169780.8678662
Jingling,China,CHN.13.142.970,CHN.13.142.970,30.63652016329601,113.0421271814094,2622979463.392513
Warsaw,Poland,POL.9.222,POL.9.222,52.20658797352174,21.016894951587133,557865124.7671344
Rabat,Morocco,MAR.11.35.R433f8d17c9514d7a,MAR.11.35.R433f8d17c9514d7a,33.994182904649094,-6.858693788690195,61025631.022689775
Vitória,Brazil,BRA.8.R6c3fb7e0782b50e9,BRA.8.R6c3fb7e0782b50e9,-20.341950057974447,-40.43066779352945,486649633.3321844
Quito,Ecuador,ECU.18.173.857,ECU.18.173.857,-0.2109541707790705,-78.53909563601333,356372685.85592335
Antananarivo,Madagascar,MDG.1.1.7.102,MDG.1.1.7.102,-18.900275378222943,47.4977441588003,73291714.47077426
Coimbatore,India,IND.31.461.1849,IND.31.461.1849,11.007389296852823,76.8672208127203,1357509612.3564386
Daqing,China,CHN.11.101.710,CHN.11.101.710,46.24921270716802,124.75668228216978,4311254890.55021
Lu’an,China,CHN.1.12.57,CHN.1.12.57,31.670086206052613,116.43252323981719,3615274865.4953794
Sanzhou,China,CHN.3.19.112,CHN.3.19.112,30.696340479846878,108.39303626883205,3477373322.7550144
Budapest,Hungary,HUN.14,HUN.14,47.43361573795894,19.305618407895192,7057246501.365415
Turin,Italy,ITA.13.69,ITA.13.69,45.130577671337406,7.498997569022687,6790333744.306179
Suzhou,China,CHN.15.169.1150,CHN.15.169.1150,31.333537141393382,120.63945273384525,476451639.5405521
Ludhiana,India,IND.28.415.1608,IND.28.415.1608,30.803258022139214,75.82504747743477,1566720668.1286962
Cincinnati,United States,USA.36.2077,USA.36.2077,39.16815247160397,-84.51717721401342,1053824173.1553638
Kumasi,Ghana,GHA.1.16,GHA.1.16,6.674637220328556,-1.613341444944142,284704147.88062084
Manhattan,United States,USA.33.1862,USA.33.1862,40.77188607456325,-73.98740950526316,79200712.73082513
Qiqihar,China,CHN.11.109.770,CHN.11.109.770,47.40317713585265,123.9151976534581,4044448699.5605927
Anshan,China,CHN.18.196.1331,CHN.18.196.1331,41.05420854071079,122.98705490989919,630831797.9631364
Austin,United States,USA.44.2754,USA.44.2754,30.450707319079246,-97.77263518972617,2722337141.519628
Zhongli,Taiwan,TWN.4.21,TWN.4.21,24.920160266794998,121.1489359288213,1096509758.9524522
Handan,China,CHN.10.93.615,CHN.10.93.615,36.585238004527945,114.4809453177504,609510787.4024262
Tai’an,China,CHN.24.256.1720,CHN.24.256.1720,36.155269566631304,117.16433561924565,2055224117.914143
Esfahan,Iran,IRN.5.47,IRN.5.47,32.30261714389049,52.30184241928896,13990703873.899864
Kansas City,United States,USA.26.1534,USA.26.1534,39.11741473517696,-94.38261268065328,1631647762.6792681
Yaoundé,Cameroon,CMR.2.12,CMR.2.12,3.7942295354871103,11.489141772470788,270559145.9466705
Shantou,China,CHN.6.54.330,CHN.6.54.330,23.322351262577577,116.68614050699944,341423294.2817812
Agra,India,IND.33.493.2007,IND.33.493.2007,27.156937537420596,77.98379405582799,523971865.4985469
Zhanjiang,China,CHN.6.60.360,CHN.6.60.360,21.116810708327186,110.42112635725458,1455711733.692366
La Paz,Bolivia,BOL.4.48.165,BOL.4.48.165,-16.21373522529417,-67.96580708981831,2075973740.8992333
Kalyan,India,IND.21.336.1428,IND.21.336.1428,19.250900021939955,73.18426126755989,307164410.02264094
Abuja,Nigeria,NGA.15.278,NGA.15.278,9.190824302564476,7.412166738000792,818299869.2239375
Harare,Zimbabwe,ZWE.2.2,ZWE.2.2,-17.87018971909093,31.040240568093928,921716828.8678348
Indianapolis,United States,USA.15.745,USA.15.745,39.81828680794154,-86.16862814338266,1113475039.964514
Xiantao,China,CHN.13.146.990,CHN.13.146.990,30.310251835475324,113.34111754620783,2535554912.9742174
Khulna,Bangladesh,BGD.4.16.41.283,BGD.4.16.41.283,22.807919833629676,89.60867132194613,96406728.66992068
Weifang,China,CHN.24.257.1729,CHN.24.257.1729,36.6886026925881,119.01426247795784,369642588.9708882
Tijuana,Mexico,MEX.3.17,MEX.3.17,32.32142496117923,-116.89446724688499,1747776915.4239964
Santiago,Dominican Republic,DOM.R15f8ee3249eb8be0,DOM.R15f8ee3249eb8be0,19.52558480865929,-70.79917565313953,5543141547.606854
Xinyang,China,CHN.12.127.890,CHN.12.127.890,32.1450796823938,114.04832511431816,203323801.4454867
Luzhou,China,CHN.27.283.1962,CHN.27.283.1962,29.001985431539214,105.44854169458317,2201114220.6239395
Perth,Australia,AUS.11.Rea19393e048c00bc,AUS.11.Rea19393e048c00bc,-32.01115447838023,115.83099214435558,154379609.89796716
Toluca,Mexico,MEX.15.637,MEX.15.637,19.343155159572817,-99.80265879094185,570161704.7299409
Vishakhapatnam,India,IND.2.22.189,IND.2.22.189,17.707311053091196,83.22874734487331,531369757.9710569
Leeds,United Kingdom,GBR.1.108,GBR.1.108,53.75357005137159,-1.6987422807363688,2053059274.291357
Kobe,Japan,JPN.13.654,JPN.13.654,34.72599453120409,135.16206375663612,569819673.9660795
Columbus,United States,USA.36.2071,USA.36.2071,39.96753863332651,-83.10146415413622,1395475696.6958494
Multan,Pakistan,PAK.7.24.103,PAK.7.24.103,29.82259025073954,71.39770659652663,4050148033.8368897
Kochi,India,IND.18.244.938,IND.18.244.938,10.085070557495563,76.21943932775787,205933399.39363456
Montevideo,Uruguay,URY.10.107,URY.10.107,-34.84624944556222,-56.26043605662635,464224678.05091083
Gujranwala,Pakistan,PAK.7.22.88,PAK.7.22.88,32.02968521061652,74.12859587262142,2315021692.887444
Niterói,Brazil,BRA.19.R63c3a59417d1f2a1,BRA.19.R63c3a59417d1f2a1,-22.869361005562393,-43.093618816875775,381906142.42400074
Ganzhou,China,CHN.16.177.1207,CHN.16.177.1207,25.827685297928372,114.9046305613739,446270922.4338209
Florence,Italy,ITA.16.90,ITA.16.90,43.806125801369845,11.23126550662536,3505433051.368832
Liuzhou,China,CHN.7.73.432,CHN.7.73.432,24.21135900286886,109.26667366959795,2540436208.9096837
Bamako,Mali,MLI.1.1.1,MLI.1.1.1,12.61404878260253,-8.019117572556528,257054946.92492926
Conakry,Guinea,GIN.2.6.39,GIN.2.6.39,9.493434097409946,-13.760963255532356,38201318.754273586
Bursa,Türkiye,TUR.21.223,TUR.21.223,40.174982133013245,29.06389999313369,59193462.19731697
León de los Aldama,Mexico,MEX.11.321,MEX.11.321,21.079459311820056,-101.67839254310391,975765780.6253865
Virginia Beach,United States,USA.47.2945,USA.47.2945,36.68464447479385,-76.03125683375958,679797642.0151498
Nasik,India,IND.21.326.1330,IND.21.326.1330,19.96523388429869,73.66797767589554,1299673603.1823888
Fushun,China,CHN.18.201.1355,CHN.18.201.1355,41.875551886490165,123.88997485476496,709702467.7202467
Changde,China,CHN.14.149.1009,CHN.14.149.1009,28.994221947854975,111.7571013893461,2750741633.366807
Daejeon,"Korea, South",KOR.5.Rf596123caa0aa819,KOR.5.Rf596123caa0aa819,36.35318053278339,127.33515681559305,209006742.237039
Charlotte,United States,USA.34.1953,USA.34.1953,35.265518613757436,-80.91241808908593,1439537833.0025864
Phnom Penh,Cambodia,KHM.16.108.R533663be196086f2,KHM.16.108.R533663be196086f2,11.546504403035986,104.900486865873,37952182.234790295
Neijiang,China,CHN.27.288.1991,CHN.27.288.1991,29.608212399576498,105.12229546390037,1559126819.4453034
Quanzhou,China,CHN.4.25.164,CHN.4.25.164,25.06853436135683,118.59515561444424,543846514.2100314
Kharkiv,Ukraine,UKR.8.191,UKR.8.191,49.98073642204555,36.2556194698757,278947787.5492811
Hyderabad City,Pakistan,PAK.8.27.117,PAK.8.27.117,25.368753606093676,68.53517137376019,2212099988.548069
Bronx,United States,USA.33.1833,USA.33.1833,40.83474548600794,-73.85563632236016,111346887.81444363
Lomé,Togo,TGO.3.10,TGO.3.10,6.184015861474596,1.2205144745433643,288831438.9607994
Córdoba,Argentina,ARG.6.186,ARG.6.186,-31.4409016062205,-64.18902925196986,555292019.2888728
Huainan,China,CHN.1.10.47,CHN.1.10.47,32.705316592052334,116.88933182809215,1042903605.6786399
Doha,Qatar,QAT.1,QAT.1,25.299808277921016,51.545941602942754,87562590.82107502
Kuala Lumpur,Malaysia,MYS.12.153,MYS.12.153,3.105800322899434,101.58415509214524,742651515.9183534
Maputo,Mozambique,MOZ.5.51.178,MOZ.5.51.178,-26.01834712920755,32.70360697523024,174031493.14728585
Kaduna,Nigeria,NGA.19.355,NGA.19.355,10.540972483721847,7.434736160078625,118051539.6562003
Gwangju,"Korea, South",KOR.7.75,KOR.7.75,35.155200864559966,126.92469021962246,101334490.0831994
Kawanakajima,Japan,JPN.19.848,JPN.19.848,35.53184017376568,139.65509423433747,151266251.5287743
San Salvador,El Salvador,SLV.10,SLV.10,13.77260295838543,-89.18864455873057,929324851.8596121
Suining,China,CHN.27.291.2013,CHN.27.291.2013,30.410884480319062,105.4740696721916,1854089319.6143727
Lyon,France,FRA.82.69,FRA.82.69,45.87034370810425,4.642355280803369,3272080884.2252545
Karaj,Iran,IRN.27.231,IRN.27.231,35.89198532574595,50.87274846893497,1920439673.4594784
Kampala,Uganda,UGA.16.47,UGA.16.47,0.296925316440489,32.595819372494766,182058525.8956754
Tabriz,Iran,IRN.4.24,IRN.4.24,38.14257554284128,46.843597635343286,2621934533.6052628
The Hague,Netherlands,NLD.14.402,NLD.14.402,52.05433181776429,4.303451470330351,73831059.82636636
Davao,Philippines,PHL.28.517.R300ba938cf441533,PHL.28.517.R300ba938cf441533,7.098855131891762,125.56769731996431,188353642.9566462
Marseille,France,FRA.93.13,FRA.93.13,43.427633141673496,5.115728916772105,5245805798.4553795
Meerut,India,IND.33.544.2159,IND.33.544.2159,28.925177474615936,77.67219145379022,928836080.1673002
Mianyang,China,CHN.27.285.1976,CHN.27.285.1976,31.495656617504217,104.82966897027133,1562489984.7433014
Semarang,Indonesia,IDN.11.123,IDN.11.123,-7.026471426747045,110.38212617121347,372918145.0132112
Faridabad,India,IND.13.151.605,IND.13.151.605,28.306569351081652,77.34583982240837,855833952.7401438
Novosibirsk,Russia,RUS.55.1375.1492,RUS.55.1375.1492,54.94912661080741,82.87546652898222,3138600664.856683
Mecca,Saudi Arabia,SAU.11,SAU.11,20.852067352743347,40.35516215356128,138720287058.4568
Dubai,United Arab Emirates,ARE.3,ARE.3,25.004828467734896,55.38576406432609,3959421731.788859
Milwaukee,United States,USA.50.3091,USA.50.3091,43.006796575716486,-87.92927487090915,614718832.4265475
Auckland,New Zealand,NZL.1,NZL.1,-36.64861009025096,174.85572246762874,5104391767.107466
Ma’anshan,China,CHN.1.13.61,CHN.1.13.61,31.696052083286524,118.53169671841265,286756230.77412826
Brazzaville,Congo (Brazzaville),COG.9,COG.9,-3.714526063786431,14.7815166040345,35507759368.70659
Yiyang,China,CHN.14.158.1084,CHN.14.158.1084,28.662035100383353,112.28640224102342,431703703.72506833
Varanasi,India,IND.33.562.2214,IND.33.562.2214,25.37079597897556,82.88222256781926,1567850781.1387403
Lubumbashi,Congo (Kinshasa),COD.6.24.86,COD.6.24.86,-11.590732828585137,27.978161891236805,10029366828.178505
Juárez,Mexico,MEX.6.170,MEX.6.170,31.422190820847366,-106.54671640263494,3007702788.0979915
Ghaziabad,India,IND.33.520.2089,IND.33.520.2089,28.76372917042527,77.41630871181425,730072298.2269338
Pretoria,South Africa,ZAF.2.102,ZAF.2.102,-25.57652721281923,28.195769665047557,1341898930.0149872
Heze,China,CHN.24.248.1667,CHN.24.248.1667,35.28628952081442,115.41826636230697,1445222873.7847917
Porto,Portugal,PRT.15,PRT.15,41.229851341718444,-8.430724742620805,2331032780.5745425
Asansol,India,IND.35.577.2249,IND.35.577.2249,23.741902462172284,86.96353451571004,872436442.7294939
Lusaka,Zambia,ZMB.5.35,ZMB.5.35,-15.428025320693946,28.31128623832302,466743651.01775163
Changzhou,China,CHN.15.163.1116,CHN.15.163.1116,31.697053043188518,119.93435179177585,1835042979.9635518
Mosul,Iraq,IRQ.16.85,IRQ.16.85,36.039661912835534,42.90393574605823,4668475725.34595
Yekaterinburg,Russia,RUS.71.1944.2061,RUS.71.1944.2061,56.779830281579756,60.45604053762746,1160503711.5462914
Peshawar,Pakistan,PAK.5.17.70,PAK.5.17.70,33.98696190272978,71.55783043240805,1246512085.0982995
Mandalay,Burma,MMR.8.25.126,MMR.8.25.126,22.013184871451667,96.068103253184,158012807.11750218
Jamshedpur,India,IND.16.211.744,IND.16.211.744,22.584292700220434,86.4614354753527,3612695834.5648875
Mbuji-Mayi,Congo (Kinshasa),COD.5.20.71,COD.5.20.71,-6.137741331955037,23.583410535447154,246495562.1009726
Madura,India,IND.31.469.1890,IND.31.469.1890,9.834384382568889,78.06922078393946,309138764.0871512
Adana,Türkiye,TUR.1.13,TUR.1.13,36.95047512076324,35.39005052335591,1494981892.6465473
Sheffield,United Kingdom,GBR.1.86,GBR.1.86,53.4615652298031,-1.339925461748782,1646402620.1090775
Jabalpur,India,IND.20.279.1056,IND.20.279.1056,23.141365688978,80.09076273672903,2564344930.1308627
San José,Costa Rica,CRI.7.R54cd68900ed62324,CRI.7.R54cd68900ed62324,9.931487334514923,-84.106671860366,45786841.303777836
Panama City,Panama,,,,,
Nizhniy Novgorod,Russia,RUS.52.1289.1406,RUS.52.1289.1406,56.27836519613873,43.80391308068163,335386213.05950826
Chifeng,China,CHN.19.212.1408,CHN.19.212.1408,42.3449953766364,118.72704996428543,7007938475.125719
Duisburg,Germany,DEU.10.26.R3a586eb11020ed9e,DEU.10.26.R3a586eb11020ed9e,51.407102836778584,6.674841956634807,381877628.2291454
Munich,Germany,DEU.2.7.84,DEU.2.7.84,48.14366725722029,11.544373104396366,305010726.8717044
Huaiyin,China,CHN.15.164.1122,CHN.15.164.1122,33.492238087023146,118.9922362606805,345652374.35281837
Stockholm,Sweden,SWE.15,SWE.15,59.349304037271416,18.597742088625576,7173006630.953324
Makassar,Indonesia,IDN.26.337,IDN.26.337,-5.135292647894534,119.39171438055908,185183300.59512442
Rajkot,India,IND.12.142.541,IND.12.142.541,22.251399396417927,70.8963533040609,1057560648.5255455
Dhanbad,India,IND.16.197.729,IND.16.197.729,23.824367016048424,86.48591292072811,1630129252.8886802
Mudanjiang,China,CHN.11.108.758,CHN.11.108.758,44.58049498161688,129.61295916044887,1468390351.5632646
Geneva,Switzerland,CHE.R893aaca90ea4248b,CHE.R893aaca90ea4248b,46.54416336298922,6.703980126675637,5177481505.433895
Shiraz,Iran,IRN.6.63,IRN.6.63,29.426228066299746,52.71784582945434,9915323177.441181
Huzhou,China,CHN.32.336.2347,CHN.32.336.2347,30.764718559000652,120.18795174413425,1560847271.2629304
Beidao,China,CHN.5.40.257,CHN.5.40.257,34.38921095585035,105.93055451806379,5855172691.125205
Shuangshui,China,CHN.8.81.481,CHN.8.81.481,26.649947004644954,104.83328865384219,536617236.0821117
Düsseldorf,Germany,DEU.10.26.272,DEU.10.26.272,51.21728763634466,6.812981650584613,213470755.5352433
Gaozhou,China,CHN.6.51.308,CHN.6.51.308,22.01790452132064,110.92387091651057,3292998060.0624223
Sevilla,Spain,ESP.1.8,ESP.1.8,37.476465597257224,-5.585419348459632,14106959609.314428
Amritsar,India,IND.28.406.1582,IND.28.406.1582,31.619565690776227,75.00908829591485,1431411189.7647698
Vila Velha,Brazil,BRA.8.R6c3fb7e0782b50e9,BRA.8.R6c3fb7e0782b50e9,-20.341950057974447,-40.43066779352945,486649633.3321844
Vila Velha,Brazil,BRA.3.125.Re60064100711ef12,BRA.3.125.Re60064100711ef12,2.7745105763300697,-51.17057239910001,8644224453.337706
Almaty,Kazakhstan,KAZ.1.3,KAZ.1.3,43.23625283484768,76.90584621535915,256755474.74250394
Providence,United States,USA.40.2319,USA.40.2319,41.84206642194304,-71.47107080822597,1076053616.8407464
Warangal,India,IND.2.24.199,IND.2.24.199,17.866453325662995,79.52233632477484,2281985829.167009
Rosario,Argentina,ARG.21.450,ARG.21.450,-33.151129073951715,-60.692359436795,2011083543.6523886
Allahabad,India,IND.33.534.2131,IND.33.534.2131,25.44076372680241,81.62649650862467,829084367.4730817
Benin City,Nigeria,NGA.12.236,NGA.12.236,6.207381299186608,5.512779177042959,277266737.6002779
Jining,China,CHN.24.250.1679,CHN.24.250.1679,35.342287886240044,116.52422868005212,960401398.171594
Maceió,Brazil,BRA.2.68.Rf1dd30f0aa5567f9,BRA.2.68.Rf1dd30f0aa5567f9,-9.622823826551068,-35.76958610320244,129856301.27428441
Sofia,Bulgaria,BGR.5.40,BGR.5.40,42.63974076733497,23.387575363344027,1353200275.7743592
Abbottabad,Pakistan,PAK.5.13.52,PAK.5.13.52,34.108974220962004,73.21122357325626,1827958279.9020512
Benghazi,Libya,LBY.3,LBY.3,31.796259593474,20.253018531571094,12723158140.954172
Cilacap,Indonesia,IDN.11.113,IDN.11.113,-7.571156733213841,108.89511043575128,2315837358.4221015
Prague,Czechia,CZE.11,CZE.11,50.04862181780366,14.448892418427972,479995170.9343994
Glasgow,United Kingdom,GBR.3.154,GBR.3.154,55.83886426262381,-4.251536410733576,163125928.80595738
Leshan,China,CHN.27.281.1941,CHN.27.281.1941,29.607528324721894,103.77829767631735,828503783.5015709
Jacksonville,United States,USA.10.332,USA.10.332,30.368920987295823,-81.62114617543068,2190102417.9147243
Ouagadougou,Burkina Faso,BFA.13,BFA.13,12.338970367340949,-1.516567512921739,2872596959.706105
Ottawa,Canada,CAN.9.154.2722,CAN.9.154.2722,45.28652640048093,-75.78578941947224,2872502358.2748766
Adelaide,Australia,AUS.7.886,AUS.7.886,-34.92855707270252,138.57785603007966,19055245.618811253
Shangrao,China,CHN.16.183.1260,CHN.16.183.1260,28.41964920411824,117.93042199055816,2582633173.189185
Torreón,Mexico,MEX.7.235,MEX.7.235,25.24594193295833,-103.37767235779076,1317952150.0065825
Srinagar,India,IND.15.181.710,IND.15.181.710,33.91929593415361,74.77322796519185,1230926012.7789783
Bezwada,India,IND.2.12.87,IND.2.12.87,16.614304109086056,80.63564556026387,1072690269.1797194
Samara,Russia,RUS.67.1814.1931,RUS.67.1814.1931,53.03381242585164,50.09480397484863,3740860483.950867
Omsk,Russia,RUS.56.1409.1526,RUS.56.1409.1526,55.00275700104878,73.45439974796506,4080948900.5300355
Newcastle,Australia,AUS.4.257,AUS.4.257,-32.74207984680661,151.89262557133097,853704295.7466501
Yulin,China,CHN.7.77.458,CHN.7.77.458,22.685354708097258,109.97570693174109,2759270035.5496984
Namp’o,"Korea, North",PRK.10.144,PRK.10.144,38.735032770015884,125.454543142608,157117732.8280014
Xianyang,China,CHN.23.242.1610,CHN.23.242.1610,34.379548035786115,108.6807923293609,543442149.6625785
Cagayan de Oro,Philippines,PHL.Rfb548f506b985526,PHL.Rfb548f506b985526,8.720933851732354,124.76139941869431,3588065468.849802
Can Tho,Vietnam,VNM.1.5,VNM.1.5,10.095687873329705,105.51505898742943,1426256084.882499
Barquisimeto,Venezuela,VEN.13.122,VEN.13.122,10.156120717923786,-69.39273362673076,2844092358.331997
Helsinki,Finland,FIN.4.R53dd9b05bb65f044,FIN.4.R53dd9b05bb65f044,60.19577053354847,24.673219246111447,22519472218.717724
Kazan’,Russia,RUS.73.2045.2162,RUS.73.2045.2162,55.51105954327478,49.36834306267498,1713889870.4880311
Aurangabad,India,IND.21.309.1190,IND.21.309.1190,19.90479558512573,75.40471129597913,1657204852.7573276
Calgary,Canada,CAN.1.16.365,CAN.1.16.365,51.011046754505436,-114.10064833564836,716255364.2132295
Ciudad Nezahualcóyotl,Mexico,MEX.9.263,MEX.9.263,19.397660327568904,-99.06691851906635,36536801.98625805
Zürich,Switzerland,CHE.26,CHE.26,47.42900833672749,8.638686393360956,1753803229.6001072
Baoding,China,CHN.10.90.561,CHN.10.90.561,38.86889711181672,115.4357226541305,132700739.50771004
Zigong,China,CHN.27.294.2034,CHN.27.294.2034,29.307244637203187,104.79906495467768,820998183.332637
Sharjah,United Arab Emirates,ARE.7,ARE.7,25.214547959674157,55.76078408313328,2538941793.5185494
Yerevan,Armenia,ARM.4,ARM.4,40.167850293711595,44.515874416879214,284174775.88322765
Mogadishu,Somalia,SOM.3.10,SOM.3.10,2.080934343256299,45.41853614987989,185808852.58883396
Tbilisi,Georgia,GEO.12.69,GEO.12.69,41.69693919125298,44.81874887300518,237176895.44550005
Ankang,China,CHN.23.235.1543,CHN.23.235.1543,32.79152119258411,108.8551783606156,3620553817.4572196
Huambo,Angola,AGO.9.72,AGO.9.72,-12.806059398980391,15.775254775948431,2919519985.7569184
Ikare,Nigeria,NGA.29.R16654512973eeec0,NGA.29.R16654512973eeec0,7.503199412297588,5.832290133276505,672089317.7744428
Belgrade,Serbia,SRB.3.R5b0c16b54b824b7c,SRB.3.R5b0c16b54b824b7c,44.8562594346135,20.37717380950358,741199034.4425492
Salt Lake City,United States,USA.45.2799,USA.45.2799,40.6421000254165,-111.89344913841363,2077650451.6495152
Bhilai,India,IND.7.106.323,IND.7.106.323,21.161601914840315,81.32159967596368,3024030543.790229
Jinhua,China,CHN.32.338.2356,CHN.32.338.2356,29.11039857647873,119.61755103275256,321525733.23050356
Chelyabinsk,Russia,RUS.12.323.323,RUS.12.323.323,55.18306733416168,61.37228604716391,515251976.84133106
Natal,Brazil,BRA.20.3746,BRA.20.3746,-5.811021452223944,-35.23698109599129,169563135.17545688
Dushanbe,Tajikistan,TJK.4.52.R083b2835db485bac,TJK.4.52.R083b2835db485bac,38.54923285220977,68.75487077236602,239347851.1056217
Copenhagen,Denmark,DNK.3.32,DNK.3.32,55.70667314135067,12.428343424748258,992539597.136872
Changwon,"Korea, South",KOR.10.144,KOR.10.144,35.11021613945445,128.52466846707438,285090665.69964206
Zhuzhou,China,CHN.14.162.1111,CHN.14.162.1111,27.89925102147665,113.1492320899879,390298562.7579051
Suwon,"Korea, South",KOR.8.101,KOR.8.101,37.269222737550834,126.9898395944639,80154741.37589328
Nashville,United States,USA.43.2451,USA.43.2451,36.168342764224384,-86.75423602476428,1379222584.8727832
Vereeniging,South Africa,ZAF.2.100,ZAF.2.100,-26.58459131745214,27.951942596421702,1520619945.7138727
Xiangyang,China,CHN.13.144.981,CHN.13.144.981,32.10676683292768,112.15374771062763,3618664366.685259
Memphis,United States,USA.43.2511,USA.43.2511,35.20810293937969,-90.04889868782911,1994669843.8457644
Ulsan,"Korea, South",KOR.16.R6dcf4076f27a03d0,KOR.16.R6dcf4076f27a03d0,35.521936420033136,129.3578874886252,242711045.78263593
Amman,Jordan,JOR.2,JOR.2,31.690972678069624,36.212880500289856,7190381538.679295
Mizhou,China,CHN.24.257.1731,CHN.24.257.1731,36.004114836543785,119.38460255796433,2178055705.0544686
Richmond,United States,USA.47.2928,USA.47.2928,37.81491878789902,-76.98884491114357,670057959.8163198
Dublin,Ireland,IRL.6,IRL.6,53.409342117079035,-6.217024331391793,866404458.6224841
Edmonton,Canada,CAN.1.3.Rd9086cb08201d582,CAN.1.3.Rd9086cb08201d582,53.335920521359455,-114.15541242351095,16429927769.267838
Solapur,India,IND.21.335.1424,IND.21.335.1424,17.769740486977966,75.81577716225529,704665559.7254108
Rostov-na-Donu,Russia,RUS.63.1632.1749,RUS.63.1632.1749,47.29851818212944,39.78897295497227,1043332777.1663543
Dnipro,Ukraine,UKR.5.Rda01a072a80ca199,UKR.5.Rda01a072a80ca199,48.44534042716372,34.89373585537481,585942799.5020336
Xining,China,CHN.22.234.1542,CHN.22.234.1542,36.634178959735564,101.72229136060609,345073793.5485439
Zhangjiakou,China,CHN.10.100.708,CHN.10.100.708,40.73363729128162,114.91035314159647,440302027.5302146
Lille,France,,,,,
Ranchi,India,IND.16.212.746,IND.16.212.746,23.36337641923829,85.26086135668403,3861359881.0275507
Gaziantep,Türkiye,TUR.33.366,TUR.33.366,37.167614427500524,37.32909528400502,1453799288.27899
Monrovia,Liberia,LBR.11.47.207,LBR.11.47.207,6.3007755964563525,-10.76814150292262,146308799.03411913
São Luís,Brazil,BRA.10.1311.2704,BRA.10.1311.2704,-2.61627516249812,-44.30758312189846,808735380.9941587
Amsterdam,Netherlands,NLD.9.266,NLD.9.266,52.355255971569946,4.9112109733369795,201066289.35898802
Jerusalem,Israel,ISR.6,ISR.6,31.741510311030293,34.96519769008115,563346659.4263823
New Orleans,United States,USA.19.1148,USA.19.1148,30.04565953935398,-89.88343351629796,908552943.452758
Guatemala City,Guatemala,GTM.7.Rff3263728f75f1fb,GTM.7.Rff3263728f75f1fb,14.616158289116186,-90.52306377149208,22486569.193632893
Zhuhai,China,CHN.6.63.370,CHN.6.63.370,22.109245560442844,113.74920594069405,491360107.243786
Florianópolis,Brazil,BRA.24.4447,BRA.24.4447,-27.593391444309503,-48.493609044756994,446304473.0844698
Port Elizabeth,South Africa,ZAF.1.56,ZAF.1.56,-33.869576777176604,25.56377313307869,1315631737.9915562
Jiamusi,China,CHN.11.106.748,CHN.11.106.748,46.72700396866796,130.22315813724208,775418263.0234331
Port Harcourt,Nigeria,NGA.33.696,NGA.33.696,4.808025660214228,6.962488915339702,281766751.8431791
Raleigh,United States,USA.34.1985,USA.34.1985,35.8564681441832,-78.66304799897938,2243812589.635371
Ufa,Russia,RUS.7.211.211,RUS.7.211.211,54.77359606348864,55.814576194481894,2215815139.1737533
Hengyang,China,CHN.14.152.1037,CHN.14.152.1037,26.8822328213317,112.62125927032976,383529036.7398913
Benxi,China,CHN.18.197.1336,CHN.18.197.1336,41.27687345368533,123.76935414412436,1333562667.588068
Louisville,United States,USA.18.1048,USA.18.1048,38.19152459923726,-85.76868813243159,1020094262.3418797
Haifa,Israel,ISR.3,ISR.3,32.63308422010194,35.00357584510304,864740377.0151377
Medina,Saudi Arabia,SAU.5,SAU.5,24.849487448920648,39.030209629263766,144698160773.88855
Bucaramanga,Colombia,COL.27.863,COL.27.863,7.1346266762150305,-73.15384785290094,192185501.0759102
Maracay,Venezuela,VEN.4.R5e1f85e54760b9ab,VEN.4.R5e1f85e54760b9ab,10.227515249468901,-67.41964296120723,2260601172.0258193
Rotterdam,Netherlands,NLD.14.R94ac5690f99564a9,NLD.14.R94ac5690f99564a9,51.86939553009585,4.320869085845956,832449856.4198217
Homs,Syria,SYR.9.34,SYR.9.34,34.57414858691229,36.83126796821317,4791174806.287692
Cologne,Germany,DEU.10.27.288,DEU.10.27.288,50.947470358150284,6.945225649819979,395522443.519716
Qinhuangdao,China,CHN.10.96.648,CHN.10.96.648,39.946159435420014,119.6172223210275,397911084.88428426
Fès,Morocco,MAR.3.7.Ra476720293202cbb,MAR.3.7.Ra476720293202cbb,34.025786255154436,-4.991649862312689,38525087.825753346
N’Djamena,Chad,TCD.17.50,TCD.17.50,11.687334542578958,15.110535315304373,4941302991.168471
Tegucigalpa,Honduras,HND.8,HND.8,14.255281658108547,-87.23796403795463,8656755328.92743
Kingston,Jamaica,JAM.3,JAM.3,17.95930270841567,-76.76868775096186,14739266.63813082
Nay Pyi Taw,Burma,MMR.8.29.144,MMR.8.29.144,19.70984013699964,96.36521192401129,2122064246.2299838
Djibouti,Djibouti,DJI.3.5,DJI.3.5,11.537686291599002,43.06156795880328,781953199.379307
Managua,Nicaragua,NIC.12,NIC.12,12.206600032914908,-86.3239691278517,4615006225.959028
Niamey,Niger,NER.5.18.50,NER.5.18.50,13.523265140638912,2.116906854474326,395458071.2390491
Tirana,Albania,ALB.R78655fa8b5193657,ALB.R78655fa8b5193657,40.55656654364771,20.03079977853871,13077501325.429836
Kathmandu,Nepal,NPL.1.1.3,NPL.1.1.3,27.703255674268913,85.3246713118122,418353743.4987418
Ulaanbaatar,Mongolia,MNG.21.308,MNG.21.308,47.85823580660009,107.37051775742907,4500151191.425646
Kigali,Rwanda,RWA.9.121,RWA.9.121,-1.9736713397088474,30.04739838465479,50327550.12604494
Bishkek,Kyrgyzstan,KGZ.3,KGZ.3,42.75952127685325,74.96743561198448,17241323879.75094
Oslo,Norway,NOR.12.288,NOR.12.288,59.92745455330829,10.7206428716621,461405072.635177
Bangui,Central African Republic,CAF.2.3,CAF.2.3,4.372782380652277,18.586033580608817,88834395.18511039
Freetown,Sierra Leone,SLE.4.14.153,SLE.4.14.153,8.458987059394662,-13.26485191738359,95791127.90644978
Islamabad,Pakistan,PAK.4.9.45,PAK.4.9.45,33.65208821174767,73.04755022224025,847970907.7732545
Cotonou,Benin,BEN.10.56,BEN.10.56,6.43817807658654,2.5125294450833913,150119244.36037847
Vientiane,Laos,LAO.13,LAO.13,18.140845190879652,102.56340968561264,3644416385.216782
Riga,Latvia,LVA.3.14,LVA.3.14,56.974899787392246,24.358537365559723,3364766306.396368
Nouakchott,Mauritania,MRT.10.32,MRT.10.32,18.132947491412036,-16.02661096895634,1546033817.8201346
Muscat,Oman,OMN.8,OMN.8,23.400879775565905,58.70142891187661,4467901885.661154
Ashgabat,Turkmenistan,TKM.1,TKM.1,37.511288633847286,59.22363241486279,98866403827.28261
Zagreb,Croatia,HRV.4.80,HRV.4.80,45.787365868369264,15.956739406508227,650754118.0586791
Sarajevo,Bosnia And Herzegovina,BIH.2.7.Race71b0d962cff6f,BIH.2.7.Race71b0d962cff6f,43.865809611600255,18.310350498224427,343506574.22201765
Chisinau,Moldova,MDA.12,MDA.12,47.01441602328264,28.893445440454357,586332482.3149285
Lilongwe,Malawi,MWI.10,MWI.10,-13.983385046526248,33.69920442799193,6343701863.498165
Asmara,Eritrea,ERI.1.1,ERI.1.1,15.319178486941752,38.91838585936612,104612011.50551032
Abu Dhabi,United Arab Emirates,ARE.1,ARE.1,24.320893029476363,53.65671746001257,67765076457.367355
Port Louis,Mauritius,MUS.Rb4955ae83edc3e08,MUS.Rb4955ae83edc3e08,-20.282777258845677,57.48274308186969,239774589.72080237
Libreville,Gabon,GAB.1,GAB.1,0.2856264197361186,9.785339835273495,19991193865.198624
Manama,Bahrain,BHR.Rf0a1304585646a1c,BHR.Rf0a1304585646a1c,26.17454040159077,50.505011114642635,278141910.1235337
Vilnius,Lithuania,LTU.10.48,LTU.10.48,54.745926771652684,25.304844783914156,2503582854.0817275`
);

const numbers = ["lng", "lat", "area"];
cities500kArray.forEach((row) => {
  numbers.forEach((number) => {
    row[number] = parseFloat(row[number]);
  });
});
const cities500k = cities500kArray.reduce((acc, row) => {
  return {
    ...acc,
    [row.Region_ID]: row,
  };
}, {});

export default cities500k;
