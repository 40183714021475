import { useCountryData } from "../../lib/useCountryData";
import MetricDetailSparkline from "../common/MetricDetailSparkline";
import "./CountryTooltipWIthMetric.scss";

function SparklineRegionWrapper(props) {
  if (!props.featureYearData) {
    return null;
  }
  return <MetricDetailSparkline {...props} data={props.featureYearData} />;
}
function SparklineCountryWrapper(props) {
  const { metric, scenario, feature } = props;

  const allYearData = useCountryData({
    metric,
    scenario,
    country: feature.properties.code3,
  });
  if (!allYearData) {
    return null;
  }
  return <MetricDetailSparkline {...props} data={allYearData} />;
}

function SparklineWrapper(props) {
  const { featureType } = props;
  if (featureType === "regions") {
    return <SparklineRegionWrapper {...props} />;
  } else {
    return <SparklineCountryWrapper {...props} />;
  }
}

export function CountryTooltipWithMetric({
  hoveredId,
  height,
  metricFormatter,
  topCityFeatures,
  metric,
  colorScale,
  extent,
  yearIndex,
  scenario,
  isMobile,
  country,
  regionNames,
}) {
  const {
    id,
    mousePosition,
    eventPosition,
    type,
    value,
    feature,
    featureYearData,
    featureIndex,
  } = hoveredId;

  const flipX = eventPosition.x > window.innerWidth / 2;
  const flipY =
    mousePosition.y > height / 2 || eventPosition.y > window.innerHeight / 2;

  if (value == null) {
    debugger;
  }
  let label = id;
  if (type === "countries") {
    label = feature.properties.name;
  }
  let hide = false;
  if (
    type === "regions" &&
    topCityFeatures.includes(feature) &&
    feature.properties.topCity
  ) {
    label = `${feature.properties.topCity.city}, ${feature.properties.topCity.country}`;
  } else if (type === "regions") {
    // hide = true
    if (regionNames[id]) {
      label = `${regionNames[id]}, ${country.name}`;
    } else {
      label = `Region #${featureIndex + 1} in ${country.name}`;
    }
  }

  const left = isMobile ? 0 : mousePosition.x + (flipX ? -3 : 3);
  const top = mousePosition.y + (flipY ? -3 : 3);
  const transform = isMobile
    ? "translateY(-100%)"
    : `${flipX ? "translateX(-100%)" : "translateX(0)"} ${
        flipY ? "translateY(-100%)" : "translateY(0)"
      }`;

  return (
    <>
      {!hide && (
        <div
          className="tooltip-with-metric"
          style={{
            left,
            top,
            transform,
          }}
        >
          <div className="tooltip-value-wrapper">
            <div className="tooltip-value">{metricFormatter(value)}</div>
            <div className="tooltip-label">{label}</div>
          </div>
          {type === "countries" && (
            <div className="instruction">
              Click to select country or territory
            </div>
          )}
          <div className="sparkline">
            <SparklineWrapper
              metric={metric}
              colorScale={colorScale}
              width={280}
              extent={extent}
              yearIndex={yearIndex}
              featureType={type}
              feature={feature}
              scenario={scenario}
              featureYearData={featureYearData}
              metricFormatter={metricFormatter}
            />
          </div>
        </div>
      )}
    </>
  );
}
