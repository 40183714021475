import closeTooltipIcon from "../../images/closeTooltipIcon.svg";
import chevronRed from "../../images/chevron_red-left.svg";
import "./CountryTooltip.scss";

export function CountryTooltip({
  tooltipViaSelect,
  xy,
  country,
  setExploreModeCountry,
  navigateToCountry,
  navigateToRisk,
  value,
  isMobile,
}) {
  const transform = `translate(${xy.x}px, ${xy.y}px) ${
    isMobile ? "translateX(-50%)" : ""
  }`;
  return (
    <div
      className="globe-tooltip"
      style={{
        transform,
        zIndex: tooltipViaSelect ? 100 : 60,
        pointerEvents: tooltipViaSelect ? "auto" : "none",
      }}
    >
      <div className="globe-tooltip__header">
        <div className="globe-tooltip__countryName">
          <div className="globe-tooltip__countryName__value">{value}</div>
          <div className="globe-tooltip__countryName__name">
            {" "}
            {country.name}
          </div>
        </div>
        {tooltipViaSelect && (
          <img
            src={closeTooltipIcon}
            alt="close-tooltip"
            onClick={() => setExploreModeCountry("")}
            className="close-tooltip"
          />
        )}
      </div>
      {tooltipViaSelect && (
        <div className="globe-tooltip__details">
          <div className="clickToSeeDetails" onClick={navigateToCountry}>
            <span className="animatedLink ">COUNTRY INSIGHTS</span>
            <img src={chevronRed} alt="go to country insights" />
          </div>
          <div className="clickToSeeDetails" onClick={navigateToRisk}>
            <span className="animatedLink ">COUNTRY COMPARISON</span>
            <img src={chevronRed} alt="go to country comparison" />
          </div>
        </div>
      )}
    </div>
  );
}
