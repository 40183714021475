import toggleIcon from "../../images/toggle.svg";
import infoIcon from "../../images/info_icon.svg";
import classNames from "classnames";
import "./ShowBordersButton.scss";

export const ShowBordersButton = ({ setDisplayBorders, displayBorders }) => {
  const borderDisclaimer =
    "The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of the UNDP concerning the legal status of any country, territory, city or area or of its authorities or concerning the delimitation of its frontiers or boundaries.";

  return (
    <div
      className="display-borders"
      onClick={(e) => setDisplayBorders((d) => !d)}
    >
      Show borders
      <span className={classNames({ enabled: displayBorders })}>
        <img src={toggleIcon} alt={borderDisclaimer} />
      </span>
      <img
        src={infoIcon}
        style={{ width: "0.8em", marginLeft: "0.2em" }}
        data-tip={borderDisclaimer}
        alt={borderDisclaimer}
      />
    </div>
  );
};
