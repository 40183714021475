import "./WhatIsHCH.scss";
import { Link } from "react-router-dom";
const A = (props) => {
  return (
    <a
      {...props}
      className="animatedLink"
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

const faq = [
  [
    "What is the precision of the information?",
    "The Human Climate Horizons platform provides insights into the direction and magnitude of changes in the climate, like the number of extremely hot days each year and the impact of those changes on human welfare. The estimates shown in each category are an annual average, representing what may happen under a selected scenario of future emissions in a single year within the 20-year time horizon. The researchers calculate a range of possible outcomes for each category, from the most to the least likely. The results presented are central point estimates from that range. For temperature data and sea level rise data and impacts, the median outcome is shown. For mortality, energy, and labor impact data, the mean is shown.",
  ],

  [
    "Is this a prediction of the future?",
    "No, the information provided on the platform should not be interpreted as a prediction of future impacts at a particular place and time. Instead, the intention is to estimate the influence of climate change on human welfare and the relative magnitude of impacts associated with different scenarios of greenhouse gas emissions trends. The research conducted by the Climate Impact Lab estimates only some of the human welfare impacts of climate change, providing an incomplete picture of the risks associated with climate change.",
  ],

  [
    "What drives the emissions scenarios?",
    "The Representative Concentration Pathways (RCPs) and Shared Socioeconomic Pathways (SSPs) shown on this platform are commonly used in the climate modelling community to explore future emissions, concentrations of atmospheric greenhouse gases, and the resulting changes in climate hazards and impacts. Each scenario ends in numbers (e.g., 4.5, 8.5) that define total radiative forcing in  2100, a measure of how atmospheric greenhouse gas concentrations impact Earth’s energy balance, expressed in Watts per square meter. Each RCP provides a pathway of land use, emissions, and atmospheric concentrations to that level of radiative forcing. The SSP-based scenarios, which were used in the Intergovernmental Panel on Climate Change's most recent assessment, integrate socioeconomic assumptions about population, economic growth, and other factors into future forcing scenarios. Compared to the older RCP-based scenarios, the new SSP-based scenarios cover a broader mix of greenhouse gases, resulting in slight differences in the end-of-century warming associated with each pathway.",
  ],

  [
    "Which emissions scenario is most likely?",
    "This question is the subject of intense scrutiny. The answer is uncertain and will be decided by factors such as climate policy. However, comparing different scenarios offers a window into how choices today are likely to affect our future. Most characteristic of current trends are the Intermediate (SSP2-4.5) and Moderate (RCP 4.5) scenarios.",
  ],

  [
    "How are the beneficial impacts of climate change considered?",
    "In each category of research presented on the platform, the Climate Impact Lab has quantified both damaging and beneficial impacts of climate change. Overall, the damaging impacts of climate change substantially outweigh the beneficial effects. For instance, the projected increase in death rates caused by warmer temperatures is estimated to be much larger than the projected decrease in deaths due to declines in cold days.",
  ],

  [
    "Do the estimated impacts consider adaptation to climate change?",
    <>
      <>
        Yes, the Climate Impact Lab compiles large historical datasets from
        around the world in each category and matches them to historical weather
        conditions. These globally representative collections of historical
        outcome data and daily climate data provide a comprehensive way to study
        the relationship between climate and the category of interest all over
        the world. It allows the researchers to account for varying levels of
        adaptation across locations by directly examining how the responses vary
        as people get richer or, for example, experience more hot days.
      </>
      <div style={{ marginTop: 16 }}>
        A few limitations are worth noting. Climate-driven migration away from
        impacted areas is not considered in the modeling. For instance, the sea
        level rise impacts do not account for potential relocation strategies
        away from coastlines. Additionally, existing and future coastal
        protections, such as seawalls, dams, or levees, are not directly
        considered, nor are a broader array of protection options, such as
        mangrove restoration and other nature-based solutions.
      </div>
    </>,
  ],

  [
    "What assumptions do you make about future socioeconomic trends, like population and income growth?",
    <>
      five SSPs envisions a different baseline scenario for the economic future
      of the planet over the 21st century in the absence of climate impacts. For
      sea level rise and its impacts, the Human Climate Horizons platform
      presents projections based on three scenarios from the Sixth Assessment
      Report of the Intergovernmental Panel on Climate Change: SSP1-2.6, a
      sustainability-focused pathway; SSP2-4.5, a middle-of-the-road pathway;
      and SSP5-8.5, a fossil-fueled development pathway (see{" "}
      <A href="https://www.sciencedirect.com/science/article/abs/pii/S0959378015000060">
        O’Neill et al., 2017
      </A>{" "}
      for more narrative description). For temperature and its impacts,
      theplatform presents information based on SSP3, which shows the highest
      population growth and the lowest global Gross Domestic Product (GDP)
      growth of the five scenarios and tracks most closely with recent
      historical observations. The SSP3 population and income projections are
      based on data from the{" "}
      <A href="https://previous.iiasa.ac.at/web/home/research/researchPrograms/Energy/SSP_Scenario_Database.html">
        International Institute for Applied Systems Analysis SSP Database
      </A>
      .
    </>,
  ],
];

export default function WhatIsHCH(props) {
  return (
    <div className="WhatIsHCH">
      <div className="breadcrumbs" style={{ maxWidth: "1100px" }}>
        <Link to="/">Home</Link>
        <span>/</span>
        <span>What is HCH</span>
      </div>
      <div className="headerContainer">
        <h2 className="pageHeaderFont">WHAT IS Human Climate Horizons?</h2>
      </div>
      <div className="content">
        <p>
          <A href="https://hdr.undp.org/human-climate-horizons-data-and-insights-platform">
            Download press release
          </A>
        </p>
        <p>
          <strong>Human Climate Horizons (HCH)</strong> is a data and insights
          platform providing localized information on future impacts of climate
          change across several dimensions of human development and human
          security. It is open access and scalable digital public good – a
          window to possible futures – fed by an evolving stream of
          multidisciplinary frontier research. It is the result of joint work of
          the{" "}
          <strong>
            <A href="https://impactlab.org/">Climate Impact Lab</A>
          </strong>{" "}
          and the{" "}
          <A href="https://hdr.undp.org/">
            <strong>UNDP’s</strong> Human Development Report Office
          </A>
          .
        </p>
        <p>
          Providing hyperlocal coverage for more than 24,000 regions worldwide,
          multiple  policy scenarios and time horizons through the end of 21st
          century, HCH provides everyone with empirically grounded data and
          information on the potential human costs of climate change, so
          everyone can play a role in choosing the path forward and helping to
          design adaptation and accelerate mitigation.
        </p>
        <p>
          In the latest release, HCH brings forward the effects of climate
          change on coastal land and flooding. This builds upon available
          research and data on climate change’s effect on mortality, labor, and
          energy demand. Additional impacts are forthcoming, including research
          on climate change’s potential to disrupt food production systems.
        </p>
        <p>
          <strong>Acknowledgements</strong>
        </p>
        <p>
          The Climate Impact Lab extends its sincere gratitude to King
          Philanthropies for its financial support.
        </p>
        <p>
          The Human Development Report Office extends its sincere gratitude to
          the Government of Japan for its ongoing support.
        </p>
        <p>
          {" "}
          For questions and feedback, please get in touch with us at{" "}
          <A href="mailto:hdro.web@undp.org">hdro.web@undp.org</A>
        </p>

        <div className="video">[Space To Insert Video]</div>

        <h2>UNDERSTANDING DATA</h2>
        <p>
          The Climate Impact Lab’s approach to estimating the effects of climate
          change on humans begins with collecting globally representative,
          detailed datasets of socioeconomic outcomes, such as historical death
          records and electricity consumption data, and matching them with
          high-resolution historical data on local climate conditions.
          Statistical models are built to compare a location’s response to daily
          weather over a long stretch of time in both hot years and cold years,
          making it possible to understand both the ways in which people
          experience temperature extremes on a day-to-day basis and how they
          respond to cumulative exposure to more frequent hot temperatures or
          fewer frigid days over time. Importantly, this analysis uses a
          flexible set of controls that account for other local characteristics
          (these are known in econometrics as “fixed effects”) that are unique
          to a given population or place.
        </p>
        <p>
          Climate Impact Lab researchers study how sensitive a place is to
          changing weather conditions, tracking how people adapt over time as
          incomes rise or fall and as the climate gradually changes. Many parts
          of the globe do not collect data on key outcomes of interest, such as
          human health indicators, leaving gaps in historical evidence,
          particularly in poorer places. By employing methods developed by the
          Climate Impact Lab and published in the world’s top peer-reviewed
          scientific and economic research journals, the Climate Impact Lab’s
          researchers estimate climate change’s impact on livelihoods worldwide
          based on real-world relationships in the places where data are
          available.
        </p>
        <p>
          The projected impacts estimate how outcomes might evolve, given the
          amount of climate change projected in the future, compared to an
          alternate future with no climate change. Extending the procedure year
          after year allows the Climate Impact Lab’s researchers to consider the
          direction and strength with which climate change will nudge society
          over longer time horizons. However, there are many unknowns about the
          future, such as how quickly the population will grow, how fast
          technology will change, or how much the climate will warm. This
          research on the effects of future climate change explicitly does not
          attempt to forecast the future. Instead, the Climate Impact Lab
          meticulously tracks and reports these sources of uncertainty and uses
          a risk management framework to communicate our findings. This involves
          assigning probabilities to different possible outcomes and exploring
          various scenarios of possible future emissions and socioeconomic
          development.
        </p>
        <p>
          <a
            href={`${process.env.PUBLIC_URL}/HCH Data Methodology.pdf`}
            target="_blank"
            rel="noreferrer"
            className="callToAction"
          >
            Read the Methodology
          </a>
        </p>

        <h2>DOWNLOAD DATA</h2>

        <h3 className="highlight">HAZARD DATA</h3>

        <p>
          Hazard data includes: Average annual daily mean temperature, Daily
          maximum temperatures over 95F, Daily minimum temperatures under 32F,
          and local relative Sea level rise projections relative to a 1994-2014
          baseline.
        </p>

        <p>
          Sea level rise is projected for three shared socioeconomic pathways
          (SSPs), considering only processes for which projections can be made
          with medium confidence, as defined by the Intergovernmental Panel on
          Climate Change (IPCC, Box 1.1). Temperature data is projected for two
          representative concentration pathways (RCPs) from the IPCC’s Fifth
          Assessment Report. 
        </p>

        <h3 className="highlight">SEA LEVEL RISE DATA</h3>

        <p>
          <strong>Hazard Data:</strong>
        </p>
        <p>
          <A
            href={`https://www.dropbox.com/scl/fi/5ljug8jb7s0hhmk3orakz/SLR-Hazard-Data.zip?rlkey=yzy4n27vfjlub60qr4yhw6pbm&dl=0`}
          >
            Download link
          </A>{" "}
          with:
        </p>
        <ul>
          <li>
            IR-Level impact regions (shapefile*)
            <ul style={{ width: "auto", margin: 0 }}>
              <li>
                *500kcities can be used to match cities with population over
                500,000 to corresponding IR.
              </li>
            </ul>
          </li>
          <li>Countries national averages (ISO Code HierID matching)</li>
          <li>Global </li>
        </ul>
        <p>
          <strong>Impact Data:</strong>
        </p>
        <p>
          Impact data includes Land Inundation (percent of region and total
          area), and Flood Exposure.
        </p>
        <p>
          <A
            href={`https://www.dropbox.com/scl/fi/5wnv11djjcxy4pb3lw8az/SLR-Impacts-Data.zip?rlkey=efnvjv2l33qvzpll3i4k32gbu&dl=0`}
          >
            Download link
          </A>
        </p>
        <p>
          For more information, download detailed instructions with indicator
          information [to come].
        </p>

        <h3 className="highlight">TEMPERATURE DATA</h3>
        <p>
          <strong>Geographical levels:</strong>
        </p>

        <ul>
          <li>
            <A href="https://www.dropbox.com/s/cxlca2lvqim0orl/HAZARDS-TEMP%20IR-level.zip?dl=0">
              IR-Level
            </A>{" "}
            impact regions (
            <A href="https://www.dropbox.com/s/vhvzypjx49yet0o/high-res-regions-simplified.topo.json?dl=0'">
              shapefile
            </A>
            *)
            <ul style={{ width: "auto", margin: 0 }}>
              <li>
                *
                <A href="https://www.dropbox.com/s/wjtzfp3ceeqf4y2/500kcities_IRregions.csv?dl=0">
                  500kcities
                </A>{" "}
                can be used to match cities with population over 500,000 to
                corresponding IR.
              </li>
            </ul>
          </li>
          <li>
            <A href="https://www.dropbox.com/s/jmlhm9r67yaib2m/HAZARDS-TEMP%20Countries.zip?dl=0">
              Countries
            </A>{" "}
            national averages (
            <A href="https://www.dropbox.com/s/5gq9ng9t9l0mf9w/ISOcodes_CIL.csv?dl=0">
              ISO Code HierID
            </A>{" "}
            matching)
          </li>
          <li>
            <A href="https://www.dropbox.com/s/l80qcqjn76a5k9c/HAZARDS-TEMP%20Global.zip?dl=0">
              Global
            </A>{" "}
            (tas-annual{" "}
            <A href="https://www.dropbox.com/s/6rbcpsiyevm5lds/fair_rcp45_ssp_global_temperature_responses_1765-2100.csv?dl=0">
              RCP 4.5
            </A>
            , tas-annual{" "}
            <A href="https://www.dropbox.com/s/pbueidffc8jz576/fair_rcp85_ssp_global_temperature_response_1765-2100.csv?dl=0">
              RCP 8.5
            </A>
            ; temporal level is annual for each year 1765-2100)
          </li>
        </ul>

        <p>
          <strong>Impact Data:</strong>
        </p>

        <p>
          Impact data includes Mortality (change in death rates), Energy (per
          capita change in electricity use and in other fuels use), Labor
          (high-risk sectors lost work time, and low-risk sectors lost work
          time).
        </p>

        <p>Geographical levels:</p>
        <ul>
          <li>
            <A href="https://www.dropbox.com/s/qpxg51eoxg4lizy/IMPACTS%20country-level.zip?dl=0">
              Countries
            </A>{" "}
            national averages (
            <A href="https://www.dropbox.com/s/5gq9ng9t9l0mf9w/ISOcodes_CIL.csv?dl=0">
              ISO Code HierID
            </A>{" "}
            matching)
          </li>
          <li>
            <A href="https://www.dropbox.com/s/xdcp4mn3oxwj358/IMPACTS%20500k%20cities.zip?dl=0">
              500k cities
            </A>{" "}
            data (Q50 only) -{" "}
            <A href="https://www.dropbox.com/s/wjtzfp3ceeqf4y2/500kcities_IRregions.csv?dl=0">
              500kcities
            </A>{" "}
            can be used to match cities with population over 500,000 to
            corresponding IR.
          </li>
          <li>
            <A href="https://www.dropbox.com/s/1i5wiv9b6jhxz6t/IMPACTS%20regions.zip?dl=0">
              All impact regions
            </A>
          </li>
        </ul>
        <p>
          For more information,{" "}
          <A
            href={`${process.env.PUBLIC_URL}/HCH_Data_Downloads_instructions.pdf`}
          >
            download detailed instructions with indicator information
          </A>
          .
        </p>

        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        {faq.map((q, i) => (
          <div key={i}>
            <strong className="highlight">{q[0]}</strong>
            <p>{q[1]}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
