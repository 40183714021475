import { Link } from "react-router-dom";
import "./CTA.scss";
import chevronRed from "../../images/chevron_red-left.svg";

export function CTA({ href, label }) {
  return (
    <Link to={href} className="call-to-action">
      <div className="call-to-action__label">{label}</div>
      <img src={chevronRed} alt="go-to" />
    </Link>
  );
}
