import { useLoader } from "@react-three/fiber"
import { TextureLoader, DoubleSide } from "three"
import nightSky from './images/night-sky.png'
import { Billboard } from '@react-three/drei'
export default function BackgroundMesh(props) {
  const [backgroundImage] = useLoader(TextureLoader, [nightSky])

  return (
    <Billboard>
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[500, 64, 64]} />
        <meshBasicMaterial side={DoubleSide} attach="material" map={backgroundImage} />
      </mesh>
    </Billboard>
  )
}
