import { METRICS_CONFIGS, METRIC_TYPE } from "../../lib/metric-constants";
import { groupBy, keys, values } from "lodash";
import Select from "react-select";
import { getDropdownMetricCustomStyle } from "../../lib/dropdowns";
import classNames from "classnames";
import { getMetricLabel } from "../../lib/metric-utils";
import { useState } from "react";
import "./ScenarioDropdown.scss";
import "./MetricDropdown.scss";

const Option = ({ onClick, value, typeOption, isSelected, label }) => {
  return (
    <div
      className={classNames({
        "selection-item--label": typeOption === "labelType",
        "selection-item--option": typeOption === "option",
        "selection-item--selected": isSelected,
      })}
      onClick={() => {
        if (value) {
          onClick(value);
        }
      }}
    >
      {label}
    </div>
  );
};

export function MetricDropdown({ selectedMetric, onChange }) {
  const metrics = METRICS_CONFIGS.map((metric) => ({
    group: metric.group,
    type: metric.type,
    label: getMetricLabel(metric) + " - " + metric.label,
    value: metric.key,
  }));
  const grouppedValues = groupBy(metrics, (d) => d.group);
  const options = keys(grouppedValues).map((group) => {
    const metricByGroup = grouppedValues[group];
    const metricByType = groupBy(metricByGroup, (d) => d.type);
    const optionsType = keys(metricByType).flatMap((type) => {
      const optionType = {
        label: type + " Data",
        value: type,
        typeOption: "labelType",
        isSelected: false,
      };
      const options = metricByType[type].map((metric) => {
        const isSelected = metric.value === selectedMetric;
        return {
          ...metric,
          label: metric.label,
          typeOption: "option",
          isSelected,
        };
      });
      return [optionType, ...options];
    });
    return { label: group, options: optionsType };
  });

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  const selectedMetricConfig = METRICS_CONFIGS.find(
    (d) => d.key === selectedMetric
  );

  const placeholder = getMetricLabel(selectedMetricConfig);

  return (
    <div className="metric-dropdown dropdown-wrapper">
      <div className="ui-label" style={{ color: "white" }}>
        DISPLAY DATA
      </div>
      <div className={classNames("dropdown")}>
        <Select
          options={options}
          placeholder={placeholder}
          menuIsOpen={menuIsOpen}
          onMenuOpen={openMenu}
          onBlur={closeMenu}
          onMenuClose={closeMenu}
          styles={getDropdownMetricCustomStyle()}
          isSearchable={false}
          components={{
            Option: ({ value, data }) => {
              return (
                <Option
                  onClick={(metric) => {
                    if (!values(METRIC_TYPE).includes(metric)) {
                      closeMenu();
                      onChange(metric);
                    }
                  }}
                  value={value}
                  typeOption={data.typeOption}
                  isSelected={data.isSelected}
                  label={data.label}
                ></Option>
              );
            },
          }}
        />
      </div>
    </div>
  );
}
