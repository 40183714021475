export const paddingLR = 48;

export const years = ["2020-2039", "2040-2059", "2080-2099"];
export const yearLabels = [
  "2020-2039 (next decades)",
  "2040-2059 (midcentury)",
  "2080-2099 (end of century)",
];

export const linechartYearLabels = [
  "Historic",
  "Next decades",
  "Midcentury",
  "End of century",
];

export const temperatureUnits = [
  {
    label: "°F",
    value: "f",
  },
  {
    label: "°C",
    value: "c",
  },
];
export const LANDLOCK_FILL = "#E6F3F2";
export const defaultTemperatureUnit = "c";
export const temperatureUnitStorageKey = "temperatureUnit";

export const COUNTRIES_TO_SHOW_ICONS_FOR = [
  "ALA",
  "ASM",
  "AIA",
  "ATG",
  "ABW",
  "BRB",
  "BMU",
  "BES",
  "BVT",
  "IOT",
  "VGB",
  "CYM",
  "MAC",
  "CXR",
  "CCK",
  "COM",
  "COK",
  "CUW",
  "DMA",
  "PYF",
  "ATF",
  "GIB",
  "GRD",
  "GLP",
  "GUM",
  "GGY",
  "HMD",
  "IMN",
  "JEY",
  "KIR",
  "MDV",
  "MLT",
  "MHL",
  "MTQ",
  "MYT",
  "FSM",
  "MCO",
  "MSR",
  "NRU",
  "NCL",
  "NIU",
  "NFK",
  "MNP",
  "PLW",
  "PCN",
  "REU",
  "BLM",
  "SHN",
  "KNA",
  "LCA",
  "MAF",
  "SPM",
  "VCT",
  "WSM",
  "STP",
  "SYC",
  "SLB",
  "SGS",
  "TLS",
  "TKL",
  "TON",
  "TTO",
  "TCA",
  "TUV",
  "UMI",
  "VIR",
  "WLF",
];

export const MARKER_SIZE = [27 / 2, 45 / 2];
export const LOLLIPOP_RADIUS = 3;
