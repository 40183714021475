import { csvParse } from 'd3-dsv'

const data = csvParse(`ISO,Countryname
AFG,Afghanistan
ALB,Albania
DZA,Algeria
AGO,Angola
ATG,Antigua and Barbuda
ARG,Argentina
ARM,Armenia
AUS,Australia
AUT,Austria
AZE,Azerbaijan
BHS,Bahamas
BHR,Bahrain
BGD,Bangladesh
BRB,Barbados
BLR,Belarus
BEL,Belgium
BLZ,Belize
BEN,Benin
BTN,Bhutan
BOL,Bolivia (Plurinational State of)
BIH,Bosnia and Herzegovina
BWA,Botswana
BRA,Brazil
BRN,Brunei Darussalam
BGR,Bulgaria
BFA,Burkina Faso
BDI,Burundi
KHM,Cambodia
CMR,Cameroon
CAN,Canada
CAF,Central African Republic
TCD,Chad
CHL,Chile
CHN,China
COL,Colombia
COM,Comoros
COG,Congo
COD,Congo (Democratic Republic of the)
CRI,Costa Rica
CIV,Côte d'Ivoire
HRV,Croatia
CUB,Cuba
CYP,Cyprus
CZE,Czechia
DNK,Denmark
DJI,Djibouti
DMA,Dominica
DOM,Dominican Republic
ECU,Ecuador
EGY,Egypt
SLV,El Salvador
GNQ,Equatorial Guinea
ERI,Eritrea
EST,Estonia
SWZ,Eswatini (Kingdom of)
ETH,Ethiopia
FJI,Fiji
FIN,Finland
FRA,France
GAB,Gabon
GMB,Gambia
GEO,Georgia
DEU,Germany
GHA,Ghana
GRC,Greece
GRD,Grenada
GTM,Guatemala
GIN,Guinea
GNB,Guinea-Bissau
GUY,Guyana
HTI,Haiti
HND,Honduras
HKG,"Hong Kong, China (SAR)"
HUN,Hungary
ISL,Iceland
IND,India
IDN,Indonesia
IRN,Iran (Islamic Republic of)
IRQ,Iraq
IRL,Ireland
ISR,Israel
ITA,Italy
JAM,Jamaica
JPN,Japan
JOR,Jordan
KAZ,Kazakhstan
KEN,Kenya
KIR,Kiribati
KOR,Korea (Republic of)
KWT,Kuwait
KGZ,Kyrgyzstan
LAO,Lao People's Democratic Republic
LVA,Latvia
LBN,Lebanon
LSO,Lesotho
LBR,Liberia
LBY,Libya
LIE,Liechtenstein
LTU,Lithuania
LUX,Luxembourg
MDG,Madagascar
MWI,Malawi
MYS,Malaysia
MDV,Maldives
MLI,Mali
MLT,Malta
MHL,Marshall Islands
MRT,Mauritania
MUS,Mauritius
MEX,Mexico
FSM,Micronesia (Federated States of)
MDA,Moldova (Republic of)
MNG,Mongolia
MNE,Montenegro
MAR,Morocco
MOZ,Mozambique
MMR,Myanmar
NAM,Namibia
NPL,Nepal
NLD,Netherlands
NZL,New Zealand
NIC,Nicaragua
NER,Niger
NGA,Nigeria
MKD,North Macedonia
NOR,Norway
OMN,Oman
PAK,Pakistan
PLW,Palau
PSE,"State of Palestine"
PAN,Panama
PNG,Papua New Guinea
PRY,Paraguay
PER,Peru
PHL,Philippines
POL,Poland
PRT,Portugal
QAT,Qatar
ROU,Romania
RUS,Russian Federation
RWA,Rwanda
KNA,Saint Kitts and Nevis
LCA,Saint Lucia
VCT,Saint Vincent and the Grenadines
WSM,Samoa
STP,Sao Tome and Principe
SAU,Saudi Arabia
SEN,Senegal
SRB,Serbia
SYC,Seychelles
SLE,Sierra Leone
SGP,Singapore
SVK,Slovakia
SVN,Slovenia
SLB,Solomon Islands
ZAF,South Africa
SSD,South Sudan
ESP,Spain
LKA,Sri Lanka
SDN,Sudan
SUR,Suriname
SWE,Sweden
CHE,Switzerland
SYR,Syrian Arab Republic
TJK,Tajikistan
TZA,Tanzania (United Republic of)
THA,Thailand
TLS,Timor-Leste
TGO,Togo
TON,Tonga
TTO,Trinidad and Tobago
TUN,Tunisia
TUR,Türkiye
TKM,Turkmenistan
UGA,Uganda
UKR,Ukraine
ARE,United Arab Emirates
GBR,United Kingdom
USA,United States
URY,Uruguay
UZB,Uzbekistan
VUT,Vanuatu
VEN,Venezuela (Bolivarian Republic of)
VNM,Viet Nam
YEM,Yemen
ZMB,Zambia
ZWE,Zimbabwe
AND,Andorra
CPV,Cabo Verde
ABW,Aruba
AIA,Anguilla
ALA,Åland Islands
ANT,Netherlands Antilles
ASM,American Samoa
ATF,French Southern Territories
BES,"Bonaire, Saint Eustatius and Saba"
BLM,Saint Barthelemy
BMU,Bermuda
BVT,Bouvet Island
CA-,(lakes)
CCK,Cocos (Keeling) Islands
CL-,Clipperton Island
COK,Cook Islands
CUW,Curaçao
CXR,Christmas Island
CYM,Cayman Islands
ESH,Western Sahara
FLK,Falkland Islands
FRO,Faroe Islands
GGY,Guernsey
GIB,Gibraltar
GLP,Guadeloupe
GRL,Greenland
GUF,French Guiana
GUM,Guam
HMD,Heard Island and McDonald Islands
IMN,Isle of Man
IOT,British Indian Ocean Territory
JEY,Jersey
KO-,Kosovo(1)
MAC,"China, Macao Special Administrative Region"
MAF,Saint Martin
MCO,Monaco
MNP,Northern Mariana Islands
MSR,Montserrat
MTQ,Martinique
MYT,Mayotte
NCL,New Caledonia
NFK,Norfolk Island
NIU,Niue
NRU,Nauru
PCN,Pitcairn
PRI,Puerto Rico
PRK,Democratic People's Republic of Korea
PYF,French Polynesia
REU,Réunion
SGS,South Georgia and the South Sandwich Islands
SHN,Saint Helena
SJM,Svalbard and Jan Mayen
SMR,San Marino
SMX,Sint Maarten
SOM,Somalia
SP-,Spratly islands
SPM,Saint Pierre and Miquelon
TCA,Turks and Caicos
TKL,Tokelau
TUV,Tuvalu
TWN,"Taiwan, Province of China"
UMI,United States Minor Outlying Islands
VAT,Vatican
VGB,British Virgin Islands
VIR,Virgin Islands (US)
WLF,Wallis and Futuna`)

export default data
