import { useEffect } from "react";
import {
  METRICS_CONFIGS,
  PATHWAY_CONFIG,
  SCENARIO_CONFIGS,
} from "../../lib/metric-constants";

import "./ScenarioDropdown.scss";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { groupBy, keys } from "lodash";
import Select from "react-select";
import { getDropdownScenarioCustomStyle } from "../../lib/dropdowns";
import infoIconWhite from "../../images/info_icon_white.svg";
import infoIconBlack from "../../images/info_icon.svg";
import { isMetricInSelectedScenario } from "../../lib/metric-utils";

const info =
  "How much greenhouse gas we emit in the future will shape how climate change evolves. In the Low Emissions scenario, warming stays below 2.0°C relative to 1850-1900. The Moderate and Intermediate Emissions scenarios align with countries carrying out their current policies, resulting in best-estimate warming around 2.7°C by 2100. In the High and Very High Emissions scenarios, emissions would continue to increase at the same pace they did during the first decade of this century resulting in warming around 4.4°C by 2100. For more information on SSP and RCP scenarios, see the FAQs.";

export function ScenarioDropdown({
  scenario,
  metricId,
  setShowOverlay,
  setNavigation,
  isDark,
}) {
  const grouppedValues = groupBy(SCENARIO_CONFIGS, (d) => d.pathway);
  const options = keys(grouppedValues).map((pathway) => ({
    label: PATHWAY_CONFIG[pathway].label,
    options: grouppedValues[pathway],
  }));
  const defaultValue = SCENARIO_CONFIGS.find((s) => s.value === scenario);
  const isLight = !isDark;
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const handleSelectClick = (scenario) => {
    if (metricId) {
      if (!isMetricInSelectedScenario(metricId, scenario)) {
        const metricInSelectedScenario = METRICS_CONFIGS.find((metric) =>
          isMetricInSelectedScenario(metric.key, scenario)
        );
        setShowOverlay(false);
        setNavigation({
          scenario: scenario,
          metric: metricInSelectedScenario.key,
        });
      } else {
        setNavigation({ scenario });
      }
    } else {
      setNavigation({ scenario });
    }
  };

  return (
    <div className="scenario-dropdown dropdown-wrapper">
      <div className="label-wrapper">
        <span
          className="ui-label"
          style={{ color: isDark ? "white" : "black" }}
        >
          EMISSION SCENARIO
        </span>
        {isDark ? (
          <img
            style={{ width: "0.8em" }}
            src={infoIconWhite}
            data-tip={info}
            alt={infoIconWhite}
          />
        ) : (
          <img
            style={{ width: "0.8em" }}
            src={infoIconBlack}
            data-tip={info}
            alt={infoIconBlack}
          />
        )}
      </div>
      <div className={classNames("dropdown")}>
        <Select
          defaultValue={defaultValue}
          options={options}
          onChange={(scenario) => handleSelectClick(scenario.value)}
          styles={getDropdownScenarioCustomStyle(isLight)}
          isSearchable={false}
        />
      </div>
    </div>
  );
}
