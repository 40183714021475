import classNames from "classnames";
import "./IntroNav.scss";

function MobileIntroNav(props) {
  const { navigate, texts, textIndex, visible } = props;

  return (
    <div className={classNames("intro-nav mobileIntroNav", { visible })}>
      {texts.map((text, index) => {
        if (text.noNav) {
          return null;
        }
        const selected = index === textIndex;
        return (
          <div
            key={index}
            className={classNames("mobileNavItem", { selected })}
            onClick={() => navigate(index)}
          />
        );
      })}
    </div>
  );
}

export function IntroNav({
  texts,
  textIndex,
  navigate,
  hover,
  visible,
  isMobile,
}) {
  if (isMobile) {
    return (
      <MobileIntroNav
        texts={texts}
        textIndex={textIndex}
        navigate={navigate}
        hover={hover}
        visible={visible}
        isMobile
      />
    );
  }

  return (
    <div
      className={classNames("intro-nav", { visible })}
      onMouseOver={(e) => hover(true)}
      onMouseOut={(e) => hover(false)}
    >
      {texts.map((text, i) => {
        if (text.noNav) {
          return null;
        }
        const highlighted = textIndex >= i;
        const selected = textIndex === i;
        const displayNavLine =
          i > 1 &&
          (text.metric === texts[i - 1].metric ||
            i === 3 ||
            i === 4 ||
            i === 6 ||
            i === 7);

        const dotStyle = displayNavLine ? { transform: `scale(0.8)` } : null;
        return (
          <div
            key={i}
            onClick={() => navigate(i)}
            className={classNames("navItem", { highlighted, selected })}
          >
            <div className="dot" style={dotStyle} />
            {displayNavLine && <div className="line" />}
            <div className="label">{text.navLabel}</div>
          </div>
        );
      })}
    </div>
  );
}
