import "./IntroParagraph.scss";

export const IntroParagraph = ({ title, description, contentStyle }) => {
  return (
    <div className="IntroParagraph">
      <div className="spacer"></div>
      <section className="content-text" style={contentStyle}>
        <h1>{title}</h1>
        {description}
      </section>
    </div>
  );
};
