import { csvParse } from "d3-dsv";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { getBaselineYear, isWithoutBaseline } from "./metric-utils";

const METRIC_RENAMES = {
  "temperature F": "degF",
  "mortality risk index": "mortality index",
  "labor risk index": "labor index",
  "energy risk index": "energy index",
};

export function useCountryData({ year, scenario, metric, country }) {
  const [data, setData] = useState(null);
  const scenarioArray = Array.isArray(scenario) ? scenario : [scenario];
  useEffect(() => {
    const controller = new AbortController();

    const signal = controller.signal;

    window
      .fetch(`${process.env.PUBLIC_URL}/CountrySummary.csv`, { signal })
      .then((d) => d.text())
      .then((text) => {
        const rows = csvParse(text);
        // console.log(rows)
        setData(rows);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          console.log(e);
        }
      });
    return () => {
      controller.abort();
    };
  }, []);
  const finalMetric = METRIC_RENAMES[metric] ? METRIC_RENAMES[metric] : metric;
  const result = useMemo(() => {
    if (!data) {
      return null;
    }
    const allScenarioResults = scenarioArray.flatMap((scenario) => {
      const results = data
        .filter(
          (d) =>
            (year ? d.year === year : true) &&
            d.scenario === scenario &&
            (d.metric.toLowerCase() ===
              finalMetric.toLowerCase().replace(/ /g, "-") ||
              d.metric.toLowerCase() === finalMetric.toLowerCase())
        )
        .filter((d) => (country ? d.ISO === country : true))
        .filter(
          (d) =>
            d.ISO !== "CL-" && // exclude Clipperton Islands
            d.ISO !== "CA-" // excludes "(lakes)"
        )
        .sort((a, b) => a.year - b.year);

      // check if country has data

      const noDataForSelectedCountry = results.every((r) => r["0.5"] === "");

      if (results.length === 0 || noDataForSelectedCountry) {
        return [];
      } else {
        if (isWithoutBaseline(metric)) {
          // debugger
          const baselineYear = getBaselineYear(metric);
          if (!year || year === baselineYear) {
            const isos = _.uniqBy(
              results.length > 0 ? results : data,
              "ISO"
            ).map((d) => d.ISO);
            isos.forEach((iso) => {
              results.unshift({
                year: baselineYear,
                ISO: iso,
                scenario: scenario,
                0.5: 0,
              });
            });
          }
        }
        return results;
      }
    });
    return allScenarioResults;
  }, [year, scenarioArray.join(), finalMetric, data, country, metric]);

  if (result && result.length === 0) {
    return null;
  }
  if (result && result.length === 1) {
    return result[0];
  }
  return result;
}
