import {
  temperatureFormatterShort,
  temperatureGraphLabel,
  daysAbove95Label,
  daysBelow32Label,
} from "./metric-formats";

import { round } from "lodash";

/** PATHWAY AND SCENARIO */

export const PATHWAY = {
  SSP: "ssp",
  RCP: "rcp",
};

export const PATHWAY_CONFIG = {
  [PATHWAY.SSP]: { label: "Sea Level Rise" },
  [PATHWAY.RCP]: { label: "Temperature" },
};

export const SCENARIO = {
  SSP126: "SSP1-26",
  SSP245: "SSP2-45",
  SSP585: "SSP5-85",
  RCP85: "rcp85",
  RCP45: "rcp45",
};

export const SCENARIO_CONFIGS = [
  {
    label: "Low Emissions (SSP1-2.6)",
    value: SCENARIO.SSP126,
    legendLabel: "Low",
    pathway: PATHWAY.SSP,
    emissionLabel: "Low Emission",
  },
  {
    label: "Intermediate Emissions (SSP2-4.5)",
    value: SCENARIO.SSP245,
    legendLabel: "Intermediate",
    pathway: PATHWAY.SSP,
    emissionLabel: "Intermediate Emission",
  },
  {
    label: "Very High Emissions (SSP5-8.5)",
    value: SCENARIO.SSP585,
    legendLabel: "Very high",
    pathway: PATHWAY.SSP,
    emissionLabel: "Very High Emission",
  },
  {
    label: "Moderate (RCP 4.5)",
    value: SCENARIO.RCP45,
    legendLabel: "Moderate",
    pathway: PATHWAY.RCP,
    emissionLabel: "Moderate Emission",
  },
  {
    label: "High (RCP 8.5)",
    value: SCENARIO.RCP85,
    legendLabel: "High",
    pathway: PATHWAY.RCP,
    emissionLabel: "High Emission",
  },
];

/** METRICS */

export const METRICS_GROUP = {
  SEA_LEVEL_RISING: "Sea Level Rise",
  TEMPERATURE: "Temperature",
};

export const METRIC_TYPE = {
  HAZARD: "Hazard",
  IMPACT: "Impact",
};

export const METRIC = {
  TEMPERATURE: "temperature F",
  DAYS_OVER: "days over 95f",
  DAYS_UNDER: "days under 32f",
  MORTALITY_INDEX: "mortality risk index",
  LABOR_INDEX: "labor risk index",
  ENERGY_INDEX: "energy risk index",
  MORTALITY: "mortality",
  ELECTRICITY: "electricity",
  OTHER_ENERGY: "other energy",
  HIGHRISK_LABOR: "highrisk labor",
  LOWRISK_LABOR: "lowrisk labor",
  SEA_LEVEL_RISING: "sea level rising cm",
  SHARE_OF_LAND: "share of land",
  SHARE_OF_POPULATION: "share of population",
  LAND: "land",
};
export const METRICS_WITHOUT_BASELINE = [
  METRIC.MORTALITY,
  METRIC.ELECTRICITY,
  METRIC.OTHER_ENERGY,
  METRIC.HIGHRISK_LABOR,
  METRIC.LOWRISK_LABOR,
  METRIC.SEA_LEVEL_RISING,
  METRIC.SHARE_OF_LAND,
  METRIC.SHARE_OF_POPULATION,
  METRIC.LAND,
];

export const RISK_INDEX_METRICS = [
  METRIC.MORTALITY_INDEX,
  METRIC.LABOR_INDEX,
  METRIC.ENERGY_INDEX,
];
export const RISK_NATURAL_METRICS = [
  METRIC.MORTALITY,
  METRIC.ELECTRICITY,
  METRIC.OTHER_ENERGY,
  METRIC.HIGHRISK_LABOR,
  METRIC.LOWRISK_LABOR,
];

export const METRICS_CONFIGS = [
  {
    key: METRIC.SEA_LEVEL_RISING,
    group: METRICS_GROUP.SEA_LEVEL_RISING,
    type: METRIC_TYPE.HAZARD,
    boldLabel: "Sea Level Rise",
    label: "Local median projection (cm)",
    metricFormatter: (d) => `${round(d, 2)} cm`,
    extent: [-10, 80],
    extentFormatter: ["<", ">"],
    graphLabel: temperatureGraphLabel,
    comparisonGrouping: "climate",
    riskHeader: "SLR",
    riskSubheader: "Global median projection",
    riskGraphLabel: temperatureGraphLabel,
    overrideAllCountriesLabel: "Median projection",
    colorLightness: 0.468,
  },
  {
    key: METRIC.SHARE_OF_LAND,
    group: METRICS_GROUP.SEA_LEVEL_RISING,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Land Inundation Impact",
    label: "Share of region under mean sea level (%)",
    metricFormatter: (d) => `${round(d, 2)}%`,
    extent: [0, 5],
    extentFormatter: ["", ">"],
    graphLabel: temperatureGraphLabel,
    comparisonGrouping: "climate",
    riskHeader: "SLR",
    riskSubheader: "Global median projection",
    riskGraphLabel: temperatureGraphLabel,
    overrideAllCountriesLabel: "Median projection",
    colorLightness: 0.49,
  },
  {
    key: METRIC.LAND,
    group: METRICS_GROUP.SEA_LEVEL_RISING,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Land Inundation Impact",
    label: "Area under mean sea level (km²)",
    metricFormatter: (d) => `${round(d, 1)} km²`,
    extent: [0, 1000],
    extentFormatter: ["", ">"],
    graphLabel: temperatureGraphLabel,
    comparisonGrouping: "climate",
    riskHeader: "SLR",
    riskSubheader: "Global median projection",
    riskGraphLabel: temperatureGraphLabel,
    overrideAllCountriesLabel: "Median projection",
    colorLightness: 0.49,
  },
  {
    key: METRIC.SHARE_OF_POPULATION,
    group: METRICS_GROUP.SEA_LEVEL_RISING,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Population Flood Exposure Impact",
    label: "Share of population living in 1-in-20-year floodplain",
    metricFormatter: (d) => `${round(d, 2)}%`,
    extent: [0, 10],
    extentFormatter: ["", ">"],
    graphLabel: temperatureGraphLabel,
    comparisonGrouping: "climate",
    riskHeader: "SLR",
    riskSubheader: "Global median projection",
    riskGraphLabel: temperatureGraphLabel,
    overrideAllCountriesLabel: "Median projection",
    colorLightness: 0.49,
  },
  {
    key: METRIC.TEMPERATURE,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.HAZARD,
    boldLabel: "Average Annual Temperature",
    label: "Median projection",
    metricFormatter: temperatureFormatterShort,
    extent: [20, 92],
    extentFormatter: ["<", ">"],
    graphLabel: temperatureGraphLabel,
    comparisonGrouping: "climate",
    riskHeader: "Temperature",
    riskSubheader: "Global median projection",
    riskGraphLabel: temperatureGraphLabel,
    overrideAllCountriesLabel: "Median projection",
    colorLightness: 0.49,
  },

  {
    key: METRIC.DAYS_OVER,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.HAZARD,
    boldLabel: daysAbove95Label,
    label: "Annual Average",
    metricFormatter: (d) => `${Math.round(d)} days`,
    extent: [0, 300],
    extentFormatter: ["", ">"],
    graphLabel: "day change",
    comparisonGrouping: "climate",
    riskHeader: daysAbove95Label,
    riskSubheader: "Global annual average",
    riskGraphLabel: "Number of days",
    overrideAllCountriesLabel: () => `${daysAbove95Label()} (annual average)`,
    colorLightness: 0.49,
  },

  {
    key: METRIC.DAYS_UNDER,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.HAZARD,
    boldLabel: daysBelow32Label,
    label: "Annual Average",
    metricFormatter: (d) => `${Math.round(d)} days`,
    extent: [0, 300],
    extentFormatter: ["", ">"],
    graphLabel: "day change",
    comparisonGrouping: "climate",
    riskHeader: daysBelow32Label,
    riskSubheader: "Global annual average",
    riskGraphLabel: "Number of days",
    overrideAllCountriesLabel: () => `${daysBelow32Label()} (annual average)`,
    colorLightness: 0.49,
  },
  {
    key: METRIC.MORTALITY,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Mortality Impact",
    label: "Change in deaths per 100,000 population",
    dropdownLabel:
      "Mortality Impact - (change in deaths per 100,000 population)",

    metricFormatter: (d) => `${Math.round(d)}`,
    extent: (rcp) => (rcp === "rcp45" ? [-100, 100] : [-300, 300]),
    extentFormatter: ["<", ">"],
    graphLabel: "rate change",
    comparisonGrouping: "impact",
    riskHeader: "Mortality Impact",
    riskSubheader: "Change in deaths per 100,000 population",
    riskGraphLabel: "Mortalitiy number",
    overrideAllCountriesLabel:
      "Change in death rates (deaths per 100k population)",
    colorLightness: 0.54,
  },

  {
    key: METRIC.ELECTRICITY,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Energy Consumption Impact",
    label: "Change in use/capita (GJ)",
    dropdownLabel:
      "Energy Consumption Impact - Electricity (change in GJ per capita)",
    overrideAllCountriesLabel: "Per capita change in electricty (GJ)",

    metricFormatter: (d) => `${round(d, 1)} GJ`,
    extent: (rcp) => (rcp === SCENARIO.RCP45 ? [-2, 2] : [-2.5, 2.5]),
    extentFormatter: ["<", ">"],
    graphLabel: "GJ change",
    comparisonGrouping: "impact",
    riskHeader: "Energy Consumption Impact",
    riskSubheader: "Change in electricity use/capita (GJ)",
    riskGraphLabel: "Per capita change (GJ)",
    colorLightness: 0.49,
  },
  {
    key: METRIC.OTHER_ENERGY,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Other Energy Fuels Consumption Impact",
    label: "Change in use/capita (GJ)",
    dropdownLabel:
      "Energy Consumption Impact - Other fuels (change in GJ per capita)",
    overrideAllCountriesLabel: "Per capita change in other fuels (GJ)",

    metricFormatter: (d) => `${round(d, 1)} GJ`,
    extent: (rcp) => (rcp === "rcp45" ? [-7, 7] : [-10, 10]),
    extentFormatter: ["<", ">"],
    graphLabel: "GJ change",
    comparisonGrouping: "impact",
    riskHeader: "Energy Consumption Impact",
    riskSubheader: "Other fuels use/capita change (GJ)",
    riskGraphLabel: "Per capita change (GJ)",
    colorLightness: 0.49,
  },
  {
    key: METRIC.HIGHRISK_LABOR,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Labor Impact to High Risk Sectors",
    label: "Change in annual hours worked per worker",
    dropdownLabel:
      "Labor Impact - Change in annual hours worked per worker, high risk sectors",
    subLabel: "Annual hours worked per worker",
    overrideAllCountriesLabel:
      "Change in work time in high risk sectors annual hours worked per worker",

    metricFormatter: (d) => `${round(d, 1)} hours`,
    extent: [-32, 32],
    extentFormatter: ["<", ">"],
    graphLabel: "hours change",
    comparisonGrouping: "impact",
    riskHeader: "Labor Impact",
    riskSubheader:
      "Change in annual hours worked per worker,  high risk sectors",
    riskGraphLabel: "Change in work time (hours)",
    colorLightness: 0.49,
  },
  {
    key: METRIC.LOWRISK_LABOR,
    group: METRICS_GROUP.TEMPERATURE,
    type: METRIC_TYPE.IMPACT,
    boldLabel: "Labor Impact to Low Risk Sectors",
    label: "Change in annual hours worked per worker",
    dropdownLabel:
      "Labor Impact - Change in annual hours worked per worker, low risk sectors",
    subLabel: "Annual hours worked per worker",
    overrideAllCountriesLabel:
      "Change in work time in low risk sectors annual hours worked per worker",
    metricFormatter: (d) => `${round(d, 1)} hours`,
    extent: [-10, 10],
    extentFormatter: ["<", ">"],
    graphLabel: "hours change",
    comparisonGrouping: "impact",
    riskHeader: "Labor Impact",
    riskSubheader:
      "Change in work time annual hours worked per worker in low risk sectors",

    riskGraphLabel: "Change in work time (hours)",
    colorLightness: 0.49,
  },
];

export const METRIC_INFO_ICON_TEXT = {
  [METRIC.SEA_LEVEL_RISING]:
    "This map depicts the median projected local relative sea level rise, relative to 1995-2014 baseline sea level, in centimeters. Projections are based on a set of scenarios used in the IPCC 6th Assessment Report and consider only processes for which projections can be made with at least medium confidence. ",
  [METRIC.SHARE_OF_LAND]:
    "This map depicts the median projected percent of land in each region that falls below local mean sea level due to climate change’s impact on sea level rise, compared to a future world without climate change. This metric is based on current land area and the average sea level relative to the height of the coast at a given location over a 20-year time period.",
  [METRIC.LAND]:
    "This map depicts the median projected land area in each region that falls below local mean sea level due to climate change’s impact on sea level rise, compared to a future world without climate change. This metric, reported in square kilometers, is based on current land area and the average sea level relative to the height of the coast at a given location over a 20-year time period.",
  [METRIC.SHARE_OF_POPULATION]:
    "This map depicts the percent of a region’s population living in the median projected 1-in-20 year floodplain due to climate change’s impact on extreme sea levels, relative to a future with no climate change. Higher ocean surface levels result in storm events pushing further inland, exposing a greater share of people to 20-year flood risk. The present-day spatial distribution of population along coasts is used.",
  [METRIC.TEMPERATURE]:
    "This map depicts the median projection for average annual temperature from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. Country and territory estimates are weighted by land area.",
  [METRIC.DAYS_OVER]:
    "This map depicts the annual number of days with maximum temperatures exceeding the threshold. Shown here is the median projection from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. Country and territory estimates are weighted by population.",
  [METRIC.DAYS_UNDER]:
    "This map depicts the annual number of days with minimum temperatures below the threshold. Shown here is the median projection from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. Country and territory estimates are weighted by population.",
  [METRIC.MORTALITY]:
    "This map depicts the mean projected change in annual death rates due to climate change’s impact on daily temperature, compared to a future world without climate change. It captures the effect of both cold and hot temperatures. A death rate is expressed as deaths per 100,000 people. A rate provides a meaningful way to compare deaths between population groups of different sizes.",
  [METRIC.ELECTRICITY]:
    "This map depicts the mean projected annual per capita change in electricity consumed due to climate change’s impact on daily temperature, compared to a future world without climate change. It captures the effect of both cold and hot temperatures.",
  [METRIC.OTHER_ENERGY]:
    "This map depicts the mean projected annual per capita change in other energy fuels (natural gas, oil, coal) consumed due to climate change’s impact on daily temperature, compared to a future world without climate change. It captures the effect of both cold and hot temperatures.",
  [METRIC.HIGHRISK_LABOR]:
    "This map depicts the mean projected change in time spent working due to climate change, expressed as hours per worker per year, compared to a future world without climate change. High risk sectors include workers employed in agriculture, mining, construction, and manufacturing. Low risk sectors include all other industries.",
  [METRIC.LOWRISK_LABOR]:
    "This map depicts the mean projected change in time spent working due to climate change, expressed as hours per worker per year, compared to a future world without climate change. High risk sectors include workers employed in agriculture, mining, construction, and manufacturing. Low risk sectors include all other industries.",
};

export const INFO_ICON_DISCLAIMERS = {
  [METRIC.SEA_LEVEL_RISING]:
    "Each line on this plot represents a region’s median projected local relative sea level rise, relative to 1995-2014 baseline sea level, in centimeters. Projections are based on a set of scenarios used in the IPCC 6th Assessment Report and consider only processes for which projections can be made with at least medium confidence. The estimates are weighted by coastline segment length.",
  [METRIC.SHARE_OF_LAND]:
    "Each line on this plot represents a median projection of the percent of land in each region that falls below local mean sea level as a consequence of sea level rise, compared to a future world without climate change. This metric is based on current land area and the average sea level relative to the height of the coast over a 20-year time period.",
  [METRIC.LAND]:
    "Each line on this plot represents a median projection of the land area in each region that falls below local mean sea level as a consequence of sea level rise, compared to a future world without climate change. This metric, reported in square kilometers, is based on current land area and the average sea level relative to the height of the coast over a 20-year time period.",
  [METRIC.SHARE_OF_POPULATION]:
    " Each line on this plot represents the percent of a region’s population living in the median projected 1-in-20 year floodplain due to climate change’s impact on extreme sea levels, relative to a future with no climate change. The present-day spatial distribution of population along coasts is used.",
  [METRIC.TEMPERATURE]:
    "Each line on this plot represents a region’s average annual temperature. The plot shows the median projection from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. The estimates are weighted by land area.",
  [METRIC.DAYS_OVER]:
    "Each line on this plot represents the annual number of days in a region with maximum temperatures exceeding the threshold. The plot shows the median projection from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. The estimates are weighted by population.",
  [METRIC.DAYS_UNDER]:
    "Each line on this plot represents the annual number of days in a region with minimum temperatures below the threshold. The plot shows the median projection from an ensemble of global climate model runs that represents the range of possible climate outcomes for each emissions scenario. The estimates are weighted by population.",
  [METRIC.MORTALITY]:
    "Each line on this plot represents a region’s mean projected change in annual death rates due to climate change’s impact on daily temperature, compared to a future world without climate change. It captures the effect of both cold and hot temperatures. A death rate is expressed as deaths per 100,000 people. A rate provides a meaningful way to compare deaths between population groups of different sizes.",
  [METRIC.ELECTRICITY]:
    "Each line on this plot represents a country or territory’s mean projected annual per capita change in energy consumption due to climate change’s impact on daily temperature, compared to a future world without climate change. Use the toggle to view impacts on electricity or other fuels (natural gas, oil, coal) consumed. The estimates capture the effect of both cold and hot temperatures.",
  [METRIC.OTHER_ENERGY]:
    "Each line on this plot represents a country or territory’s mean projected annual per capita change in energy consumption due to climate change’s impact on daily temperature, compared to a future world without climate change. Use the toggle to view impacts on electricity or other fuels (natural gas, oil, coal) consumed. The estimates capture the effect of both cold and hot temperatures.",
  [METRIC.HIGHRISK_LABOR]:
    "Each line on this plot shows represents a country or territory’s mean projected change in time spent working due to climate change, expressed as hours per worker per year, compared to a future world without climate change. Use the toggle to view impacts for high risk sectors (includes workers employed in agriculture, mining, construction, and manufacturing) or low risk sectors (includes all other industries).",
  [METRIC.LOWRISK_LABOR]:
    "Each line on this plot shows represents a country or territory’s mean projected change in time spent working due to climate change, expressed as hours per worker per year, compared to a future world without climate change. Use the toggle to view impacts for high risk sectors (includes workers employed in agriculture, mining, construction, and manufacturing) or low risk sectors (includes all other industries).",
};
