import classNames from "classnames";
import { MetricDropdown } from "../UI/MetricDropdown";
import { getMetricExtent, getMetricLabel } from "../../lib/metric-utils";
import { CountriesDropdown } from "../UI/CountriesDropdown";
import { METRICS_GROUP, SCENARIO } from "../../lib/metric-constants";
import { MapLegend } from "../countryPage/MapLegend";
import "./LegendPanel.scss";

export function LegendPanel({
  exploreMode,
  selectedMetric,
  hideLegend,
  exploreModeMetric,
  setExploreModeMetric,
  countryLookup,
  exploreModeCountry,
  setExploreModeCountry,
  colorScale,
  valueSelectedCountry,
}) {
  const metricTitle = getMetricLabel(selectedMetric);
  const metricSubtitle = selectedMetric?.label;

  const group = selectedMetric?.group;
  const scenarioKey =
    group === METRICS_GROUP.TEMPERATURE ? SCENARIO.RCP45 : SCENARIO.SSP245;

  const scenario = group === METRICS_GROUP.TEMPERATURE ? "RCP 4.5" : "SSP2-4.5";

  const countryValues = [
    { label: "Global View", value: "" },
    ...countryLookup.map((c) => ({
      label: c.name,
      value: c.code3,
    })),
  ];
  const value = !exploreModeCountry
    ? countryValues[0]
    : countryValues.find((c) => c.value === exploreModeCountry);

  const reset = () => {
    setExploreModeCountry("");
  };

  return (
    <div className={classNames("legend-panel", { hideLegend, exploreMode })}>
      {exploreMode && (
        <>
          <CountriesDropdown
            countryValues={countryValues}
            value={value}
            onChange={(option) => setExploreModeCountry(option.value)}
            onClickGlobe={reset}
          />
          <MetricDropdown
            selectedMetric={exploreModeMetric}
            onChange={setExploreModeMetric}
          />
        </>
      )}
      <div>
        <MapLegend
          width={500}
          extent={getMetricExtent(selectedMetric.key, scenarioKey)}
          scenario={scenarioKey}
          title={exploreMode ? "" : metricTitle}
          subtitle={metricSubtitle}
          detailText={`2040-2059, ${scenario}`}
          metric={selectedMetric.key}
          colorScale={colorScale}
          dark
          selectedCountry={value.label}
          countryValue={valueSelectedCountry}
        />
      </div>
    </div>
  );
}
