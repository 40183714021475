import { scaleSequential } from "d3-scale";
import getColorInterpolator from "./getColorInterpolator";
import countryLookup from "./data/countryLookup";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from "react";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import TWEEN, { Tween } from "@tweenjs/tween.js";
import PolygonMesh from "./PolygonMesh";
import GlobeMesh from "./GlobeMesh";
import BackgroundMesh from "./BackgroundMesh";
import { animated, useSpring } from "@react-spring/three";
import { useControls } from "leva";
import Cities from "./data/Cities500k.csv.js";
import {
  METRIC,
  METRICS_CONFIGS,
  METRICS_GROUP,
  SCENARIO,
} from "./lib/metric-constants";
import { LegendPanel } from "./components/globePage/LegendPanel";
import { getMetricExtent } from "./lib/metric-utils";
import { CityLabelLine } from "./components/globePage/CityLabelLine";
import { CountryTooltipControl } from "./components/globePage/CountryTooltipControl";
import { useCountryData } from "./lib/useCountryData";
import { years } from "./constants";
import { globeTexts } from "./lib/globe-constants";
import { useWindowSize, useIdle, useMouse } from "react-use";

export const GLOBE_RADIUS = 100;

export function polar2Cartesian({ lat, lng, alt = 0 }) {
  const phi = ((90 - lat) * Math.PI) / 180;
  const theta = ((90 - lng) * Math.PI) / 180;
  const r = GLOBE_RADIUS * (1 + alt);
  return {
    x: r * Math.sin(phi) * Math.cos(theta),
    y: r * Math.cos(phi),
    z: r * Math.sin(phi) * Math.sin(theta),
  };
}

function cartesian2Polar({ x, y, z }) {
  const r = Math.sqrt(x * x + y * y + z * z);
  const phi = Math.acos(y / r);
  const theta = Math.atan2(z, x);

  return {
    lat: 90 - (phi * 180) / Math.PI,
    lng: 90 - (theta * 180) / Math.PI - (theta < -Math.PI / 2 ? 360 : 0), // keep within [-180, 180] boundaries
    alt: r / GLOBE_RADIUS - 1,
  };
}

const initialCameraPosition = polar2Cartesian({ lat: 0, lng: 0, alt: 1 });

function Updater() {
  useFrame(() => {
    TWEEN.update();
  });
  return null;
}

export default function GlobeRenderer({
  countryFeatures,
  metric,
  countryIRHighlight,
  cameraFocus,
  alternateTexture,
  texturesToLoad,
  globeScale,
  countryOffset,
  hideLegend,
  exploreMode,
  texturesLoaded,
  maskTextures,
  maskTexture,
  globeHierId,
  overrideCameraPosition,
  setGlobalCountrySelection,
  loadAllTextures,
  setAllTexturesInitialized,
  setTextureProgress,
  isMobile,
  overIntroNav,
  allowMouseClickAdvance,
  textIndex,
}) {
  const [exploreModeMetric, setExploreModeMetric] = useState(
    METRIC.SEA_LEVEL_RISING
  );

  const [exploreModeCountry, setExploreModeCountry] = useState("");

  useEffect(() => {
    if (!exploreMode && exploreModeCountry) {
      setExploreModeCountry("");
    }
  }, [exploreMode, exploreModeCountry]);
  const {
    globeDefaultAltitude,
    globeDefaultFov,
    globeZoomedInAltitude,
    globeZoomedInFov,
    globeZoomedInCameraLatitudeOffset,
    globeZoomedInCameraLookAtOffset,
    globeAutoRotateSpeed,
    globeZoomedInLookAtAltitude,
    globeZoomedInCameraLongitudeOffset,
    overrideCountrySpecificOffsets,
  } = useControls(
    "Globe",
    {
      globeDefaultAltitude: 1,
      globeDefaultFov: 75,
      globeZoomedInAltitude: 0.8,
      globeZoomedInFov: 55,
      globeZoomedInCameraLatitudeOffset: 20,
      globeZoomedInCameraLookAtOffset: 40,
      globeAutoRotateSpeed: 0.6,
      globeZoomedInLookAtAltitude: -0.3,
      globeZoomedInCameraLongitudeOffset: 3,
      overrideCountrySpecificOffsets: false,
    },
    { collapsed: true }
  );
  const cameraRef = useRef();
  const controlsRef = useRef();

  const selectedMetric = useMemo(() => {
    if (exploreMode) {
      return METRICS_CONFIGS.find((d) => d.key === exploreModeMetric);
    } else {
      return METRICS_CONFIGS.find((d) => d.key === metric);
    }
  }, [metric, exploreMode, exploreModeMetric]);

  const scenario =
    selectedMetric.group === METRICS_GROUP.TEMPERATURE
      ? SCENARIO.RCP45
      : SCENARIO.SSP245;

  const [colorScale] = useMemo(() => {
    const extent = getMetricExtent(selectedMetric.key, scenario);

    const colorScale = scaleSequential()
      .domain(extent)
      .interpolator(getColorInterpolator(selectedMetric.key));
    return [colorScale];
  }, [selectedMetric.key, scenario]);

  const [
    hideTooltipsDuringCameraTransition,
    setHideTooltipsDuringCameraTransition,
  ] = useState(false);
  useEffect(() => {
    if (!cameraRef.current || !controlsRef.current) {
      return;
    }
    const cameraPosition = cameraRef.current.position;
    const cameraWorldCoords = cartesian2Polar(cameraPosition);
    const lookAt = cartesian2Polar(controlsRef.current.target);
    cameraWorldCoords.lookAtLng = isNaN(lookAt.lng)
      ? cameraWorldCoords.lng
      : lookAt.lng;
    cameraWorldCoords.lookAtLat = isNaN(lookAt.lat)
      ? cameraWorldCoords.lat
      : lookAt.lat;
    cameraWorldCoords.lookAtAlt = isNaN(lookAt.alt)
      ? cameraWorldCoords.alt
      : lookAt.alt;
    cameraWorldCoords.fov = cameraRef.current.fov;

    let targetWorldCoords = { ...cameraWorldCoords };
    targetWorldCoords.alt = globeDefaultAltitude;
    targetWorldCoords.lookAtAlt = -1;
    targetWorldCoords.fov = globeDefaultFov;
    if (exploreMode && exploreModeCountry !== "") {
      const country = countryLookup.find((d) => d.code3 === exploreModeCountry);
      if (country) {
        targetWorldCoords.lat = country.lat;
        targetWorldCoords.lng = country.lng;
      }
    } else if (globeHierId && Cities[globeHierId]) {
      const city = Cities[globeHierId];

      if (city && city.lat) {
        let positionLatOffset =
          (countryOffset === "right" ? -1 : -1) *
          globeZoomedInCameraLatitudeOffset;
        let lookAtLatOffset =
          (countryOffset === "right" ? -1 : 1) *
          globeZoomedInCameraLookAtOffset;
        let globeLongOffset =
          (countryOffset === "right" ? 1 : 1) *
          globeZoomedInCameraLongitudeOffset;
        if (overrideCameraPosition) {
          if (overrideCameraPosition.globeZoomedInCameraLatitudeOffset) {
            positionLatOffset =
              -overrideCameraPosition.globeZoomedInCameraLatitudeOffset;
          }
          if (overrideCameraPosition.globeZoomedInCameraLongitudeOffset) {
            globeLongOffset =
              -overrideCameraPosition.globeZoomedInCameraLongitudeOffset;
          }
        }
        targetWorldCoords = {
          lat: city.lat + positionLatOffset,
          lng: city.lng + globeLongOffset,
          alt: globeZoomedInAltitude,
          lookAtLng: city.lng + lookAtLatOffset,
          lookAtAlt: globeZoomedInLookAtAltitude,
          lookAtLat: city.lat,
          fov: globeZoomedInFov,
        };
      }
    }
    while (cameraWorldCoords.lng - targetWorldCoords.lng > 180)
      cameraWorldCoords.lng -= 360;
    while (cameraWorldCoords.lng - targetWorldCoords.lng < -180)
      cameraWorldCoords.lng += 360;

    while (cameraWorldCoords.lookAtLng - targetWorldCoords.lookAtLng > 180)
      cameraWorldCoords.lookAtLng -= 360;
    while (cameraWorldCoords.lookAtLng - targetWorldCoords.lookAtLng < -180)
      cameraWorldCoords.lookAtLng += 360;

    setHideTooltipsDuringCameraTransition(true);
    new Tween(cameraWorldCoords)
      .to(targetWorldCoords, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate((t) => {
        const newCameraPosition = polar2Cartesian(t);
        cameraPosition.set(
          newCameraPosition.x,
          newCameraPosition.y,
          newCameraPosition.z
        );
        const newLookAt = polar2Cartesian({
          alt: t.lookAtAlt,
          lng: t.lookAtLng,
          lat: t.lookAtLat,
        });
        controlsRef.current.target.set(newLookAt.x, newLookAt.y, newLookAt.z);
        cameraRef.current.fov = t.fov;

        cameraRef.current.updateProjectionMatrix();
      })
      .onComplete(() => {
        setHideTooltipsDuringCameraTransition(false);
      })
      .start();
  }, [
    countryIRHighlight,
    cameraFocus,
    countryOffset,
    globeDefaultAltitude,
    globeZoomedInAltitude,
    globeZoomedInFov,
    globeDefaultFov,
    globeZoomedInCameraLookAtOffset,
    globeZoomedInCameraLatitudeOffset,
    globeZoomedInLookAtAltitude,
    globeZoomedInCameraLongitudeOffset,
    overrideCountrySpecificOffsets,
    overrideCameraPosition,
    globeHierId,
    exploreMode,
    exploreModeCountry,
  ]);

  const [onGlobe, setOnGlobe] = useState(false);
  const hoverGlobe = useCallback(
    (on) => {
      setOnGlobe(on);
    },
    [setOnGlobe]
  );
  const [hoveredCountry, setHoveredCountry] = useState(null);
  // const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    if (!cameraRef.current || !controlsRef.current) {
      return;
    }
    const cameraRotate =
      countryIRHighlight === null &&
      exploreModeCountry === "" &&
      !onGlobe &&
      !hoveredCountry;
    controlsRef.current.autoRotate = cameraRotate;
  }, [countryIRHighlight, exploreModeCountry, onGlobe, hoveredCountry]);

  const globeSpring = useSpring({ globeScale });

  // const navigate = useNavigate();
  const exploreOnClick = useCallback(
    (countryFeature) => {
      const country = countryLookup.find((d) => d.number === countryFeature.id);
      if (country) {
        setGlobalCountrySelection(country.code3);
        setExploreModeCountry(country.code3);
      }
    },
    [setExploreModeCountry, setGlobalCountrySelection]
  );

  const hoveredCountryConfig = countryLookup.find(
    (d) => d.number === hoveredCountry?.id
  );
  const selectedCountryConfig = countryLookup.find(
    (d) => d.code3 === exploreModeCountry
  );
  const dataHoveredCountry = useCountryData({
    scenario,
    country: hoveredCountryConfig?.code3,
    metric: selectedMetric.key,
    year: years[1],
  });

  const dataSelectedCountry = useCountryData({
    scenario,
    country: selectedCountryConfig?.code3,
    metric: selectedMetric.key,
    year: years[1],
  });
  const valueHoveredCountry =
    hoveredCountry && dataHoveredCountry ? +dataHoveredCountry["0.5"] : null;

  const valueSelectedCountry =
    exploreModeCountry && dataSelectedCountry
      ? +dataSelectedCountry["0.5"]
      : null;

  const polygons = useMemo(() => {
    return exploreMode ? (
      <PolygonMesh
        GLOBE_RADIUS={GLOBE_RADIUS}
        features={countryFeatures}
        exploreMode={exploreMode}
        exploreOnClick={exploreOnClick}
        exploreOnHover={setHoveredCountry}
      />
    ) : null;
  }, [countryFeatures, exploreMode, exploreOnClick, setHoveredCountry]);
  const cursor = hoveredCountry !== null ? "pointer" : "default";

  const windowSize = useWindowSize();

  const width = windowSize.width;
  const headerHeight = 117;
  const height = windowSize.height - headerHeight;

  const canvasRef = useRef(null);
  const mousePosition = useMouse(canvasRef);
  let mouseAdvanceNotice = null;
  const isIdle = useIdle(500, true, ["mousemove"]);

  const overrideClickToAdvanceLabel = globeTexts[textIndex]
    .overrideClickToAdvanceLabel
    ? globeTexts[textIndex].overrideClickToAdvanceLabel
    : null;

  if (allowMouseClickAdvance && !overIntroNav && !isMobile) {
    const flipX = mousePosition.elX > windowSize.width * 0.9;
    const x = flipX ? mousePosition.elX - 10 : mousePosition.elX + 10;
    const styleLabel = {
      transform: `translate(${x}px, ${mousePosition.elY}px) translateY(-2em) ${
        flipX ? "translateX(-100%)" : ""
      }`,
      opacity: isIdle ? 0 : 1,
      display: "flex",
      gap: 8,
    };
    const label = overrideClickToAdvanceLabel
      ? overrideClickToAdvanceLabel
      : "Click to advance";

    mouseAdvanceNotice = (
      <div className={"mouse-advance-notice"} style={styleLabel}>
        {label}
      </div>
    );
  }
  return (
    <div style={{ width, height, cursor }} ref={canvasRef}>
      <Canvas linear={false} gl={{ antialias: "false" }}>
        <PerspectiveCamera
          makeDefault
          ref={cameraRef}
          fov={globeDefaultFov}
          near={0.1}
          far={1000}
          position={[
            initialCameraPosition.x,
            initialCameraPosition.y,
            initialCameraPosition.z,
          ]}
        />

        <OrbitControls
          autoRotate={!onGlobe && !hoveredCountry && exploreModeCountry === ""}
          ref={controlsRef}
          autoRotateSpeed={globeAutoRotateSpeed}
          camera={cameraRef.current}
          minDistance={GLOBE_RADIUS * 1.25}
          maxDistance={GLOBE_RADIUS * 3}
          enableZoom={false}
        />
        <color attach="background" args={["#101010"]} />
        <ambientLight intensity={1} />
        <pointLight
          position={[
            initialCameraPosition.x,
            initialCameraPosition.y,
            initialCameraPosition.z,
          ]}
        />
        <Suspense fallback={null}>
          <BackgroundMesh />
        </Suspense>
        <Updater />

        <animated.group scale={globeSpring.globeScale}>
          <Suspense fallback={null}>
            <GlobeMesh
              maskTextures={maskTextures}
              maskTexture={maskTexture}
              texturesLoaded={texturesLoaded}
              texturesToLoad={texturesToLoad}
              alternateTexture={alternateTexture}
              exploreMode={exploreMode}
              exploreModeMetric={METRICS_CONFIGS.find(
                (d) => d.key === exploreModeMetric
              )}
              onHover={hoverGlobe}
              loadAllTextures={loadAllTextures}
              setAllTexturesInitialized={setAllTexturesInitialized}
              setTextureProgress={setTextureProgress}
            />
          </Suspense>

          {polygons}
        </animated.group>
        {globeHierId && Cities[globeHierId] && (
          <CityLabelLine
            city={Cities[globeHierId]}
            countryOffset={countryOffset}
          />
        )}
      </Canvas>
      <LegendPanel
        exploreMode={exploreMode}
        selectedMetric={selectedMetric}
        hideLegend={hideLegend}
        exploreModeMetric={exploreModeMetric}
        setExploreModeMetric={setExploreModeMetric}
        countryLookup={countryLookup}
        exploreModeCountry={exploreModeCountry}
        setExploreModeCountry={setExploreModeCountry}
        colorScale={colorScale}
        valueSelectedCountry={valueSelectedCountry}
      />
      {hoveredCountry &&
        hoveredCountryConfig?.code !== selectedCountryConfig?.code &&
        !hideTooltipsDuringCameraTransition && (
          <CountryTooltipControl
            isMobile={isMobile}
            country={hoveredCountryConfig}
            scenario={scenario}
            cameraRef={cameraRef}
            width={width}
            height={height}
            setExploreModeCountry={setExploreModeCountry}
            dataTooltip={dataHoveredCountry}
            metric={selectedMetric}
            tooltipValue={valueHoveredCountry}
          />
        )}
      {exploreModeCountry && !hideTooltipsDuringCameraTransition && (
        <CountryTooltipControl
          isMobile={isMobile}
          tooltipViaSelect={exploreModeCountry}
          country={selectedCountryConfig}
          scenario={scenario}
          cameraRef={cameraRef}
          width={width}
          height={height}
          setExploreModeCountry={setExploreModeCountry}
          dataTooltip={dataSelectedCountry}
          metric={selectedMetric}
          tooltipValue={valueSelectedCountry}
        />
      )}
      {mouseAdvanceNotice}
    </div>
  );
}
