import "./News.scss";
import { Link, useParams } from "react-router-dom";
import DWChart from "react-datawrapper-chart";

const typeColors = {
  "Data Viz": "#A2DAF3",
  News: "#A2DAF3",
};
const AnimatedLink = ({ children, ...props }) => {
  return (
    <Link {...props} className="animatedLink">
      {children}
    </Link>
  );
};

const newsItems = [
  {
    title:
      "Human Climate Horizons helps us make better choices about the future we want",
    text: "Climate change will have a massive impact on people worldwide and potentially spark a significant expansion of inequalities over the coming years and decades.  As an open public good, the Human Climate Horizons allows everyone to see the stark reality of how climate change could impact their lives and communities and helps understand the human consequences of climate change under different scenarios.",
    date: "Oct 28th, 2022",
    internalLink: "/news/empowering_people",
    slug: "empowering_people",
    image: "Story_preview_img.jpg",
    imageStyle: { backgroundPosition: "bottom" },
    type: "Data Viz",
    content: (
      <>
        <p>
          Climate change will have a massive impact on people worldwide and
          potentially spark a significant expansion of inequalities over the
          coming years and decades. As an open public good, the Human Climate
          Horizons allows everyone to see the stark reality of how climate
          change could impact their lives and communities and helps understand
          the human consequences of climate change under different scenarios.
        </p>
        <p>
          Fortunately, our future is not predetermined. Concerted global
          progress towards Paris Agreement targets could reduce projected
          mortality from extreme heat in 2100 by more than 80%, potentially
          saving tens of millions of lives over the next decades.
        </p>
        <p>
          Human Climate Horizons helps us make better choices about the future
          we want and empowers everyone with information to play a role in
          changing the conversation, globally and locally. The new data shows
          that the world needs to act quickly, not only through efforts to
          mitigate climate change but also to adapt to its consequences.
        </p>
        <p>
          For example, in Dhaka, the capital of{" "}
          <AnimatedLink to="/country/BGD/mortality/8.5">
            Bangladesh
          </AnimatedLink>
          , under a scenario of very high emissions, an additional 132 people in
          every 100,000 could die each year by 2100 compared to a future with no
          climate change. To put this in context, this figure is double
          Bangladesh’s 2019 death rate from all cancers each year and 9 times
          greater than the rate of road traffic fatalities (figure below).
        </p>
        <p>
          <DWChart src="https://datawrapper.dwcdn.net/cyi0c/1/" />
        </p>
        <p>
          Similar patterns are seen even in countries better prepared like{" "}
          <AnimatedLink to="/country/JPN/mortality/8.5">Japan</AnimatedLink>.
          Under the same scenario in Tokyo, the effect of climate change on
          death rates at the end of the century is twice as lethal as kidney
          disease is in Japan currently.
        </p>
        <p>
          <DWChart src="https://datawrapper.dwcdn.net/qNHMM/2/" />{" "}
        </p>
        <p>
          A comparison of the health impacts of climate change across countries
          offers an even more extreme picture: a likely intensification of
          existing inequality with irreversible effects. In Helsinki,{" "}
          <AnimatedLink to="/country/FIN/mortality/8.5">Finland</AnimatedLink>,
          death rates are predicted to fall dramatically but predicted to rise
          by a similar rate across most of{" "}
          <AnimatedLink to="/country/SDN/mortality/8.5">Sudan</AnimatedLink>.{" "}
        </p>
        <p>
          Some countries will also experience greater within-country
          inequalities because of climate change. In Barranquilla, a port city
          in northern{" "}
          <AnimatedLink to="/country/COL/mortality/8.5">Colombia</AnimatedLink>{" "}
          for example, under a scenario of very high emissions, an additional 37
          people in every 100,000 could die as a result of warmer temperatures
          each year by 2100. This figure is 5 times greater than Colombia’s
          death rate from breast cancer each year. While in some of the
          Colombian cities that have colder winters, like the capital Bogotá,
          death rates are expected to fall.
        </p>
        <p>
          <DWChart src="https://datawrapper.dwcdn.net/1gJbg/2/" />
        </p>
        <p>
          <img
            src={`${process.env.PUBLIC_URL}/images/Colombia_img.PNG`}
            alt="Colombia"
          />
        </p>
      </>
    ),
  },
  // {
  //   title: 'Aenean Nonaliquam',
  //   text: 'Aenean non dolor in ipsum hendrerit tempus at at ex. Morbi et purus magna. Aenean dictum lacus nec rutrum fringilla. Cras mauris diam, lacinia at consequat eget, commodo malesuada mauris. Cras porttitor cursus nibh, a commodo justo consequat nec. Fusce semper libero non dui pharetra blandit. Suspendisse massa leo, commodo nec lorem eu, laoreet blandit dolor. Sed luctus odio ac ipsum feugiat, ac consectetur est feugiat. In sollicitudin orci porttitor sollicitudin sollicitudin.',
  //   date: 'May 25th, 2022',
  //   internalLink: '/country/IND',
  //   image: 'newsImage2.jpg',
  //   type: 'Data Viz',
  // },

  // {
  //   title: 'Aenean Nonaliquam',
  //   text: 'Aenean non dolor in ipsum hendrerit tempus at at ex. Morbi et purus magna. Aenean dictum lacus nec rutrum fringilla. Cras mauris diam, lacinia at consequat eget, commodo malesuada mauris. Cras porttitor cursus nibh, a commodo justo consequat nec. Fusce semper libero non dui pharetra blandit. Suspendisse massa leo, commodo nec lorem eu, laoreet blandit dolor. Sed luctus odio ac ipsum feugiat, ac consectetur est feugiat. In sollicitudin orci porttitor sollicitudin sollicitudin.',
  //   date: 'May 24th, 2022',
  //   internalLink: '/country/IND',
  //   image: 'newsImage2.jpg',
  //   type: 'Data Viz',
  // },
];

export default function News(props) {
  const slug = useParams().slug;
  if (slug) {
    return <NewsItem slug={slug} />;
  } else {
    return <NewsList />;
  }
}
function NewsItem(props) {
  const { slug } = props;
  const item = newsItems.find((item) => item.slug === slug);
  return (
    <div className="News">
      <div className="breadcrumbs" style={{ maxWidth: "1100px" }}>
        <Link to="/">Home</Link>
        <span>/</span>
        <span>NEWS and Stories</span>
      </div>
      <div className="headerContainer">
        <h2 className="pageHeaderFont">{item.title} </h2>
      </div>
      <div className="NewsItem">{item.content}</div>
    </div>
  );
}

function NewsList(props) {
  return (
    <div className="News">
      <div className="breadcrumbs" style={{ maxWidth: "1100px" }}>
        <Link to="/">Home</Link>
        <span>/</span>
        <span>NEWS and Stories</span>
      </div>
      <div className="headerContainer">
        <h2 className="pageHeaderFont">News and Stories</h2>
      </div>
      <div className="News__items">
        {newsItems.map((item, itemIndex) => {
          const LinkTag = item.internalLink ? Link : "a";
          const linkAttributes = item.internalLink
            ? { to: item.internalLink }
            : { href: item.externalLink };
          return (
            <div key={itemIndex} className="News__item">
              {/* <div className='News__item__image' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/${item.image})`}} /> */}
              {/* <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt={item.title} /> */}
              <LinkTag {...linkAttributes}>
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${item.image})`,
                    ...item.imageStyle,
                  }}
                />
              </LinkTag>
              <div className="content">
                <div className="date">
                  <div
                    className="type"
                    style={{ backgroundColor: typeColors[item.type] }}
                  >
                    {item.type}
                  </div>
                  {item.date}
                </div>
                <h3 className="News__item__title">
                  <LinkTag {...linkAttributes}>{item.title}</LinkTag>
                </h3>

                <p className="News__item__text">{item.text}</p>
                {item.internalLink && (
                  <Link className="readMore" to={item.internalLink}>
                    Read more <i />
                  </Link>
                )}
                {item.link && (
                  <a className="readMore" href={item.link}>
                    Read more <i />
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
