import countryLookup from "./data/countryLookup";
import { useState, useEffect } from "react";
import "./RiskComparison.scss";
import classNames from "classnames";
import { group } from "d3-array";
import { useWindowSize } from "react-use";
import { paddingLR } from "./constants";
import { Barcode, defaultRiskComparisonCountryValue } from "./Barcode";
import { METRICS_CONFIGS, SCENARIO } from "./lib/metric-constants";
import { IntroParagraph } from "./components/common/IntroParagraph";
import { CompareControls } from "./components/comparePage/CompareControls";
import { useNavigate, useParams } from "react-router-dom";
import { kebabCase } from "lodash";
import COUNTRIES_GROUPS from "../src/data/countriesGroups.json";
import { useForceRender } from "./lib/hook/useForceRender";

const titlePage = "Compare Impacts";
const descriptionPage =
  "Select a country or territory or group of locations to see future climate change projections and impacts on human development for the next 20 years, midcentury, and the end of the century. Use the Emissions Scenario menu to assess the impact of mitigation efforts.";

export default function RiskComparison({
  setGlobalCountrySelection,
  riskComparisonTooltipOpen,
  setRiskComparisonTooltipOpen,
  setLockedTooltipYearIndex,
  lockedTooltipYearIndex,
  globalCountrySelection,
  countryId: countryProp,
  gotoExplore,
}) {
  const forceRender = useForceRender();
  const groupsData = COUNTRIES_GROUPS;

  const detailParams = useParams();
  const scenarioParam = detailParams.rcp || SCENARIO.SSP126;

  const countryId = countryProp
    ? countryProp
    : riskComparisonTooltipOpen
    ? globalCountrySelection
    : defaultRiskComparisonCountryValue;

  useEffect(() => {
    if (
      countryId === defaultRiskComparisonCountryValue ||
      groupsData.map((group) => group.code).includes(countryId)
    ) {
      setRiskComparisonTooltipOpen(false);
    } else {
      setGlobalCountrySelection(countryId);
    }
  }, [
    countryId,
    setGlobalCountrySelection,
    setRiskComparisonTooltipOpen,
    groupsData,
  ]);

  const navigation = useNavigate();
  const updateNavigation = (country, scenario) => {
    navigation(`/risk/${scenario}/${country}`, { replace: true });
  };

  const setNavigation = ({
    country = countryProp ?? defaultRiskComparisonCountryValue,
    scenario = scenarioParam,
  }) => {
    updateNavigation(country, scenario);
  };

  const country = countryLookup.find((c) => c.code3 === countryId);
  const countryGroup = groupsData.find((c) => c.code === countryId);
  // console.log("counries id", countryId);
  // console.log("all coutries group", countryGroup);

  const [hoveredPoint, setHoveredPoint] = useState(null);
  // const [lockedTooltipYearIndex, setLockedTooltipYearIndex] = useState(0)
  const windowWidth = useWindowSize().width;
  let contentWidth = Math.min(windowWidth - paddingLR, 1200);
  const collapse = windowWidth < 790;
  if (collapse && windowWidth < 770) {
    contentWidth += 20;
  }
  const groupedMetrics = Array.from(
    group(METRICS_CONFIGS, (metric) => metric.group)
  );

  return (
    <div className={classNames("RiskComparison layout-page")}>
      <IntroParagraph title={titlePage} description={descriptionPage} />

      <CompareControls
        defaultRiskComparisonCountryValue={defaultRiskComparisonCountryValue}
        countryLookup={countryLookup}
        countryId={countryId}
        scenario={scenarioParam}
        setNavigation={setNavigation}
        gotoExplore={gotoExplore}
        groupsData={groupsData}
        onTemperatureChange={forceRender}
      />

      {groupedMetrics.map(([metricGroup, metrics]) => {
        return (
          <div key={kebabCase(metricGroup)} className="metric-group">
            <div className="metric-group__label">{metricGroup}</div>
            {metrics.map((metric, metricGroupIndex) => (
              <Barcode
                key={metric.key}
                group={metricGroup}
                collapse={collapse}
                country={country}
                metricGroupIndex={metricGroupIndex}
                metric={metric}
                scenario={scenarioParam}
                contentWidth={contentWidth}
                hoveredPoint={hoveredPoint}
                setHoveredPoint={setHoveredPoint}
                selectCountry={setNavigation}
                setLockedTooltipYearIndex={setLockedTooltipYearIndex}
                lockedTooltipYearIndex={lockedTooltipYearIndex}
                setRiskComparisonTooltipOpen={setRiskComparisonTooltipOpen}
                countryGroup={countryGroup}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
}
