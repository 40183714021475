import undpCountries from "./undpCountries";

const countryListAllIsoData = [
  {
    code: "AF",
    code3: "AFG",
    name: "Afghanistan",
    number: "004",
    lat: 34.79119314512794,
    lng: 67.78634845171983,
  },
  {
    code: "AL",
    code3: "ALB",
    name: "Albania",
    number: "008",
    lat: 41.24556981018838,
    lng: 20.08657229429438,
  },
  {
    code: "DZ",
    code3: "DZA",
    name: "Algeria",
    number: "012",
    lat: 29.70292660259827,
    lng: 3.2081337206814755,
  },
  {
    code: "AS",
    code3: "ASM",
    name: "American Samoa",
    number: "016",
    lat: -13.906588251517704,
    lng: -171.65085323635165,
  },
  {
    code: "AD",
    code3: "AND",
    name: "Andorra",
    number: "020",
    lat: 42.449701543734726,
    lng: -1.5455176046275647,
  },
  {
    code: "AO",
    code3: "AGO",
    name: "Angola",
    number: "024",
    lat: -10.361754288083327,
    lng: 16.90776633793736,
  },
  {
    code: "AI",
    code3: "AIA",
    name: "Anguilla",
    number: "660",
    lat: 17.117992443004493,
    lng: -64.09564258396637,
  },
  {
    code: "AQ",
    code3: "ATA",
    name: "Antarctica",
    number: "010",
    lat: 20.073570618919614,
    lng: 12.313746590860156,
  },
  {
    code: "AG",
    code3: "ATG",
    name: "Antigua and Barbuda",
    number: "028",
    lat: 16.366193293200816,
    lng: -62.832608116574875,
  },
  {
    code: "AR",
    code3: "ARG",
    name: "Argentina",
    number: "032",
    lat: -38.533208404229,
    lng: -65.31909856913695,
  },
  {
    code: "AM",
    code3: "ARM",
    name: "Armenia",
    number: "051",
    lat: 40.00731684279885,
    lng: 45.247620897261605,
  },
  {
    code: "AW",
    code3: "ABW",
    name: "Aruba",
    number: "533",
    lat: 6.3291469280729995,
    lng: -70.11666888574716,
  },
  {
    code: "AU",
    code3: "AUS",
    name: "Australia",
    number: "036",
    lat: -24.98939986742699,
    lng: 134.17235511267248,
  },
  {
    code: "AT",
    code3: "AUT",
    name: "Austria",
    number: "040",
    lat: 47.63138520898584,
    lng: 13.38733387333874,
  },
  {
    code: "AZ",
    code3: "AZE",
    name: "Azerbaijan",
    number: "031",
    lat: 40.1399663510833,
    lng: 47.045613313276,
  },
  {
    code: "BS",
    code3: "BHS",
    name: "Bahamas (the)",
    number: "044",
    lat: 25.751162973028595,
    lng: -77.8829788297883,
  },
  {
    code: "BH",
    code3: "BHR",
    name: "Bahrain",
    number: "048",
    lat: 23.746164175390533,
    lng: 46.33542161309003,
  },
  {
    code: "BD",
    code3: "BGD",
    name: "Bangladesh",
    number: "050",
    lat: 23.437580438725448,
    lng: 90.5522712369981,
  },
  {
    code: "BB",
    code3: "BRB",
    name: "Barbados",
    number: "052",
    lat: 13.174068376509416,
    lng: -59.574249166006396,
  },
  {
    code: "BY",
    code3: "BLR",
    name: "Belarus",
    number: "112",
    lat: 53.310819178287815,
    lng: 28.22231478128736,
  },
  {
    code: "BE",
    code3: "BEL",
    name: "Belgium",
    number: "056",
    lat: 50.61968473769963,
    lng: 4.469444694446951,
  },
  {
    code: "BZ",
    code3: "BLZ",
    name: "Belize",
    number: "084",
    lat: 17.396426460886527,
    lng: -88.60304392517607,
  },
  {
    code: "BJ",
    code3: "BEN",
    name: "Benin",
    number: "204",
    lat: 9.956347133284543,
    lng: 2.311973119731205,
  },
  {
    code: "BM",
    code3: "BMU",
    name: "Bermuda",
    number: "060",
    lat: 32.30842375814648,
    lng: -64.77769731614818,
  },
  {
    code: "BT",
    code3: "BTN",
    name: "Bhutan",
    number: "064",
    lat: 27.4424521574081,
    lng: 90.52530525305254,
  },
  {
    code: "BO",
    code3: "BOL",
    name: "Bolivia (Plurinational State of)",
    number: "068",
    lat: -16.427770358646526,
    lng: -64.03863021681067,
  },
  {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    number: "535",
    lat: 15.051118250158988,
    lng: -65.22907203714244,
  },
  {
    code: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    number: "070",
    lat: 44.226165510656045,
    lng: 17.83894981806961,
  },
  {
    code: "BW",
    code3: "BWA",
    name: "Botswana",
    number: "072",
    lat: -22.48645342936388,
    lng: 24.344228057665198,
  },
  {
    code: "BV",
    code3: "BVT",
    name: "Bouvet Island",
    number: "074",
    lat: -54.43666148255774,
    lng: 3.364557713216467,
  },
  {
    code: "BR",
    code3: "BRA",
    name: "Brazil",
    number: "076",
    lat: -9.537083516873354,
    lng: -56.94383676510036,
  },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory (the)",
    number: "086",
    lat: -6.690441979953891,
    lng: 71.967564781644,
  },
  {
    code: "BN",
    code3: "BRN",
    name: "Brunei Darussalam",
    number: "096",
    lat: 4.6430228367888935,
    lng: 114.93397791120769,
  },
  {
    code: "BG",
    code3: "BGR",
    name: "Bulgaria",
    number: "100",
    lat: 42.844186933367205,
    lng: 24.762580959142934,
  },
  {
    code: "BF",
    code3: "BFA",
    name: "Burkina Faso",
    number: "854",
    lat: 12.157289468335797,
    lng: -1.824555087656133,
  },
  {
    code: "BI",
    code3: "BDI",
    name: "Burundi",
    number: "108",
    lat: -3.3020285928162054,
    lng: 30.00660006600067,
  },
  {
    code: "CV",
    code3: "CPV",
    name: "Cabo Verde",
    number: "132",
    lat: 16.010821662893893,
    lng: -24.00159914659339,
  },
  {
    code: "KH",
    code3: "KHM",
    name: "Cambodia",
    number: "116",
    lat: 12.669694882613605,
    lng: 105.03960039600395,
  },
  {
    code: "CM",
    code3: "CMR",
    name: "Cameroon",
    number: "120",
    lat: 6.807031549374727,
    lng: 13.146671466714677,
  },
  {
    code: "CA",
    code3: "CAN",
    name: "Canada",
    number: "124",
    lat: 65.07175920992294,
    lng: -90.69129088137126,
  },
  {
    code: "KY",
    code3: "CYM",
    name: "Cayman Islands (the)",
    number: "136",
    lat: 21.756001576459607,
    lng: -79.6652583036361,
  },
  {
    code: "CF",
    code3: "CAF",
    name: "Central African Republic (the)",
    number: "140",
    lat: 6.3116557171005025,
    lng: 20.593887906092185,
  },
  {
    code: "TD",
    code3: "TCD",
    name: "Chad",
    number: "148",
    lat: 12.880205003027362,
    lng: 17.893473671578832,
  },
  {
    code: "CL",
    code3: "CHL",
    name: "Chile",
    number: "152",
    lat: -40.92939420521328,
    lng: -71.23146366598802,
  },
  {
    code: "CN",
    code3: "CHN",
    name: "China",
    number: "156",
    lat: 36.60489414143669,
    lng: 105.93388791030775,
  },
  {
    code: "CX",
    code3: "CXR",
    name: "Christmas Island",
    number: "162",
    lat: -10.497045671217052,
    lng: 105.6153514515684,
  },
  {
    code: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands (the)",
    number: "166",
    lat: -12.179844635201333,
    lng: 96.85480940633701,
  },
  {
    code: "CO",
    code3: "COL",
    name: "Colombia",
    number: "170",
    lat: 4.523383611414841,
    lng: -72.71147256927118,
  },
  {
    code: "KM",
    code3: "COM",
    name: "Comoros (the)",
    number: "174",
    lat: -12.23614510660856,
    lng: 44.177439198912495,
  },
  {
    code: "CD",
    code3: "COD",
    name: "Congo (the Democratic Republic of the)",
    number: "180",
    lat: -3.8312643913072395,
    lng: 22.948905522113087,
  },
  {
    code: "CG",
    code3: "COG",
    name: "Congo (the)",
    number: "178",
    lat: -1.012490465433965,
    lng: 14.853448534485358,
  },
  {
    code: "CK",
    code3: "COK",
    name: "Cook Islands (the)",
    number: "184",
    lat: -16.662445180043118,
    lng: -145.12041416757066,
  },
  {
    code: "CR",
    code3: "CRI",
    name: "Costa Rica",
    number: "188",
    lat: 9.829505985570554,
    lng: -84.20797922264934,
  },
  {
    code: "HR",
    code3: "HRV",
    name: "Croatia",
    number: "191",
    lat: 44.862709083510566,
    lng: 16.378627200906166,
  },
  {
    code: "CU",
    code3: "CUB",
    name: "Cuba",
    number: "192",
    lat: 21.68704079300835,
    lng: -79.67112354050373,
  },
  {
    code: "CW",
    code3: "CUW",
    name: "Curaçao",
    number: "531",
    lat: 8.097424670738768,
    lng: -66.67011404074808,
  },
  {
    code: "CY",
    code3: "CYP",
    name: "Cyprus",
    number: "196",
    lat: 35.01622876766802,
    lng: 33.281047096185254,
  },
  {
    code: "CZ",
    code3: "CZE",
    name: "Czechia",
    number: "203",
    lat: 49.73954715237621,
    lng: 15.87266460899904,
  },
  {
    code: "CI",
    code3: "CIV",
    name: "Côte d'Ivoire",
    number: "384",
    lat: 7.894444033395432,
    lng: -6.307943079430788,
  },
  {
    code: "DK",
    code3: "DNK",
    name: "Denmark",
    number: "208",
    lat: 56.16767202661633,
    lng: 10.108573813010866,
  },
  {
    code: "DJ",
    code3: "DJI",
    name: "Djibouti",
    number: "262",
    lat: 11.747658471318104,
    lng: 42.53416819882485,
  },
  {
    code: "DM",
    code3: "DMA",
    name: "Dominica",
    number: "212",
    lat: 15.409625110408276,
    lng: -61.32694676264861,
  },
  {
    code: "DO",
    code3: "DOM",
    name: "Dominican Republic (the)",
    number: "214",
    lat: 18.753110171692057,
    lng: -70.4451444514445,
  },
  {
    code: "EC",
    code3: "ECU",
    name: "Ecuador",
    number: "218",
    lat: -1.6834136434672624,
    lng: -78.80895058950591,
  },
  {
    code: "EG",
    code3: "EGY",
    name: "Egypt",
    number: "818",
    lat: 28.249540667604098,
    lng: 31.51377228057996,
  },
  {
    code: "SV",
    code3: "SLV",
    name: "El Salvador",
    number: "222",
    lat: 13.824415151601942,
    lng: -88.89748897488974,
  },
  {
    code: "GQ",
    code3: "GNQ",
    name: "Equatorial Guinea",
    number: "226",
    lat: 1.473948646135895,
    lng: 10.139501395013957,
  },
  {
    code: "ER",
    code3: "ERI",
    name: "Eritrea",
    number: "232",
    lat: 14.722701863139921,
    lng: 39.70539705397054,
  },
  {
    code: "EE",
    code3: "EST",
    name: "Estonia",
    number: "233",
    lat: 58.5510445771742,
    lng: 25.797182971829727,
  },
  {
    code: "SZ",
    code3: "SWZ",
    name: "Eswatini",
    number: "748",
    lat: -26.38878182605533,
    lng: 31.37359373593737,
  },
  {
    code: "ET",
    code3: "ETH",
    name: "Ethiopia",
    number: "231",
    lat: 9.000406570614723,
    lng: 39.061135438940624,
  },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands (the) [Malvinas]",
    number: "238",
    lat: -51.72194585315346,
    lng: -59.40479404794049,
  },
  {
    code: "FO",
    code3: "FRO",
    name: "Faroe Islands (the)",
    number: "234",
    lat: 62.03321382932191,
    lng: -6.839506136335647,
  },
  {
    code: "FJ",
    code3: "FJI",
    name: "Fiji",
    number: "242",
    lat: -17.0365304571376,
    lng: 98.86238862388626,
  },
  {
    code: "FI",
    code3: "FIN",
    name: "Finland",
    number: "246",
    lat: 65.38008968386464,
    lng: 25.695548847380373,
  },
  {
    code: "FR",
    code3: "FRA",
    name: "France",
    number: "250",
    lat: 35.53527212640532,
    lng: -10.646903652135109,
  },
  {
    code: "GF",
    code3: "GUF",
    name: "French Guiana",
    number: "254",
    lat: -11.43518412277548,
    lng: -52.22714672491042,
  },
  {
    code: "PF",
    code3: "PYF",
    name: "French Polynesia",
    number: "258",
    lat: -13.376147300239936,
    lng: -113.17590769288985,
  },
  {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories (the)",
    number: "260",
    lat: -49.18009895508285,
    lng: 69.52659526595268,
  },
  {
    code: "GA",
    code3: "GAB",
    name: "Gabon",
    number: "266",
    lat: -0.4241429902607308,
    lng: 11.911799117991189,
  },
  {
    code: "GM",
    code3: "GMB",
    name: "Gambia (the)",
    number: "270",
    lat: 13.495346126301497,
    lng: -15.191791917919168,
  },
  {
    code: "GE",
    code3: "GEO",
    name: "Georgia",
    number: "268",
    lat: 42.207321557189786,
    lng: 43.512148164959925,
  },
  {
    code: "DE",
    code3: "DEU",
    name: "Germany",
    number: "276",
    lat: 50.996802914127755,
    lng: 10.631001046852575,
  },
  {
    code: "GH",
    code3: "GHA",
    name: "Ghana",
    number: "288",
    lat: 8.176903854981491,
    lng: -1.0119101191011832,
  },
  {
    code: "GI",
    code3: "GIB",
    name: "Gibraltar",
    number: "292",
    lat: 36.4808141050581,
    lng: -5.688861090848232,
  },
  {
    code: "GR",
    code3: "GRC",
    name: "Greece",
    number: "300",
    lat: 38.89469425799942,
    lng: 23.498058057503663,
  },
  {
    code: "GL",
    code3: "GRL",
    name: "Greenland",
    number: "304",
    lat: 74.08289742767633,
    lng: -40.987687739472804,
  },
  {
    code: "GD",
    code3: "GRD",
    name: "Grenada",
    number: "308",
    lat: 11.686990506310117,
    lng: -61.264198910969604,
  },
  {
    code: "GP",
    code3: "GLP",
    name: "Guadeloupe",
    number: "312",
    lat: 16.0603727678913,
    lng: -61.58754153768961,
  },
  {
    code: "GU",
    code3: "GUM",
    name: "Guam",
    number: "316",
    lat: 15.567734967020105,
    lng: 145.32252011287358,
  },
  {
    code: "GT",
    code3: "GTM",
    name: "Guatemala",
    number: "320",
    lat: 15.560416965772735,
    lng: -90.2121374154918,
  },
  {
    code: "GG",
    code3: "GGY",
    name: "Guernsey",
    number: "831",
    lat: 52.21062562376964,
    lng: -1.8451885995153214,
  },
  {
    code: "GN",
    code3: "GIN",
    name: "Guinea",
    number: "324",
    lat: 10.387514172565952,
    lng: -10.945231191442346,
  },
  {
    code: "GW",
    code3: "GNB",
    name: "Guinea-Bissau",
    number: "624",
    lat: 11.956716324839183,
    lng: -15.132751327513265,
  },
  {
    code: "GY",
    code3: "GUY",
    name: "Guyana",
    number: "328",
    lat: 4.547830365010288,
    lng: -58.95903574420359,
  },
  {
    code: "HT",
    code3: "HTI",
    name: "Haiti",
    number: "332",
    lat: 18.795678020565855,
    lng: -72.75168751687515,
  },
  {
    code: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    number: "334",
    lat: -53.08863638366972,
    lng: 73.4167399168641,
  },
  {
    code: "VA",
    code3: "VAT",
    name: "Holy See (the)",
    number: "336",
    lat: 26.099762393331996,
    lng: 9.682419965853697,
  },
  {
    code: "HN",
    code3: "HND",
    name: "Honduras",
    number: "340",
    lat: 14.741794640612435,
    lng: -86.3644779304936,
  },
  {
    code: "HK",
    code3: "HKG",
    name: "Hong Kong",
    number: "344",
    lat: 34.06204887576848,
    lng: 109.2297764097097,
  },
  {
    code: "HU",
    code3: "HUN",
    name: "Hungary",
    number: "348",
    lat: 47.43295151895186,
    lng: 19.150391503915042,
  },
  {
    code: "IS",
    code3: "ISL",
    name: "Iceland",
    number: "352",
    lat: 65.21601743473055,
    lng: -19.51664779805692,
  },
  {
    code: "IN",
    code3: "IND",
    name: "India",
    number: "356",
    lat: 23.93380638201089,
    lng: 83.4863148631486,
  },
  {
    code: "ID",
    code3: "IDN",
    name: "Indonesia",
    number: "360",
    lat: -3.1457400881116966,
    lng: 119.48492902650527,
  },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran (Islamic Republic of)",
    number: "364",
    lat: 33.34882981925041,
    lng: 53.40916111863822,
  },
  {
    code: "IQ",
    code3: "IRQ",
    name: "Iraq",
    number: "368",
    lat: 33.48271230895944,
    lng: 44.578190609492296,
  },
  {
    code: "IE",
    code3: "IRL",
    name: "Ireland",
    number: "372",
    lat: 53.50362325221274,
    lng: -7.85077850778507,
  },
  {
    code: "IM",
    code3: "IMN",
    name: "Isle of Man",
    number: "833",
    lat: 55.001051954352924,
    lng: -5.090784434650409,
  },
  {
    code: "IL",
    code3: "ISR",
    name: "Israel",
    number: "376",
    lat: 32.032174360489186,
    lng: 35.16209944708144,
  },
  {
    code: "IT",
    code3: "ITA",
    name: "Italy",
    number: "380",
    lat: 42.05604343759105,
    lng: 12.45303881610245,
  },
  {
    code: "JM",
    code3: "JAM",
    name: "Jamaica",
    number: "388",
    lat: 18.157498799179525,
    lng: -77.32553325533254,
  },
  {
    code: "JP",
    code3: "JPN",
    name: "Japan",
    number: "392",
    lat: 37.14003492352963,
    lng: 137.03653165563912,
  },
  {
    code: "JE",
    code3: "JEY",
    name: "Jersey",
    number: "832",
    lat: 46.32061949136436,
    lng: 2.0393893645018464,
  },
  {
    code: "JO",
    code3: "JOR",
    name: "Jordan",
    number: "400",
    lat: 31.062505901317966,
    lng: 36.71236712367123,
  },
  {
    code: "KZ",
    code3: "KAZ",
    name: "Kazakhstan",
    number: "398",
    lat: 47.246230283625366,
    lng: 65.05305593596476,
  },
  {
    code: "KE",
    code3: "KEN",
    name: "Kenya",
    number: "404",
    lat: 1.2754491343784582,
    lng: 37.78547785477856,
  },
  {
    code: "KI",
    code3: "KIR",
    name: "Kiribati",
    number: "296",
    lat: -14.505521612553652,
    lng: 19.259610032320822,
  },
  {
    code: "KP",
    code3: "PRK",
    name: "Korea (the Democratic People's Republic of)",
    number: "408",
    lat: 39.98588172635805,
    lng: 127.48563485634861,
  },
  {
    code: "KR",
    code3: "KOR",
    name: "Korea (the Republic of)",
    number: "410",
    lat: 36.659421078402836,
    lng: 127.53927539275395,
  },
  {
    code: "KW",
    code3: "KWT",
    name: "Kuwait",
    number: "414",
    lat: 29.256663410354598,
    lng: 47.713977139771416,
  },
  {
    code: "KG",
    code3: "KGZ",
    name: "Kyrgyzstan",
    number: "417",
    lat: 41.33723821521758,
    lng: 74.06534065340654,
  },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic (the)",
    number: "418",
    lat: 18.397089872584523,
    lng: 103.59033590335902,
  },
  {
    code: "LV",
    code3: "LVA",
    name: "Latvia",
    number: "428",
    lat: 56.82839268486508,
    lng: 24.827991137054234,
  },
  {
    code: "LB",
    code3: "LBN",
    name: "Lebanon",
    number: "422",
    lat: 33.850225145832596,
    lng: 35.855278552785535,
  },
  {
    code: "LS",
    code3: "LSO",
    name: "Lesotho",
    number: "426",
    lat: -29.641971783037818,
    lng: 28.315101332831524,
  },
  {
    code: "LR",
    code3: "LBR",
    name: "Liberia",
    number: "430",
    lat: 6.718081084928849,
    lng: -9.169845544609286,
  },
  {
    code: "LY",
    code3: "LBY",
    name: "Libya",
    number: "434",
    lat: 28.594538331455205,
    lng: 16.486103042848615,
  },
  {
    code: "LI",
    code3: "LIE",
    name: "Liechtenstein",
    number: "438",
    lat: 46.228238207917464,
    lng: 11.47397569258466,
  },
  {
    code: "LT",
    code3: "LTU",
    name: "Lithuania",
    number: "440",
    lat: 55.17779878849188,
    lng: 23.878438784387853,
  },
  {
    code: "LU",
    code3: "LUX",
    name: "Luxembourg",
    number: "442",
    lat: 49.7592604819915,
    lng: 5.971859718597192,
  },
  {
    code: "MO",
    code3: "MAC",
    name: "Macao",
    number: "446",
    lat: 34.06204887576848,
    lng: 109.2297764097097,
  },
  {
    code: "MG",
    code3: "MDG",
    name: "Madagascar",
    number: "450",
    lat: -18.0858580218075,
    lng: 46.93606936069361,
  },
  {
    code: "MW",
    code3: "MWI",
    name: "Malawi",
    number: "454",
    lat: -13.050617633646395,
    lng: 34.11094110941111,
  },
  {
    code: "MY",
    code3: "MYS",
    name: "Malaysia",
    number: "458",
    lat: 3.905365498442441,
    lng: 109.67227672276731,
  },
  {
    code: "MV",
    code3: "MDV",
    name: "Maldives",
    number: "462",
    lat: 3.6688760244495455,
    lng: 73.18601411072359,
  },
  {
    code: "ML",
    code3: "MLI",
    name: "Mali",
    number: "466",
    lat: 14.10132724119045,
    lng: -5.72566525665256,
  },
  {
    code: "MT",
    code3: "MLT",
    name: "Malta",
    number: "470",
    lat: 41.702633682114225,
    lng: 12.39430439187085,
  },
  {
    code: "MH",
    code3: "MHL",
    name: "Marshall Islands (the)",
    number: "584",
    lat: 7.874249058325525,
    lng: 160.3975727522909,
  },
  {
    code: "MQ",
    code3: "MTQ",
    name: "Martinique",
    number: "474",
    lat: 14.806255996820818,
    lng: -61.111638232223186,
  },
  {
    code: "MR",
    code3: "MRT",
    name: "Mauritania",
    number: "478",
    lat: 18.774347328062287,
    lng: -12.30302829344082,
  },
  {
    code: "MU",
    code3: "MUS",
    name: "Mauritius",
    number: "480",
    lat: -19.23218986444523,
    lng: 58.461899473990925,
  },
  {
    code: "YT",
    code3: "MYT",
    name: "Mayotte",
    number: "175",
    lat: -12.23614510660856,
    lng: 44.177439198912495,
  },
  {
    code: "MX",
    code3: "MEX",
    name: "Mexico",
    number: "484",
    lat: 23.823428860090406,
    lng: -103.04795237301487,
  },
  {
    code: "FM",
    code3: "FSM",
    name: "Micronesia (Federated States of)",
    number: "583",
    lat: -3.565154546507361,
    lng: 126.95469486197209,
  },
  {
    code: "MD",
    code3: "MDA",
    name: "Moldova (the Republic of)",
    number: "498",
    lat: 47.00156172990109,
    lng: 28.541639262546482,
  },
  {
    code: "MC",
    code3: "MCO",
    name: "Monaco",
    number: "492",
    lat: 43.86588115810019,
    lng: 7.597144267897078,
  },
  {
    code: "MN",
    code3: "MNG",
    name: "Mongolia",
    number: "496",
    lat: 47.176255946411935,
    lng: 104.81305353594075,
  },
  {
    code: "ME",
    code3: "MNE",
    name: "Montenegro",
    number: "499",
    lat: 42.75042508672903,
    lng: 19.409594095940964,
  },
  {
    code: "MS",
    code3: "MSR",
    name: "Montserrat",
    number: "500",
    lat: 16.248076079365365,
    lng: -62.649391808412,
  },
  {
    code: "MA",
    code3: "MAR",
    name: "Morocco",
    number: "504",
    lat: 28.666649765932597,
    lng: -9.543056720889785,
  },
  {
    code: "MZ",
    code3: "MOZ",
    name: "Mozambique",
    number: "508",
    lat: -17.996733304912965,
    lng: 34.86952237943433,
  },
  {
    code: "MM",
    code3: "MMR",
    name: "Myanmar",
    number: "104",
    lat: 19.994427745408785,
    lng: 97.11037110371105,
  },
  {
    code: "NA",
    code3: "NAM",
    name: "Namibia",
    number: "516",
    lat: -21.48625827822457,
    lng: 17.88292766648597,
  },
  {
    code: "NR",
    code3: "NRU",
    name: "Nauru",
    number: "520",
    lat: -0.5382185206226197,
    lng: 166.912777624923,
  },
  {
    code: "NP",
    code3: "NPL",
    name: "Nepal",
    number: "524",
    lat: 28.224221895942552,
    lng: 84.39646214643963,
  },
  {
    code: "NL",
    code3: "NLD",
    name: "Netherlands (the)",
    number: "528",
    lat: 52.05456524333316,
    lng: 5.548347791170224,
  },
  {
    code: "NC",
    code3: "NCL",
    name: "New Caledonia",
    number: "540",
    lat: -21.185884888722782,
    lng: 165.48915489154896,
  },
  {
    code: "NZ",
    code3: "NZL",
    name: "New Zealand",
    number: "554",
    lat: -40.588433468515966,
    lng: 173.44011565115656,
  },
  {
    code: "NI",
    code3: "NIC",
    name: "Nicaragua",
    number: "558",
    lat: 13.120826051034799,
    lng: -85.0035912123827,
  },
  {
    code: "NE",
    code3: "NER",
    name: "Niger (the)",
    number: "562",
    lat: 15.348608714918296,
    lng: 8.630454725599893,
  },
  {
    code: "NG",
    code3: "NGA",
    name: "Nigeria",
    number: "566",
    lat: 9.792324842316907,
    lng: 8.447737108950044,
  },
  {
    code: "NU",
    code3: "NIU",
    name: "Niue",
    number: "570",
    lat: -19.069164005373565,
    lng: -169.8841225768011,
  },
  {
    code: "NF",
    code3: "NFK",
    name: "Norfolk Island",
    number: "574",
    lat: -29.043735281116064,
    lng: 167.93521076393898,
  },
  {
    code: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands (the)",
    number: "580",
    lat: 16.542075412858118,
    lng: 145.5774809239182,
  },
  {
    code: "NO",
    code3: "NOR",
    name: "Norway",
    number: "578",
    lat: 71.49592419577569,
    lng: 18.311497400688307,
  },
  {
    code: "OM",
    code3: "OMN",
    name: "Oman",
    number: "512",
    lat: 21.398353594219547,
    lng: 56.56852568525686,
  },
  {
    code: "PK",
    code3: "PAK",
    name: "Pakistan",
    number: "586",
    lat: 30.718015253551073,
    lng: 69.3144562214853,
  },
  {
    code: "PW",
    code3: "PLW",
    name: "Palau",
    number: "585",
    lat: 7.342634397860663,
    lng: 134.4467148344097,
  },
  {
    code: "PS",
    code3: "PSE",
    name: "Palestine, State of",
    number: "275",
    lat: 31.84881691135757,
    lng: 35.220952209522096,
  },
  {
    code: "PA",
    code3: "PAN",
    name: "Panama",
    number: "591",
    lat: 8.492474769088027,
    lng: -80.37556846156697,
  },
  {
    code: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
    number: "598",
    lat: -6.228127203448669,
    lng: 149.63374309067768,
  },
  {
    code: "PY",
    code3: "PRY",
    name: "Paraguay",
    number: "600",
    lat: -23.399681018605918,
    lng: -57.55130051300514,
  },
  {
    code: "PE",
    code3: "PER",
    name: "Peru",
    number: "604",
    lat: -7.799230146075502,
    lng: -74.13782137821381,
  },
  {
    code: "PH",
    code3: "PHL",
    name: "Philippines (the)",
    number: "608",
    lat: 11.473708119179909,
    lng: 122.81583980888354,
  },
  {
    code: "PN",
    code3: "PCN",
    name: "Pitcairn",
    number: "612",
    lat: -24.371300497927542,
    lng: -129.3026862125131,
  },
  {
    code: "PL",
    code3: "POL",
    name: "Poland",
    number: "616",
    lat: 51.695585991444275,
    lng: 19.384884757938494,
  },
  {
    code: "PT",
    code3: "PRT",
    name: "Portugal",
    number: "620",
    lat: 39.69805743430977,
    lng: -8.007267572675719,
  },
  {
    code: "PR",
    code3: "PRI",
    name: "Puerto Rico",
    number: "630",
    lat: 18.24635812585445,
    lng: -66.45216452164522,
  },
  {
    code: "QA",
    code3: "QAT",
    name: "Qatar",
    number: "634",
    lat: 25.319983668829757,
    lng: 51.194311943119445,
  },
  {
    code: "MK",
    code3: "MKD",
    name: "Republic of North Macedonia",
    number: "807",
    lat: 41.63296927265639,
    lng: 21.64409144091442,
  },
  {
    code: "RO",
    code3: "ROU",
    name: "Romania",
    number: "642",
    lat: 45.86545069137023,
    lng: 25.117744200697825,
  },
  {
    code: "RU",
    code3: "RUS",
    name: "Russian Federation (the)",
    number: "643",
    lat: 61.11578528016226,
    lng: 76.69984459041918,
  },
  {
    code: "RW",
    code3: "RWA",
    name: "Rwanda",
    number: "646",
    lat: -2.0461501091438534,
    lng: 29.843698436984372,
  },
  {
    code: "RE",
    code3: "REU",
    name: "Réunion",
    number: "638",
    lat: -21.14357040375998,
    lng: 55.512196611722445,
  },
  {
    code: "BL",
    code3: "BLM",
    name: "Saint Barthélemy",
    number: "652",
    lat: 16.4746042378677,
    lng: -63.64022411719711,
  },
  {
    code: "SH",
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    number: "654",
    lat: -13.26995397984514,
    lng: -51.706521360576055,
  },
  {
    code: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    number: "659",
    lat: 16.366193293200816,
    lng: -62.832608116574875,
  },
  {
    code: "LC",
    code3: "LCA",
    name: "Saint Lucia",
    number: "662",
    lat: 13.832291342320543,
    lng: -61.03437418067184,
  },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    number: "663",
    lat: 17.054002343890627,
    lng: -64.39637164698378,
  },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    number: "666",
    lat: 64.52866309470342,
    lng: -89.82392431463404,
  },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    number: "670",
    lat: 11.686990506310117,
    lng: -61.264198910969604,
  },
  {
    code: "WS",
    code3: "WSM",
    name: "Samoa",
    number: "882",
    lat: -13.906588251517704,
    lng: -171.65085323635165,
  },
  {
    code: "SM",
    code3: "SMR",
    name: "San Marino",
    number: "674",
    lat: 42.7499197195573,
    lng: 13.751045966402787,
  },
  {
    code: "ST",
    code3: "STP",
    name: "Sao Tome and Principe",
    number: "678",
    lat: 0.628173629412946,
    lng: 6.816557375166073,
  },
  {
    code: "SA",
    code3: "SAU",
    name: "Saudi Arabia",
    number: "682",
    lat: 23.969214707992595,
    lng: 43.70921309213093,
  },
  {
    code: "SN",
    code3: "SEN",
    name: "Senegal",
    number: "686",
    lat: 13.884162263132048,
    lng: -14.542010536384428,
  },
  {
    code: "RS",
    code3: "SRB",
    name: "Serbia",
    number: "688",
    lat: 43.78387039499488,
    lng: 20.986729867298678,
  },
  {
    code: "SC",
    code3: "SYC",
    name: "Seychelles",
    number: "690",
    lat: -24.22519528559629,
    lng: 51.942506271959,
  },
  {
    code: "SL",
    code3: "SLE",
    name: "Sierra Leone",
    number: "694",
    lat: 8.589102323414382,
    lng: -11.62905914773433,
  },
  {
    code: "SG",
    code3: "SGP",
    name: "Singapore",
    number: "702",
    lat: -1.9974833335219793,
    lng: 118.30628483841869,
  },
  {
    code: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    number: "534",
  },
  {
    code: "SK",
    code3: "SVK",
    name: "Slovakia",
    number: "703",
    lat: 48.753933564068625,
    lng: 19.25186751867519,
  },
  {
    code: "SI",
    code3: "SVN",
    name: "Slovenia",
    number: "705",
    lat: 46.0833716635021,
    lng: 15.065409477624193,
  },
  {
    code: "SB",
    code3: "SLB",
    name: "Solomon Islands",
    number: "090",
    lat: -8.74000781549654,
    lng: 159.759247592476,
  },
  {
    code: "SO",
    code3: "SOM",
    name: "Somalia",
    number: "706",
    lat: 6.650203905071709,
    lng: 46.990704192756226,
  },
  {
    code: "ZA",
    code3: "ZAF",
    name: "South Africa",
    number: "710",
    lat: -28.728343032362364,
    lng: 25.240556753393626,
  },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    number: "239",
    lat: -55.10258212228781,
    lng: -34.86360033162059,
  },
  {
    code: "SS",
    code3: "SSD",
    name: "South Sudan",
    number: "728",
    lat: 8.082379916851858,
    lng: 30.080565321782256,
  },
  {
    code: "ES",
    code3: "ESP",
    name: "Spain",
    number: "724",
    lat: 40.236891928473526,
    lng: -4.390603906039055,
  },
  {
    code: "LK",
    code3: "LKA",
    name: "Sri Lanka",
    number: "144",
    lat: 7.643606848381631,
    lng: 80.76140761407616,
  },
  {
    code: "SD",
    code3: "SDN",
    name: "Sudan (the)",
    number: "729",
    lat: 12.865185401298453,
    lng: 29.397218972189723,
  },
  {
    code: "SR",
    code3: "SUR",
    name: "Suriname",
    number: "740",
    lat: 3.7529788055224476,
    lng: -55.924183241832424,
  },
  {
    code: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    number: "744",
    lat: 68.65737144375366,
    lng: 15.326712500642747,
  },
  {
    code: "SE",
    code3: "SWE",
    name: "Sweden",
    number: "752",
    lat: 62.83898447756361,
    lng: 16.705136282132056,
  },
  {
    code: "CH",
    code3: "CHE",
    name: "Switzerland",
    number: "756",
    lat: 46.76501392844043,
    lng: 8.250578157955497,
  },
  {
    code: "SY",
    code3: "SYR",
    name: "Syrian Arab Republic",
    number: "760",
    lat: 35.239971995346934,
    lng: 38.03618036180362,
  },
  {
    code: "TW",
    code3: "TWN",
    name: "Taiwan",
    number: "158",
    lat: 23.7947767974022,
    lng: 121.0210602106021,
  },
  {
    code: "TJ",
    code3: "TJK",
    name: "Tajikistan",
    number: "762",
    lat: 38.547159898009085,
    lng: 70.86076860768607,
  },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania, United Republic of",
    number: "834",
    lat: -6.630938749194573,
    lng: 34.3519185191852,
  },
  {
    code: "TH",
    code3: "THA",
    name: "Thailand",
    number: "764",
    lat: 13.163470310000545,
    lng: 100.58909160520176,
  },
  {
    code: "TL",
    code3: "TLS",
    name: "Timor-Leste",
    number: "626",
    lat: -8.730656429213127,
    lng: 125.99909999099995,
  },
  {
    code: "TG",
    code3: "TGO",
    name: "Togo",
    number: "768",
    lat: 8.767653823061119,
    lng: 0.8682086820868307,
  },
  {
    code: "TK",
    code3: "TKL",
    name: "Tokelau",
    number: "772",
    lat: 26.099762393331996,
    lng: 9.682419965853697,
  },
  {
    code: "TO",
    code3: "TON",
    name: "Tonga",
    number: "776",
    lat: -19.656585604157556,
    lng: -174.7198362516834,
  },
  {
    code: "TT",
    code3: "TTO",
    name: "Trinidad and Tobago",
    number: "780",
    lat: 10.43882708277008,
    lng: -61.428700001285726,
  },
  {
    code: "TN",
    code3: "TUN",
    name: "Tunisia",
    number: "788",
    lat: 34.14032969044369,
    lng: 9.853538535385363,
  },
  {
    code: "TR",
    code3: "TUR",
    name: "Türkiye",
    number: "792",
    lat: 38.98176337845928,
    lng: 34.71451022202531,
  },
  {
    code: "TM",
    code3: "TKM",
    name: "Turkmenistan",
    number: "795",
    lat: 39.283743837891784,
    lng: 58.56908757766824,
  },
  {
    code: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands (the)",
    number: "796",
    lat: 22.816877781545994,
    lng: -75.24552428117651,
  },
  {
    code: "TV",
    code3: "TUV",
    name: "Tuvalu",
    number: "798",
    lat: -7.155780762640816,
    lng: 177.91539963430722,
  },
  {
    code: "UG",
    code3: "UGA",
    name: "Uganda",
    number: "800",
    lat: 1.34349143686369,
    lng: 31.91916534549962,
  },
  {
    code: "UA",
    code3: "UKR",
    name: "Ukraine",
    number: "804",
    lat: 48.64982511225963,
    lng: 30.436582626695834,
  },
  {
    code: "AE",
    code3: "ARE",
    name: "United Arab Emirates (the)",
    number: "784",
    lat: 24.192961283589653,
    lng: 54.37022941657989,
  },
  {
    code: "GB",
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    number: "826",
    lat: 53.98313450764004,
    lng: -3.5745024116907764,
  },
  {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands (the)",
    number: "581",
    lat: 40.75965736419594,
    lng: -41.218618525771774,
  },
  {
    code: "US",
    code3: "USA",
    name: "United States of America (the)",
    number: "840",
    lat: 45.115804131976624,
    lng: -120.63440865355547,
  },
  {
    code: "UY",
    code3: "URY",
    name: "Uruguay",
    number: "858",
    lat: -32.82177393766946,
    lng: -56.063900639006384,
  },
  {
    code: "UZ",
    code3: "UZB",
    name: "Uzbekistan",
    number: "860",
    lat: 41.14974179546334,
    lng: 65.4647187981314,
  },
  {
    code: "VU",
    code3: "VUT",
    name: "Vanuatu",
    number: "548",
    lat: -15.709583318332012,
    lng: 167.12087120871212,
  },
  {
    code: "VE",
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)",
    number: "862",
    lat: 7.235613427101525,
    lng: -66.91883402350506,
  },
  {
    code: "VN",
    code3: "VNM",
    name: "Viet Nam",
    number: "704",
    lat: 16.850076001761515,
    lng: 105.9758923170627,
  },
  {
    code: "VG",
    code3: "VGB",
    name: "Virgin Islands (British)",
    number: "092",
    lat: 18.218508916762918,
    lng: -65.79968657699806,
  },
  {
    code: "VI",
    code3: "VIR",
    name: "Virgin Islands (U.S.)",
    number: "850",
    lat: 18.218508916762918,
    lng: -65.79968657699806,
  },
  {
    code: "WF",
    code3: "WLF",
    name: "Wallis and Futuna",
    number: "876",
    lat: -13.838292348919467,
    lng: -177.22697848318174,
  },
  {
    code: "EH",
    code3: "ESH",
    name: "Western Sahara",
    number: "732",
    lat: 24.67118520939141,
    lng: -12.151921519215184,
  },
  {
    code: "YE",
    code3: "YEM",
    name: "Yemen",
    number: "887",
    lat: 15.43076720829767,
    lng: 46.342970406448266,
  },
  {
    code: "ZM",
    code3: "ZMB",
    name: "Zambia",
    number: "894",
    lat: -12.909696458080079,
    lng: 27.9320193201932,
  },
  {
    code: "ZW",
    code3: "ZWE",
    name: "Zimbabwe",
    number: "716",
    lat: -18.776292639295864,
    lng: 29.61609616096162,
  },
  {
    code: "AX",
    code3: "ALA",
    name: "Åland Islands",
    number: "248",
    lat: 62.260994362417975,
    lng: 20.4274952540897,
  },
  {
    code: "XK",
    code3: "KO-",
    name: "Kosovo",
    number: "383",
    lat: 42.602638,
    lng: 20.96789,
  },
].filter((d) => {
  const code3 = d.code3;
  const isUNDPCountry = undpCountries.find((d) => d.ISO === code3);
  if (isUNDPCountry) {
    d.name = isUNDPCountry.Countryname;
  }
  return !!isUNDPCountry;
});
countryListAllIsoData.sort((a, b) => {
  return a.name.localeCompare(b.name);
});

export default countryListAllIsoData;
